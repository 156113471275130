.modal.alertModal {
  .modalWrapper { z-index: 102; } /* 1 higher than default modal */

  .modalContent {
    min-height: 120px;
    min-width: 200px;
    padding: 14px;

    .closeButton {
      position: absolute;
      margin: 0px;
      top: 8px;
      right: 16px;
    }

    .alertMessage { margin-top: 36px; }
  }
}
