.backgroundColor {
  background: linear-gradient(to bottom,
          $ds-background-primary 0%,
          $ds-background-primary 30%,
          rgba(255,255,255,0) 30%,
          rgba(255,255,255,0) 100%);
}

.postSubmit {
  color: black;
}

.betterTogetherWrapper {
  z-index: 1;
  flex: 1;

  display: flex;
  justify-content: center;
  overflow: auto;
  padding: 90px 0 28px;
  @include sm-down {
    padding: 50px 25px 50px 25px;
  }

  @include md {
    padding-left: 15px;
    padding-right: 15px;
  }

  .betterTogether {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    min-width: min(100%, 400px);
    max-width: 950px;
    background-color: white;
    border-radius: 10px;

    .heading {
      text-align: center;
      color: $gray-125;


      @include sm-down {
        .postSubmit {
          > h1 {
            font-size: 20px;
            line-height: 24px;
          }

          > h2 {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      > h2 {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;

        padding-top: 24px;
        padding-bottom: 40px;

        @include sm-down {
          font-size: 18px;
          line-height: 24px;
        }

        a, p { color: $gray-125; }
      }
    }

    .formInput {
      padding-bottom: 12px;
      font-size: 14px;

      .inputMessage {
        position: absolute;
      }
    }

    .submit {
      margin-top: 12px;
      width: 100%;
      max-width: 400px;
    }

    .fieldsWrapper {
      width: 100%;
      max-width: 400px;
    }

    .submit, .fieldsWrapper {
      margin-left: auto;
      margin-right: auto;
    }

    .disableClicks {
      pointer-events: none;
    }
  }
}

.disclaimer {
  padding: 20px;
  color: $text-color-gray;

  a {
    color: $text-color-gray;
    text-decoration: underline;
  }
}

.footerWrapper {
  z-index: 1;
}
