.checkoutSection {
  background-color: white;
  color: $gray-125;
  font-size: 18px;
  line-height: 18px;

  &.shadowBoxes {
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  }
  &.currentSection {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  }

  .formInput {
    input {
      font-size: 18px;
      line-height: 18px;
    }
  }

  @include sm-down {
    &.shadowBoxes {
      padding: 24px;
    }
    font-size: 16px;
    line-height: 16px;
  }

  .checkoutSectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    margin: 12px 0;
    line-height: 24px;
    @include txt-family;

    &.collapsable {
      cursor: pointer;
      padding: 4px 0;
    }

    .controlIcon {
      transition: all 0.15s ease-in;

      &.rotated {
        transform:rotate(90deg);
      }
      &.rotatedUp {
        transform:rotate(270deg);
      }
      &.rotatedDown {
        transform:rotate(90deg);
      }
    }

    .controlIcon:after {
      font-family: Tahoma !important;
      content: '\203a';
    }

    &.disabled {
      cursor: default;
    }
  }

  .subHeader {
    font-size: 21px;
    line-height: 21px;
    font-weight: 500;
    margin: 5px 0;
  }

  .fixedSection {
    position: relative;
    padding-top: 10px;
  }

  .sectionRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 8px;

    .icon {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-around;
      height: 24px;
      width: 24px;
      margin-right: 8px;

      .cardLogo {
        width: 24px;
      }
    }
  }

  .sectionButton {
    text-align: right;
    margin-top: 10px;

    @include sm-down {
      text-align: center;

      button {
        width: 100%;
      }
    }

    button {
      padding: 0 20px;
      height: 40px;
      border-radius: 20px;
      border: none;
      @include txt-title-sm;

      &:disabled {
        cursor: default;
        background-color: $primary-blue-0;
      }

      &:not(:disabled) {
        cursor: pointer;
        color: $primary-contrasting-color;
      }
    }
  }

  .acceptedPaymentMethods {
    margin-top: 24px;
    margin-bottom: 12px;
    img {
      margin-right: 2px;
    }

  }

  .separator {
    border-top: 1px solid $black-12;
    &.top {
      margin-top: 16px;
    }
    &.bottom {
      margin-bottom: 16px;
    }
  }

  .logInLink {
    cursor: pointer;
    color: $link-blue;
    font-weight: 500;
    align-self: center;
  }
}
