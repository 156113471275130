.editAddressWrapper {
  .editAddressInputs {
    margin: 16px 0;

    .addressInput {
      display: flex;

      input {
        flex-grow: 1;
        border: 1px solid $dark-gray;
        border-radius: 32px;
        font-size: 16px;
        height: 32px;
      }
    }

    .addressInputError, .addressInput input {
      padding: 0 12px;
    }

  }

  .actionsWrapper {
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    grid-gap: 5px;

    @include sm-down {
      grid-auto-flow: row;
      justify-content: unset;
    }

    .updateAddressBtn {
      width: 218px;
      float: right;
      color: white;
      background: $primary-blue;
      font-size: 16px;

      &:hover {
        color: darken(white, 10%);
        background: darken($primary-blue, 10%);
      }

      &:disabled {
        cursor: default;
        background-color: $text-color-disabled;
        color: white;

        &:hover {
          cursor: default;
          background-color: $text-color-disabled;
          color: white;
        }
      }
      @include sm-down {
        width: 100%;
        grid-row: 1;
      }
    }

    .cancelAddressEditBtn {
      color: $primary-blue;
      background-color: transparent;
      font-size: 16px;

      &:hover {
        color: darken($primary-blue, 10%);
        background-color: $gray-25;
      }

      @include sm-down {
        grid-row: 2;
      }
    }
  }
}
