$card-shadow: 10px;

li.card {
  list-style: none;
}

.card {
  position: relative;
  flex: 1;

  &:not(.textInset) {
    .cardWrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @include sm-down {
        gap: 12px;
      }
    }
  }

  &.textInset {
    .cardContentWrapper {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: var(--ds-surface-overlay, rgba(0, 0, 0, 0.45));

      .cardContent {
        justify-content: center;
        text-align: center;
        padding: 1rem;
        color: var(--ds-text-on-overlay, white);
        & a {
          color: white;
        }
        & button a {
          color: currentColor;
        }
      }
    }
  }

  &.outlined {
    .cardWrapper {
      padding: 24px;
      border: 2px solid $ds-border-default;
    }
  }

  &.hasShadow .cardWrapper {
    box-shadow: 0px 0px $card-shadow var(--ds-surface-shadow, rgba(0, 0, 0, 0.15));
    @extend %default-colors;
  }

  &.hasShadow {
    padding: $card-shadow;

    .cardWrapper {
      gap: 0;

      .cardContentWrapper {
        padding: 20px;
      }

      @include md {
        .cardContentWrapper {
          padding: 40px;
        }
      }
    }
  }

  .cardImageWrapper {
    line-height: 0;

    .cardImage {
      height: 250px;
      width: 100%;

      @include md {
        &.tall {
          height: 450px;
        }
      }
    }
  }

  .cardContentWrapper {
    flex: 1;

    &.justifyEnd {
      justify-content: flex-end;
    }

    &.right {
      text-align: right;
    }

    &.center {
      text-align: center;
    }

    .cardContent {
      height: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .cardText {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .cardFooterWrapper {
        &.alignItems {
          display: flex;
          justify-content: space-between;
        }

        &.right {
          flex-direction: row-reverse;
        }

        .cardFooter {
          font-weight: 700;
          align-self: center;
        }
      }
    }
  }
}
