$search-bar-height-full: 66px;
$search-bar-height-std: 56px;
$search-bar-height-small: 35px;
$search-button-size-full: 50px;
$search-button-size-std: 44px;
$search-button-size-small: 25px;
$search-input-padding-full: 20px;
$search-input-padding-std: 16px;
$search-input-padding-small: 10px;

.searchBarContainer{
  display: flex;
  flex-direction: column;

  .search-bar {
    max-width: 545px;
    height: $search-bar-height-std;
    position: relative;
    @extend %border-radius-full;
    @extend %box-shadow-softer;
    border: 1px solid $light-gray;
    background: #FFFFFF;
  
    display: flex;
    align-items: center;
  
    padding-right: 8px;
  
    input {
      height: 100%;
      width: calc(100% - #{$search-input-padding-std * 2 + $search-button-size-std });
      padding-left: $search-input-padding-std;
      background: none;
      border: none;
  
      &::placeholder {
        @extend %txt-body-sm;
        color: $text-color-gray;
      }
    }
  
    .submit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
  
      background: $primary-blue;
      cursor: pointer;
      @extend %border-radius-full;
  
      .search-icon {
        pointer-events: none;
  
        @extend %no-select;
        @extend %no-drag;
      }
    }
  
    &.small {
      height: $search-bar-height-small;
  
      input {
        width: calc(100% - #{$search-input-padding-small * 2 + $search-button-size-small });
        padding-left: $search-input-padding-small;
      }
  
      .submit-button {
        height: $search-button-size-small;
        width: $search-button-size-small;
  
        .search-icon {
          height: 50%;
          width: 50%;
        }
      }
    }
  
    &.full {
      height: $search-bar-height-full;
  
      input {
        width: calc(100% - #{$search-input-padding-full * 2 + $search-button-size-full });
        padding-left: $search-input-padding-full;
      }
  
      .submit-button {
        height: $search-button-size-full;
        width: $search-button-size-full;
      }
    }
  
    @include md-down {
      min-width: 400px;
    }
  
    @include sm-down {
      min-width: 300px;
      max-width: 320px;
      line-height: $search-bar-height-small;
      height: $search-bar-height-small;
      margin: auto;
  
      padding-right: 4px;
  
      input {
        padding-left: $search-input-padding-small;
        width: calc(100% - #{$search-input-padding-small * 2 + $search-button-size-small });
      }
  
      .submit-button {
        height: $search-button-size-small;
        width: $search-button-size-small;
  
        .search-icon {
          height: 50%;
          width: 50%;
        }
      }
  
      &.full {
        line-height: $search-bar-height-small;
        height: $search-bar-height-small;
  
        .submit-button {
          height: $search-button-size-small;
          width: $search-button-size-small;
        }
      }
    }
  
    .disabled {
      filter: grayscale(100%);
    }
  }

  .placesAutocompleteError {
    // Aligns error with search input
    padding-left: 13px;
  }
}
