.option {
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  input {
    height: unset;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    padding: 0;
    height: 0;
    width: 0;
    margin: 0;
  }

  // text-only selected state
  .checkbox:checked ~ .label:not(.showInput),
  .radio:checked ~ .label:not(.showInput) {
    border-radius: 8px;
    box-shadow: 0 0 0 1.5px var(--ds-surface-shadow, var(--tsw-primary-color)) inset; // An inset box shadow avoids the div growing from the border
  }

  // text-only focus state w/ keyboard
  .checkbox:not(:checked):not(:disabled):focus-visible ~ .label:not(.showInput),
  .radio:not(:checked):not(:disabled):focus-visible ~ .label:not(.showInput) {
    border-radius: 8px;
    outline: 1px -webkit-focus-ring-color solid;
    outline: 1px Highlight solid;
  }

  // text-only hover state
  @include md {
    .checkbox:not(:checked):not(:disabled) ~ .label:not(.showInput):hover,
    .radio:not(:checked):not(:disabled) ~ .label:not(.showInput):hover {
      border-radius: 8px;
      box-shadow: 0 0 0 1.5px var(--ds-surface-shadow, var(--tsw-primary-color-50)) inset;
    }
  }

  .checkbox:checked ~ .label.showInput::after {
    background-color: $gray-125;
    border-color: $gray-125;
    content: url("https://cdn.toasttab.com/spa/takeout-web/126bbc7e24c5e034fdee27223ee7ce37c38410c9/static/checkmark-large.6b0fd7df.svg");
  }

  .radio:checked ~ .label.showInput::after {
    background-color: $gray-125;
    box-shadow: 0 0 0 2px white inset, 0 0 0 10px $gray-125 inset;
    border-color: $gray-125;
  }

  .checkbox:disabled ~ .label, .radio:disabled ~ .label {
    cursor: default;
    color: #c7c4c4;
    &::after {
      border-color: #c7c4c4;
    }
  }

  .radio ~ .label::after {
    border-radius: 50%;
  }

  .checkbox:not(:disabled) ~ label:hover, .radio:not(:disabled) ~ label:hover {
    &.showInput::before {
      content: "";
      background-color: rgb(244, 244, 244);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      position: absolute;
      transform: translate(-6px);
      box-sizing: inherit;
    }
  }

  .label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-sizing: inherit;
    width: 100%;
    height: 100%;

    .labelContents {
      width: 100%;
      order: 2;
    }

    &.showInput {
      padding-left: 8px;
    }

    &.showInput::after {
      border: 2px solid;
      color: $gray-125;
      content: "";
      height: 20px;
      width: 20px;
      max-height: 20px;
      max-width: 20px;
      flex: 0 0 20px;
      visibility: visible;
      order: 1;
      margin-right: 16px;
      z-index: 1;
      box-sizing: inherit;
    }
  }

  .checkbox:focus ~ .label.showInput::after, .radio:focus ~ .label.showInput::after {
    box-shadow: 0 0 0 1px white inset, 0 0 0 2px $ds-surface-shadow;
  }
}
