.primaryCta {
  @extend %txt-title-sm;
  white-space: nowrap;

  @include sm-down {
    font-size: 1.25rem;
    height: 40px;
    line-height: 40px;
    min-width: 200px;
  }

  height: 48px;
  line-height: 48px;
  border-radius: 30px;
  border: none;

  &:not(.dropDown) {
    padding: 0 16px;
  }

  &.dropDown {
    margin-right: 0;

    .dropDownLabel {
      padding: 0;
      font-size: 16px;
      text-align: center;
      width: 140px;
    }

    .dropDownContent {
      @extend %txt-body-md;

      a:hover {
        text-decoration: none;
      }
    }
  }
}
