.date-of-birth-popup {
  text-align: center;

  .inputElementWrapper, .inputWrapper {
    width: auto;
  }
  .react-aria-DatePicker {
    font-size: 16px;

    .react-aria-DateInput {
      text-transform: uppercase;
      border: 1px solid #cecece;
      border-radius: 8px;
      padding-right: 16px;
      padding-left: 16px;
    }
    .react-aria-Group {
      flex-direction: column;
      width: unset;
      justify-content: center;
    }
    .calendarToggle {
      display: none;
    }
    label {
      margin-right: 0;
    }
  }
  .confirm-button {
    margin: 24px 0;
    width: 100%;
  }

  @media screen and (min-width: 567px) {
    .react-aria-DatePicker {
      font-size: 22px;

      .react-aria-Group {
        flex-direction: row;
      }
      label {
        margin-right: 16px;
      }
    }
  }
}
