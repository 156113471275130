
.desktopFulfillmentWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
}

.fulfillmentToggleRedesign {
  align-items: center;

  .option {
    color: $ds-text-default
  }

  // replicate icon sizing from tailwind
  // scoped so we don't affect other icons that may be ignoring these classes

  // xs
  svg.h-4 {
    height: 1rem;
  }

  svg.w-4 {
    width: 1rem;
  }

  // s
  svg.h-6 {
    height: 1.5rem;
  }

  svg.w-6 {
    width: 1.5rem;
  }

  // m
  svg.h-8 {
    height: 2rem;
  }

  svg.w-8 {
    width: 2rem;
  }

   // l
   svg.h-12 {
    height: 3rem;
  }

  svg.w-12 {
    width: 3rem;
  }

  // xl
  svg.h-16 {
    height: 4rem;
  }

  svg.w-16 {
    width: 4rem;
  }

  // 2xl
  svg.h-20 {
    height: 5rem;
  }

  svg.w-20 {
    width: 5rem;
  }
}

.optionText {
  :first-child {
    white-space: break-spaces;
    width: min-content;
  }
}
