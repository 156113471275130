$crumb-spacing: 6px;

.breadcrumbs {
  ul {
    display: inline-block;
    margin: 0px;
    padding: 0px;
    vertical-align: middle;

    a {
      color: $text-color;

      &:hover { color: $link-blue; }
    }

    .crumb {
      @extend %txt-body-md;
      display: inline-block;
      padding: 0px $crumb-spacing;
      list-style-type: none;

      &.slash {
        color: $medium-gray;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        @extend %txt-body-md-bold;
      }
    }
  }
}
