.savedCreditCards {
  .savedCreditCard, .addCard {
    display: flex;
    align-items: center;
    width: auto;
    line-height: 24px;
    padding: 8px 0;
    margin: 12px 0;
  }

  .savedCreditCard {
    margin-bottom: 12px;

    .savedCardRadio {
      flex-grow: 1;

      .savedCardLabel {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;

        .cardLogo {
          width: 37px;
        }

        .expiration {
          margin-left: auto;
        }
      }
    }

    .editCard {
      cursor: pointer;
      padding: 0 10px 0 20px;
    }

    .dropDownContent {
      width: auto;
      right: 5px;
      white-space: nowrap;
    }
  }

  .addCard {
    cursor: pointer;
    margin-top: 16px;

    .plusSign {
      display: inline-block;
      width: 20px;
      margin-right: 16px;
      text-align: center;
    }
  }
}
