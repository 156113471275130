.verifiedStatus {
    font-size: 10px;
    line-height: 14px;
    border-radius: 22px;
    padding: 4px 10px 2px 10px;
    display: inline;
    color: $gray-125 !important;
    font-weight: 600;
    margin-left: 5px;
    letter-spacing: 1px;

    &.verified {
      background: #E8F7D4;
      border: 1px solid #CDE6B0;
    }
  
    &.unVerified {
      background: #FFE6E9;
      border: 1px solid #FFCCD1;
    }
}
