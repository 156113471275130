.confirmation {
  .description {
    padding-bottom: 16px;
  }

  .buttons {
    padding-bottom: 16px;
    
    .button {
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
    }

    .loyaltyContainer {
      display: flex;
      justify-content: center;
      padding: 12px 0;
    }

    a {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }

    .joinLoyalty {
      padding-left: 8px;
      color: #252525;
    }
  }

  .policies {
    .cancellationPolicy {
      padding-bottom: 0px;

      span {
        font-weight: 600;
        color: rgba(37, 37, 37, 1);
      }
    }
  }

  .depositSummary {
    margin-top: 32px;
    background-color: $gray-0;
    border-radius: 8px;
    padding: 16px;

    .depositDescriptionTitle {
      padding-bottom: 8px;
    }

    .depositPaymentContainer {
      display: flex;
      justify-content: space-between;
    }
  }
}
