.infoIconWrapper {
  cursor: pointer;
  display: inline;
  margin-left: 4px;
  height: 20px;
  width: min-content;
  vertical-align: text-top;
  .infoIcon {
    height: 12px;
    width: 12px;
    color: #252525;
  }
}
.titleAndTooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.feeModal {
  margin: 24px;

  .title {
    padding-bottom: 18px;
  }

  .closeButton {
    position: absolute;
    top: 0;
    right: 0;
  }

  .fee {
    margin: 12px;

    .feeDescription {
      margin: 8px;
    }
  }
}

.taxesAndFees {
  cursor: pointer;
}  
