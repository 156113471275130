.promoContainerPadding {
  padding-top: 40px;
}

.promoContainer {
  display: flex;
  gap: 40px;
  align-items: center;

  .promoImg {
    @include md-down {
      display: none;
    }
  }

  .cateringImg {
    width: 380px;
    height: auto;
  }

  .promoContent {
    h2 {
      font-weight: 400;
      line-height: 36px;
    }

    h3 {
      margin: 20px 0px 34px 0px;
      font-size: 1.5rem;
      font-weight: 400;
    }

    .titleText {
      font-size: 1.5rem;
      font-weight: 400;
      color: $gray-75;
    }

    .titleContainer {
      margin-bottom: 6px;
      display: flex;
      align-items: center;
    }

    .logo {
      height: 20px;
      margin-right: 6px;
    }

    .divider {
      margin-right: 6px;
      border-right: 1px solid $gray-75;
      display: flex;
      align-items: center;
    }

    .linkButton {
      text-align: center;
      @include txt-source-sans-pro-md;
      font-size: 16px;
    }

    .cateringButton{
      @extend .linkButton;
      width: 200px;
    }

    .iosButton, .androidButton {
      @extend .linkButton;
      width: 132px;
    }

    .androidButton {
      margin-left: 20px;
    }

    .dinerSupport {
      margin-top: 30px;
      @extend %txt-body-sm;
      color: $dark-gray;

      a {
        color: $link-blue;
        &:hover { text-decoration: underline; }
      }
    }
  }
}