.userModal {
  padding: 24px;
  display: flex;
  flex-direction: column;

  .form {
    display: flex;
    flex-direction: column;

    .formError {
      color: red;
      @extend %txt-body-sm;
      margin-bottom: 4px;
    }

    button.logout {
      margin-top: 15px;
    }
  }
}