.filter {
  position: relative;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .filterLabel {
    display: inline-block;
    white-space: nowrap;
  }

  .arrow {
    padding: 5px;
  }
}

.filterContent {
  border-radius: 12px;
  cursor: default;
  position: absolute;
  top: 100%;
  z-index: 7;
  @extend %box-shadow-std;
  @extend %border-radius-std;
  margin-top: 5px;
  @extend %themed-default-colors;

  align-items: inherit;
  left: 0;

  padding: 12px;

  @include sm-down {
    inset: 0;
    position: fixed;
    z-index: 10;
    border-radius: 0;
    margin-top: 0;
    max-height: 100%;

    display: flex;
    flex-direction: column;
  }

  .header {
    flex: 0;
    padding: 8px 0;
  }

  .filterOptionsContainer {
    max-height: 288px;
    overflow-y: scroll;
    flex: 1;

    @include md-down {
      max-height: unset;
    }

    input {
      accent-color: $ds-icon-action;
    }

    >* {
      @extend %themed-default-colors;
      display: flex;
      cursor: pointer;
      line-height: 24px;
      padding: 0 12px;
      border-radius: 6px;


      &:hover {
        background-color: $ds-surface-focus;
      }
    }

    .filterOption {
      white-space: nowrap;
      display: flex;
      gap: 16px;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;

      &.bold {
        font-weight: 600;
      }
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      background: transparent;
      width: 0;
      height: 0;
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    gap: 6px;
    flex: 0;

    padding-top: 6px;

    .button {
      flex: 1;
      border-radius: 8px;

      &.mobileOnly {
        @include md {
          display: none;
        }
      }
    }
  }
}
