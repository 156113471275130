.menuHeader {
  .heroBanner {
    width: 100%;
    display: flex;
    height: 250px;
    @include xs {
      height: 150px;
    }

    .heroImage {
      height: 100%;
      width: 100%;

      &.centerHero {
        padding-top: 24px;
        @include xs {
          padding-top: 12px;
        }

        img {
          &.roundedCornerNone {
            border-radius: 4px;
          }

          &.roundedCornerSubtle {
            border-radius: 8px;
          }

          &.roundedCornerExaggerated {
            border-radius: 16px;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.withoutImage {
      flex-direction: column;
      justify-content: flex-end;
      height: 150px;
      @include xs {
        height: 126px;
      }

      .contentWrapper {
        padding-top: 20px;
        padding-bottom: 40px;
        @include xs {
          padding-bottom: 32px;
        }
      }
    }
  }

  .contentWrapper {
    display: flex;
    padding-top: 40px;
    padding-bottom: 16px;

    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .headerContent {
        display: flex;
        flex-direction: column;
        @include sm {
          // Make room for fulfillment control on desktop
          max-width: 60%;
        }

        .heading {
          font-size: 32px;
          font-weight: 600;
          line-height: normal;
          @extend %line-clamp-2;

          @include xs {
            font-size: 26px;
          }
          &.smaller {
            font-size: 26px;
          }
        }

        .diningOptionBehaviorInfo {
          font-size: 16px;
          font-weight: 400;
          white-space: normal;
          cursor: pointer;
        }
      }
    }

    .fulfillmentControlContainer {
      align-self: center;
      display: flex;

      .fulfillmentControl {
        flex: none;
      }
    }
  }
}
