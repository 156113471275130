$cuisine-filter-horizontal-padding: 12px;

.cuisine-filters {
  position: relative;

  .filter-header {
    margin: 52px 0px 0px 0px;
  }

  .show-more {
    text-align: right;
    margin-bottom: 12px;

    * { vertical-align: middle; }

    .caret {
      margin-left: 10px;
    }
  }

  .filters {
    display: grid;
    overflow-x: scroll;
    grid-auto-flow: column;
    padding: 0 20px 0 20px;
    width: 100%;

    &::-webkit-scrollbar { display: none; }

    @extend %no-select;

    .arrow-wrapper {
      position: sticky;
      top: 5px;
      background-color: white;
      z-index: 2;
      height: 30px;
      width: 30px;
      border-radius: 25px;
      @extend %box-shadow-std;
      cursor: pointer;
      text-align: center;
      
      @include sm-down {
        top: 50%;
      }

      &.left-arrow {
        left: 0px;
      }

      &.right-arrow {
        right: 0px;
      }

      img {
        height: 20px;
        padding-top: 5px;
      }
    }

    .cuisine-filter {
      cursor: pointer;

      .cuisine-filter-content {
        position: relative;
        padding: 18px $cuisine-filter-horizontal-padding;
        display: flex;
        flex-direction: column;
        align-items: center;

        .filter-icon-wrapper {
          position: relative;
          display: inline-block;

          .filter-icon {
            @extend %no-drag;
            height: 80px;
          }
        }

        .filter-title {
          position: relative;
          color: $text-color;
          text-align: center;
          @extend %txt-body-sm;
          @extend %txt-source-sans-pro;
          white-space: nowrap;
        }
      }

      &:hover {
        .cuisine-filter-content {
          .filter-title {
            color: $link-blue;
            text-decoration: underline;
          }
        }
      }

      &.selected {
        .cuisine-filter-content {
          .filter-title {
            color: #FFFFFF;
            font-weight: bold;
          }
        }
        .cuisine-filter-content .filter-title::before {
          content: '';
          z-index: -1;
          position: absolute;
          top: -2px;
          bottom: -2px;
          left: -5px;
          right: -5px;
          background-color: $hard-blue;
          @extend %border-radius-lg;
        }
      }
    }
  }

  .filters:before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 25px;
    left: -35px;
    width: 70px;
    height: inherit;
    box-shadow: inset 75px 0px 20px -20px #FFFFFF;
  }

  .filters:after {
    content: "";
    position: absolute;
    top: 25px;
    right: -35px;
    width: 70px;
    height: inherit;
    box-shadow: inset -75px 0px 20px -20px #FFFFFF;
  }
}
