.profileSettings {
    .deleteButton {
        float: left;
        color: #B12910;
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
    }
}

.deleteAccountModalContent {
    max-width: 400px;
    .closeButton {
        float: right;
        margin-left: 100%;

        &:hover {
            cursor: pointer;
        }
    }
    .content {
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 20px;
        text-align: left;

        .deleteModalHeader {
            padding-top: 16px;
            padding-bottom: 16px;
        }
        .modalButtonWrapper {
            text-align: center !important;
            .deleteAccountButton {
                margin-top: 16px;
                background-color: #D40023;
                color: white;
            }
            .deleteAccountCancel {
                margin-top: 16px;
                color: #2B4FB9;
                background-color: transparent;
            }
        }
    }
}
