$menu-item-padding: 14px;

.rx-card-container {

  .oo-link {
    text-decoration: none;
    color: $ds-text-default;
  }

  .rx-card-wrapper {
    .rx-card {
      overflow: hidden;
      @extend %border-radius-lg;
      border: 1px solid $light-gray;
      cursor: pointer;

      &:hover {
        background: $extra-light-gray;

        .rx-details {
          .rx-header {
            .avg-rating {
              .star-img {
                transform: rotate(72deg);
                stroke: $brand-orange;
                fill: $brand-orange;
              }
            }
          }
        }
      }

      .rx-image-wrapper {
        position: relative;
        width: 100%;
        height: auto;
        padding-bottom: 56.25%; // Force 16:9 aspect ratio
        overflow: hidden;

        border-bottom: 1px solid $light-gray;

        .rx-image {
          position: absolute;
          width: 100%;
          height: auto;
          margin: auto;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
        }
      }

      .rx-details {
        padding: 20px;

        .rx-header {
          .food-types {
            @extend %txt-source-sans-pro-md;
            font-size: 1.2rem;
            color: $text-color-gray;
          }

          .rx-name {
            margin: 12px 0px;
            /* Custom font wieght and size :face-palm: */
            @extend %txt-source-sans-pro-md;
            font-size: 22px;
          }

          .rx-address {
            margin-bottom: 12px;
            color: $text-color-gray;
          }

          .avg-rating {
            .star-img {
              fill: $primary-blue;
              stroke: $primary-blue;
              transition: all 0.4s ease-in-out;
            }
          }
        }

        hr { margin: 14px 0px 0px 0px; }
      }

      .popular-items-wrapper {
        padding: 0px 20px 20px 20px;

        .show-items {
          * { vertical-align: middle; }

          .caret { margin-left: 8px; }
        }

        .rx-popular-items {
          width: 100%;

          th {
            @extend %txt-source-sans-pro-md;
            font-size: 2.0rem;
            text-align: left;
          }

          .menu-item {
            td { vertical-align: bottom; }

            .name-cell {
              padding-top: $menu-item-padding;
              @extend %txt-title-md;
            }

            .price-cell {
              padding-top: $menu-item-padding;
              text-align: right;
              @extend %txt-title-md;
            }

            .description-cell {
              @extend %txt-title-sm;
              line-height: normal;
              color: $text-color-gray;

              @include sm-down { @include txt-body-sm; }

              .clamped {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }

              .link-text {
                font-weight: 100;
              }
            }

            .rating-cell { text-align: right; }
          }
        }
      }
    }
  }

  .link-to-rx-profile {
    @extend %txt-body-sm;
    color: $link-blue;
  }
}
