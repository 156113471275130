.adyenPaymentOverlay {
    position: absolute;
    z-index: 1002; // appear over everything
    inset: 0 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  
    &.withBorderRadius {
      border-radius: 24px;
    }
  
    &.fullScreen {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }