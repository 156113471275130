.itemResolutionModal {
  padding: 24px 24px 40px 24px;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 14px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .closeButton {
      margin: 0;
      padding: 0;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    img {
      align-self: center;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .button {
      width: 100%;
    }
  }
}
