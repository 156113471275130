@mixin xs {
  @media screen and (max-width: 35.49em) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: 35.5em) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: 48em) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: 64em) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: 80em) {
    @content;
  }
}

@mixin sm-down {
  @media screen and (max-width: 47.99em) {
    @content;
  }
}

@mixin sm-height-down {
  @media screen and (max-height: 34.5em) {
    @content;
  }
}

@mixin md-height-down {
  @media only screen and (max-height: 47em) {
    @content;
  }
}

@mixin md-down {
  @media screen and (max-width: 63.99em) {
    @content;
  }
}

@mixin md-up {
  @media screen and (min-width: 47.99em) {
    @content;
  }
}

@mixin lg-down {
  @media screen and (max-width: 79.99em) {
    @content;
  }
}

.hide {
  display: none !important;
}

.hidden-xs {
  @media screen and (max-width: 35.49em) {
    display: none !important;
  }
}

.hidden-sm-down {
  @media screen and (max-width: 47.99em) {
    display: none !important;
  }
}

.hidden-md-down {
  @media screen and (max-width: 63.99em) {
    display: none !important;
  }
}

.hidden-lg-down {
  @media screen and (max-width: 79.99em) {
    display: none !important;
  }
}

.hidden-xl {
  @media screen and (min-width: 80em) {
    display: none !important;
  }
}

.hidden-lg-up {
  @media screen and (min-width: 64em) {
    display: none !important;
  }
}

.hidden-md-up {
  @media screen and (min-width: 48em) {
    display: none !important;
  }
}

.hidden-sm-up {
  @media screen and (min-width: 35.5em) {
    display: none !important;
  }
}

.paddedContentWrapper {
  padding: 0 108px;
  display: flex;
  justify-content: center;

  @include md-down {
    padding: 0 36px;
  }

  @include sm-down {
    padding: 0 16px;
  }

  .paddedContent {
    max-width: 1224px;
    width: 100%;
    position: relative;
  }

  &.sideBySide {
    padding-right: 40px;
    padding-left: 40px;
  }

  &.withBorder {
    border-bottom: 0.75px solid var(--ds-border-default, #CECECE);
  }
}
