.popupContent.loyaltyPopupContent {
  gap: 16px;
}

.loyaltyPopupContent {
  .popupBody {
    .loyaltySignup {
      display: grid;
      grid-gap: 16px;
    }
  }

  .heading > h2 {
    margin-bottom: 16px;
  }

  .successMessage {
    margin: 16px
  }
}
