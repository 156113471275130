.sms-marketing-signup-popup {
  text-align: center;

  .button {
    margin: 0 auto;
  }
  .qr-code-container{
    text-align: center;
    .qr-code {
      width: 50%
    }
    .alt-signup-method {
      font-weight: bold;
    }
    .scan-to-join-cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;

      .top {
        margin-top: -15px;
        margin-bottom: -2px;
        width: 0;
        height: 0;
        display: inline-block;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #252525;
      }
      .bottom {
        background-color: #252525;
        border: none;
        padding: 8px 16px;
        color: #fff;
        border-radius: 4px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: .2px;
        font-weight: 400;
        display: inline-block;
      }
    }
  }

  .legal-disclaimer {
    font-size: 14px;
  }
  .popupBody {
    margin: 0 0 40px 0;
  }
  .underline {
    text-decoration: underline;
  }
}
