.modalButton {
  @extend %txt-title-sm;

  background-color: $ds-background-primary;
  color: $ds-text-on-bg-primary;
  width: 100%;
  border: none;
  border-radius: 32px;
  height: 40px;

  display: flex;
  justify-content: space-between;
  &.centered {
    justify-content: center;
  }
  align-items: center;
  padding: 8px 24px;

  &.flush {
    border-radius: 0 0 6px 6px;
    justify-content: center;

    @include sm-down {
      border-radius: 0;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $ds-background-primary-focus;
  }

  &:disabled {
    cursor: default;
    background-color: $text-color-disabled;

    &:hover {
      cursor: default;
      background-color: $text-color-disabled;
    }
  }

  &.marginBottom {
    margin-bottom: 8px;
  }
}

.modal .itemModalWrapper {
  @include sm-down {
    padding: 48px;
  }

  @include xs {
    padding: 24px;
  }
}

.modal .itemModalContent {
  overflow: hidden;
  border-radius: 24px;

  @include sm-down {
    width: 100%;
  }
}

.addToCart {
  position: relative;
  width: 100%;
  min-height: 68px;
  display: flex;
  flex: 0;
  z-index: 1;
  align-items: center;
  box-shadow: 0 -4px 4px rgb(0 0 0 / 12%);

  .actionWrapper {
    padding: 16px;
    flex: 1;

    &.primary {
      flex: 2;
    }
  }
}

.itemModalContainer {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .ariaAlert {  
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }
}

.itemModal {
  max-width: 690px;
  min-width: 590px;
  max-height: 100%;
  overflow: hidden;
  position: relative;

  flex: 1;

  @include sm-down {
    max-width: unset;
    min-width: unset;
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .itemModalImage {
    max-width: 100%;
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .imageWrapper {
    position: relative;
    max-width: 100%;
    width: 100%;
    height: 300px;
  }

  .itemModalCloseButton {
    position: absolute;
    top: 8px;
    right: 16px;
    z-index: 3;
    background-color: white;
    border-radius: 50%;
    height: 40px;
    width: 40px;

    @include sm-down {
      top: 0;
    }
  }

  .header {
    @extend %default-colors;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 16px 72px 8px 32px;

    &.secondary {
      z-index: 2;
    }

    .backButton {
      display: flex;
      padding: 8px;
      margin: 8px;
      cursor: pointer;

      &:hover {
        border-radius: 50%;
        background-color: #f5f5f5;
        color: $gray-125;
      }

      > img {
        width: 16px;
        height: 16px;
      }
    }

    .header-row {
      min-height: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
  }

  .subHeader {
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    gap: 24px;
    font-family: inherit;

    .description {
      color: $text-color-gray;
      font-size: 18px;
      line-height: 20px;
      white-space: pre-wrap;
    }

    .itemTags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      line-height: normal;

      .itemTag {
        border-radius: 4px;
        padding: 2px 4px;
        font-size: 12px;
      }
    }
  }

  .modalTitle {
    font-size: 22px;
  }

  .paddedContent {
    padding: 0 32px;
    position: relative;
  }

  .background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: white;
  }

  .content, .secondaryContent {
    @extend %default-colors;
    z-index: 1;
    overflow-y: scroll;
    height: 100%;
    max-height: 600px;
    @include hide-scrollbar;
    width: 100%;
  }

  .tbrContent {
    border: 1px solid $black-12;
    border-radius: 8px;
    padding: 4px 8px;
    margin: 8px 24px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .tbrHeader {
      font-size: 12px;
      font-weight: bold;
      font-family: "Source-Sans-Pro", sans-serif;
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  .subTitle {
    @extend %txt-title-sm;
    font-weight: 600;
    padding: 18px 0;
  }

  .modifierGroup {
    padding-bottom: 8px;
    border-top: 1px solid $medium-gray;

    .title {
      @extend %txt-body-md;
      padding: 16px 0;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .modifierGroupTitle {
        @extend %txt-title-sm;
        font-weight: 600;
      }

      .modifierGroupSubtitle {
        font-size: 14px;
        color: $text-color-gray;
      }
    }

    .required {
      color: $text-color-gray;
      font-size: 14px;
      line-height: 14px;
      padding: 4px 0;

      &.highlight {
        color: $error-75;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .selections {
      padding: 4px 0 8px;

      .parentSelections {
        gap: 8px;
        padding-bottom: 8px;
        display: flex;
        flex-direction: column;
      }

      .toggles {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
        row-gap: 8px;

        @include sm-down {
          grid-template-columns: 1fr;
        }
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      font-size: 16px;
      overflow: visible;

      &.skeleton {
        display: block;
        padding: 8px 0;
        box-shadow: none;
      }

      &.subGroups {
        cursor: pointer;
        border: 1px solid $medium-gray;
        box-shadow: none;
      }

      .toggleContents {
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.hasModifiers {
          justify-content: space-between;
        }

        &.selectedWithQuantity {
          border-radius: 8px;

          .modifierText, .subtitle {
            color: $gray-125;
          }

          .price {
            display: none;
          }

          .actions {
            display: block;
          }
        }

        .modifierTextContent {
          flex: 1;
          display: flex;
          justify-content: space-between;
          overflow: hidden;

          &.leftAligned {
            justify-content: flex-start;
          }
        }

        .modifierText {
          overflow: hidden;
        }

        .modifierText, .price {
          line-height: 26px;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        flex: 0;
        padding-left: 8px;
      }

      .name {
        padding-right: 8px;
      }

      .rowSelector {
        .button {
          color: $primary-blue-75;
        }

        .quantity {
          color: white;
          background-color: $primary-blue-75;
        }
      }
    }

    &.fractionalQuantity {
      display: flex;
      background-color: #f5f5f5;
      align-items: center;
      justify-content: space-between;
      padding-right: 8px;

      .quantity {
        input {
          max-width: 100px;

          &:focus {
            border-color: $ds-border-focus;
          }
        }

        label {
          margin-left: -24px;
        }
      }
    }

    &.specialRequests {
      textarea {
        width: 100%;
        border: 1px solid $medium-gray;
        border-radius: 6px;
        resize: vertical;
        overflow: auto;
        padding: 16px;
        height: unset;

        &:hover {
          border-color: $ds-border-focus;
        }
      }
    }

    .subtitle {
      @extend %txt-body-sm;
    }
  }

  @media (max-height: 320px) {
    .header {
      position: unset;
    }
    // hide image which itself is 300px height
    .imageWrapper {
      display: none;
    }
  }
}
