.mapInfoWindow {
  max-width: 266px;
  color: #0D0D0D;

  hr {
    margin-bottom: 0;
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 8px;

    .itemLabel {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .fulfillmentCta {
    display: flex;
    justify-content: center;
    width: 100%;
    a {
      color: $ds-text-on-bg-primary;
    }

    &:disabled {
      cursor: default;
    }
  }

  &.detail {
    font-size: 16px;

    h3 {
      font-size: 20px;
      line-height: 28px
    }

    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
    }

    .schedule {
      padding-top: 8px;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

// Hide the native close button in the info window
.gm-style-iw > button {
  display: none !important;
}
