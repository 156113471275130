.coreCtas {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;

  @include sm-down {
    gap: 0;
    justify-content: center;
  }

  .secondaryCta {
    .cta {
      display: inline-flex;
      @extend %txt-title-sm;
      text-decoration: none;
      cursor: pointer;
      color: $link-color;

      &:hover {
        text-decoration: none;
      }

      .dropDownArrow:after {
        color: $link-color;
      }

      .dropDownContent {
        font-weight: 400;
        white-space: nowrap;

        a:hover {
          text-decoration: none;
        }
      }
    }
  }

  @include sm-down {
    a, .primaryCta, .secondaryCta {
      flex-basis: 100%;
      text-align: center;
      margin: 0;
      padding: 0;
    }

    .secondaryCta {
      margin-top: 20px;

      .cta {
        font-size: 1.25rem;
      }
    }
  }
}

.applePayBtn {
  -apple-pay-button-type: order;
  -webkit-appearance: -apple-pay-button;
  cursor: pointer;
}
