.rx-config {
  text-align: right;

  &.has-menu-tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  img {
    padding: 5px;
    height: 18px;
  }
}
