.iframePage {
  display: block;
  border: none;

  &.readOnly {
    pointer-events: none;
  }

  &.loading {
    visibility: hidden;
  }
}
