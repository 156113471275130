.orderStatusBarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 16px;
}

.orderStatusHeader {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.orderStatusFooter {
  font-size: 14px;
  color: #666666;
  margin-top: 16px;
}

.statusStageDivider {
  height: 3px;
  flex: 1;
  margin-left: 3px;
  margin-right: 3px;
  background: #252525;

  &.upcoming {
    background: #C0C0C0;
  }

  &.inProgress {
    background: linear-gradient(to right, #252525, #25252500);
  }
  
  // in the future when we update in real time, indicate live status using animated gradient instead:
  // &.inProgress {
  //   background: repeating-linear-gradient(to right, #c0c0c0, #252525, #c0c0c0);
  //   animation: inProgressGradient 2s infinite;
  //   background-size: 200% auto;
  //   background-position: 0 100%;
  //   animation-fill-mode: forwards;
  //   animation-timing-function: linear;
  // }

  // @keyframes inProgressGradient {
  //   0% { background-position: 0 0; }
  //   100% { background-position: -200% 0; }
  // }

}

.statusIcon {
  color: #252525;

  &.inProgress {
    color: #C0C0C0
  }
}

.warningStatusIcon {
  color: #D40023
}

.contactButton {
  margin-top: 16px;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
