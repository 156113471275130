.rx-hot-spots {
  .icon {
    margin-bottom: 32px;
  }

  .blurb {
    margin-top: 8px;
  }

  .hot-spots {
    .simple-listing {
      .listing-content {
        padding: 8px;

        h3 {
          color: $text-color;
          font-weight: 400;
          font-size: 26px;

          &:hover {
            color: $link-blue;
            text-decoration: underline;
          }
        }

        p {
          color: $text-color-gray;
          @include txt-source-sans-pro;
          font-size: 16px;
        }

        @include md-down {
          padding: 12px 0px 0px 0px;
        }
      }
    }
  }
}
