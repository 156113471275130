.menuGroup {
  padding-bottom: 48px;
}

.menuWrapper {
  padding-top: 20px;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .headerWrapper h2 {
    font-size: 20px;
  }
}

.globalNav {
  top: 0;
  transition: top 0.5s ease-out;

  &.collapsed {
    top: -80px;

    @include md-down {
      top: -66px;
    }

    &.withNavSpotlight {
      top: -128px;

      @include md-down {
        top: -124px;
      }
    }
  }
}

.secondaryNav {
  z-index: 4;
  padding-bottom: 16px;
  background-color: $ds-surface-default;
  transition: top 0.5s ease-out;
  top: -4px !important;

  &.sideBySide {
    align-self: start;
    box-shadow: unset !important;
    @include sm-down {
      width: 100%;
    }
  }

  @include sm-down {
    top: -24px !important;

    &.noOrder {
      top: -8px !important;

      .paddedContent {
        row-gap: 16px;
      }
    }

    &.noLocationSelector {
      top: -4px !important;
    }
  }

  &.withoutHero {
    box-shadow: none;
  }

  &.withShadow {
    box-shadow: 0px 0px 6px var(--ds-surface-shadow, rgba(0, 0, 0, 0.15));
  }

  &.globalNavOpen {
    top: 70px !important;

    @include md-down {
      top: 65px !important;
    }

    @include sm-down {
      top: 60px !important;
    }

    &.withNavSpotlight {
      top: 128px !important;

      @include md-down {
        top: 112px !important;
      }

      @include sm-down {
        top: 108px !important;
      }
    }
  }

  .paddedContent {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    column-gap: 24px;
    row-gap: 16px;
    background-color: $ds-surface-default;

    @include sm-down {
      padding-top: 16px;
    }

    .locationSelector .dropDownWrapper {
      margin: 0;
    }

    @include sm-down {
      flex-direction: column;
      align-items: normal;
    }
  }
}

.pageContent {
  position: relative;

  .onlineOrderingUnavailable {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    padding: 48px;
    font-size: 32px;
    @include xs {
      padding: 24px;
      font-size: 24px;
    }
  }
}

.intersectionRoot {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  height: 20px;
}
