.locationDescription {

  margin: 8px 8px 0 0;
  flex: 0 1 475px;

  button {
    border: none;
    background: none;
    padding: none;
    margin: none;
  }

  .locationName, .locationMenu {
    a {
      color: inherit;
      text-decoration: underline;
      &.disabled {
        pointer-events: none;
        cursor: default;
        text-decoration: none;
      }
    }
  }

  .locationName {
    @extend %txt-title-sm;
    font-weight: bold;
    margin-top: 24px;
  }

  .foodLinks {
    display: flex;
    gap: 16px;
  }

  .locationMenu {
    font-weight: bold;
    padding-top: 10px;
  }

  .locationAddress, .locationMenu {
    line-height: 1.5rem;
  }

  .hoursToggle {
    color: inherit;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: underline;
    padding: 0;
  }

  .locationSchedule {
    width: fit-content;
  }

  .status {
    display: block;
    margin-right: 0;

    @include sm-down {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }

  .backupServiceHours {
    margin-top: 8px;

    .day {
      font-weight: bold;
      margin-right: 5px;
    }

    .times {
      padding-right: 16px;
    }
  }
}
