.Toastify__toast-body {
  line-height: 1.25rem !important;
}

.account {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;

  display: block;
  margin: auto;
  max-width: 600px !important;
  margin: 24px 0;

  .accountHeading {
    font-size: 24px;
    line-height: 29px;
    color: black;
    @include txt-family;
  }

  .accountHeadingDisabled {
    font-size: 24px;
    line-height: 29px;
    color: $gray-50;
  }

  .accountSections {
    $minSectionHeight: 100px;
    display: grid;
    grid-auto-flow: row;
    grid-template:
          "information" minmax($minSectionHeight, auto)
          "address" minmax($minSectionHeight, auto)
          "wallet" minmax($minSectionHeight, auto)
          "settings" minmax($minSectionHeight, auto);
    grid-gap: 32px;
    margin: 20px 0;

    p {
      color: black;
    }

    .accountSubheading {
      font-size: 18px;
      line-height: 22px;
      color: black;
      @include txt-family;
    }

    .accountSubheadingDisabled {
      font-size: 18px;
      line-height: 22px;
      color: $gray-50;
    }

    .informationSection {
      grid-area: information;
    }

    .addressSection {
      grid-area: address;
    }

    .walletSection {
      grid-area: wallet;
    }

    .settingsSection {
      grid-area: settings;
    }
  }
}
