$rx-search-filters-height: 35px;
$rx-search-text-button-size: 25px;
$nav-bar-height: 84px;

.rx-search {
  height: 100%;
  padding-top: $nav-bar-height 20px 0;

  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  .location-title {
    display: flex;
    flex-direction: row;

    @include sm-down {
      flex-direction: column;
    }
  }

  .accepts-toggles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin-left: auto;
    margin-right: 0;
    align-items: center;
    justify-content: center;

    @include sm-down {
      margin-right: auto;
      margin-top: 16px;
    }

    .accepts-toggle {
      align-items: center;
      justify-content: space-between;
      border-radius: 32px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      background-color: #FFF;
      font-size: 16px;
      font-weight: normal;
      font-family: "Source Sans Pro", sans-serif;
      overflow: hidden;
      height: 36px;
      min-width: fit-content;

      .toggleContents {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px 8px 8px;

        &.selected {
          background-color: #252525;
        }

        .toggleTextContent {
          flex: 1;
          display: flex;
          justify-content: space-between;
          overflow: hidden;

          .toggleText {
            overflow: hidden;

            &.selected {
              color: #FFF;
            }
          }
        }
      }
    }
  }

  .cuisine-filters {
    .filter-header {
      margin: 0;
    }
  }

  .rx-filters {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 24px;

    @include sm-down {
      flex-direction: column;
    }

    .rx-filter-second-row {
      display: flex;
      align-items: center;
    }

    @include sm-down {
      .rx-filter-second-row {
        text-align: center;
      }
    }
    .rx-filter {
      display: inline-block;
      min-height: $rx-search-filters-height;

      .rx-filter-dropdown {
        margin-right: 20px;
      }

      .button-group {
        display: flex;
        white-space: nowrap;
        width: auto;
        padding-top: 10px;

        .button-group-button {
          flex-grow: 1;
          img {
            vertical-align: text-top;
            padding-right: 5px;
          }
        }
      }

      .rx-filter-dropdown-label-text {
        span, svg, img { vertical-align: middle; }
        svg { padding-left: 3px; }
        img { padding-right: 5px; }
      }

      .rx-filter-dropdown-menu {
        @include md {
          transform: translate(-40%);
        }

        @include lg {
          transform: unset;
        }

        @include sm-down {
          .button-group {
            flex-wrap: wrap;
            .button-group-button {
              width: 40%;
              margin: 10px;
              padding: 10px 20px;
            }
          }
        }

        @include xs {
          .button-group {
            .button-group-button {
              width: 100%;
            }
          }
        }
      }

      .rx-rating-slider {
        height: 30px;
        width: 200px;
        padding: 15px 15px 0 15px;
        letter-spacing: normal;

        @include sm-down {
          width: 80%;
          margin: auto;
        }
      }

      &.clear-button {
        @extend %txt-source-sans-pro-rg;
        height: 35px;

        .button-sm {
          height: auto;
          min-width: 50px;
          line-height: $rx-search-filters-height;
          text-align: center;
          border: 0;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
}
