$cuisine-card-horizontal-padding: 12px;

.cuisine-farm {

  h2 {
    margin: 32px 0px 32px 0px;
    @extend %txt-body-xl;

    @include xs { margin: 0px 0px 32px 0px; }
  }

  .cuisine-cards {
    margin: 0px -$cuisine-card-horizontal-padding;

    .cuisine-card {
      &.hidden {
        display: none;
      }

      .cuisine-card-content {
        padding: 18px $cuisine-card-horizontal-padding;

        .cuisine-image-wrapper {
          position: relative;
          width: 100%;
          height: auto;
          padding-bottom: 75%; // Force 4:3 aspect ratio (352 X 264)
          overflow: hidden;
          @extend %border-radius-lg;
          @extend %box-shadow-light;

          .cuisine-image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
          }

          transition: all 0.25s ease-in-out;
        }

        h3 {
          margin-top: 10px;
          text-decoration: none;
          color: $text-color;
          @extend %txt-source-sans-pro;
          font-size: .9rem;
        }
      }

      &:hover {
        .cuisine-card-content {
          .cuisine-title {
            color: $link-blue;
            text-decoration: underline;
          }

          .cuisine-image-wrapper {
            @extend %box-shadow-std;
          }
        }
      }
    }
  }

  .show-more {
    margin-top: 34px;
    text-align: center;

    .caret {
      margin-left: 10px;
    }
  }
}
