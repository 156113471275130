.checkoutMode {
  padding: 10px 0;
  cursor: default;

  &.narrowSpacing {
    padding: 6px 0;
  }

  button {
    cursor: pointer;
    &:disabled {
      cursor: default;
      border: 2px solid $gray-50;
    }
  }

  .toastCheckout, .guestCheckout, .borderedGuestCheckout, .applePayCheckout {
    text-align: center;
    width: 100%;
    background-color: transparent;
  }

  .toastCheckout, .applePayCheckout {
    border: 2px solid $gray-125;
    @extend %border-radius-full;
    padding: 8px;
  }

  .toastCheckout {
    background-color: $gray-125;
    color: white;
  }

  .note {
    margin: 8px 0;
  }

  .guestCheckout {
    padding: 12px 0 12px 0;
    border: 0;
    .text {
      color: $link-blue;
    }
  }

  .borderedGuestCheckout {
    padding: 8px 0;
    border: 1.5px solid $gray-125;
    border-radius: 32px;
    .text {
      color: $gray-125;
    }
  }

  .text {
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 20px;
      vertical-align: text-top;
      margin-right: 5px;
    }
  }
}

.checkoutSection {
  .completeAccount {
    padding-bottom: 16px;
  }

  .createAccountLabel, .helpText {
    img {
      padding: 0 3px;
      height: 16px;
      vertical-align: top;
    }
  }

  .createAccountCheckbox {
    margin-top: 12px;
    font-size: 16px;
    .checkboxContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      .option {
        width: unset;
      }
      .createAccountLabel {
        display: flex;
        flex-direction: row;
        .imageContainer {
          height: 16px;
          padding-left: 4px;
        }
      }
      .loadingWrapper {
        display: flex;
        justify-content: center;
        padding-right: 16px;
      }
    }
  }

  .completedInfo {
    margin-top: 24px;
  }

  .yourInfoHeader {
    display: flex;
    justify-content: space-between;
    @include txt-family;
  }

  .editLink {
    cursor: pointer;
    border: 0;
    border-radius: 0;
    background-color: white;
    color: $link-blue;
    font-size: 16px;
    font-weight: 500;
    align-self: center;
  }

  .yourInfoContainer {
    // Used for a11y so we can logically place the update button last in the tab order
    // but have it still appear at the top of the form where the edit button was.
    // By making the yourInfoContainer a relative container and the updateLinkContainer
    // an absolute container the yourInfoContainer coordinate system becomes relative to the
    // yourInfoContainer.
    position: relative;
    .updateLinkContainer {
      position: absolute;
      right: 0;
      top: 12px;
    }
  }
}

.line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $black-12;
  line-height: 0.1em;
  margin: 20px 0;

  .lineText {
    color: $gray-100;
    background: white;
    padding:0 10px;
  }
}

.subheading {
  font-size: 16px;
  letter-spacing: 0.2px;
  margin-top: 25px;
}

.checkoutModeButtonGroup {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  > * {
    padding: 0;
  }
}
