@mixin fill-relative-parent($enabled) {
  @if $enabled =="true" {
    // when used, this will fill the space of the next relatively-positioned parent element.
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @if $enabled =="false" {
    position: inherit;
    top: inherit;
    bottom: inherit;
    left: inherit;
    right: inherit;
  }
}

.hero {
  &.templateHero {
    display: flex;
    justify-content: center;
    width: 100%;

    .heroWrapper {
      width: 100%;
    }
  }

  .image,
  .video {
    width: 100%;
    height: 328px;
    min-height: 48px;

    @include md-down {
      height: 268px;
    }

    @include sm-down {
      height: 200px;
    }

    &.large {
      height: 500px;

      @include md-down {
        height: 368px;
      }

      @include sm-down {
        height: 250px;
      }
    }
  }

  .video {
    object-fit: cover;
    height: 500px;

    @include md-down {
      height: 350px;
    }

    @include sm-down {
      height: 250px;
    }
  }

  .heroWrapper {
    position: relative;
    line-height: 0;

    &.rounded,
    &.outlined {
      // ignore-tailwind-class-check
      // NOTE: This class conflicts with a tailwind class, but the classname is set from a value provided by the API.
      // I have marked this border-radius as important so that this CSS takes precedence over tailwind.
      margin-top: 30px !important;
    }
  }

  .positionedCard {
    &:not(.top):not(.bottom) {
      &.rounded {
        // ignore-tailwind-class-check
        // NOTE: This class conflicts with a tailwind class, but the classname is set from a value provided by the API.
        // I have marked this border-radius as important so that this CSS takes precedence over tailwind.
        margin-top: 40px !important;
      }
    }
  }

  &.templateHero .heroWrapper .image,
  .heroBoxWrapper {
    height: 530px;
  }

  &.templateHero .heroWrapper .image.standalone {
    position: absolute;
  }


  .heroBoxWrapper:not(.wide) {
    @include md {
      @include padded-section;
    }
  }

  .imageScroller {
    &.halfLeaf .arrows {
      right: 1em;
    }

    @include sm-down {
      @include fill-relative-parent("true");
    }
  }


  .heroBoxWrapper.overImageScroller {
    @include fill-relative-parent("true");

    @include sm-down {
      @include fill-relative-parent("false");
    }
  }


  .heroBox {
    background: var(--ds-surface-tertiary, rgba(0, 0, 0, 0.45));
    color: var(--ds-text-default, white);
    display: flex;
    align-items: flex-end;
    padding-top: 64px;
    padding-bottom: 32px;

    @include sm-down {
      @include padded-section;
    }

    &.wide {
      background: var(--ds-surface-overlay, rgba(0, 0, 0, 0.45));
      color: var(--ds-text-on-overlay, white);
    }

    @include md {
      position: absolute;
      bottom: 80px;

      &:not(.wide) {
        padding: 32px;
        max-width: 600px;
      }

      &.wide {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .heroTextWrapper {
          align-self: center;
        }
      }
    }

    .heroTextWrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .heroText {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .heroHeader {
          font-size: 24px;
          line-height: 28px;

          @include sm-down {
            font-size: 22px;
            line-height: 25px;
          }
        }

        .heroBody {
          font-size: 18px;
          line-height: 21px;

          @include sm-down {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }

    &.outlined {
      background: var(--ds-surface-tertiary, rgba(255, 255, 255, 0.9));
      color: var(--ds-text-default, $gray-110);
      padding: 16px;

      .heroTextWrapper {
        width: 100%;
        padding: 24px;
        border: 2px solid var(--ds-border-default, $gray-110);
      }
    }
  }

  @include sm-down {
    &:not(.rounded):not(.outlined) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &.templateHero {
      .heroWrapper {

        .image,
        .heroBoxWrapper {
          height: 100%;
        }

        .heroBox {
          position: relative;
        }
      }
    }

    .heroBox.outlined .heroTextWrapper .heroButton {
      align-self: center;
    }
  }

  &.emptySection {
    min-height: 100px;
  }
}