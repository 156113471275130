.appPromo {
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  padding: 12px;
  border: 1px solid $ds-border-default;

  .promoContent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftContent {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .appIcon {
      width: 40px;
      height: 40px;
    }

    .downloadButton {
      background-color: $ds-background-primary;
      color: $ds-text-on-bg-primary;
      border-radius: 32px;
      padding: 12px 16px;
    }
  }
}
