$rx-card-padding: 10px;

.paginated-rxs {
  .rx-listing-title {
    margin-bottom: 12px;
    @extend %txt-title-lg; /* h2 with the style of an h3 */
  }

  .restaurantsMap {
    height: 500px;
  }

  .restaurants {
    width: calc(100% + #{2 * $rx-card-padding});
    margin-left: -$rx-card-padding;

    .rx-card-container {
      .rx-card-wrapper {
        padding: $rx-card-padding;
      }

      .link-to-rx-profile { padding-left: $rx-card-padding; }
    }

    .rx-card-container.disabled {
      .oo-link {
        pointer-events: none;
      }

      .rx-card {
        background: $light-gray;
        opacity: 60%;
        cursor: default;
      }
    }
  }

  .paginator {
    padding: 0px;
    text-align: center;
  }

  .viewButton {
    z-index: 10;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    background: white;
    border: 1.5px solid $ds-border-default;
    @extend %border-radius-full;
    padding: 12px;
    position: fixed;
    bottom: 48px;
    left: 50%;
    transform: translate(-50%, -50%);
    @extend %box-shadow-std;
  }
}
