.orderConfirmation {

  .checkoutSection {
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    @include sm-down {
      padding: 18px;
    }

    &.flat {
      border-radius: 0;
      box-shadow: none;
      @include sm-down {
        padding: 0;
      }
    }

    .subHeader {
      margin: 0;

      &:not(.rxName) {
        font-family: inherit;
      }
    }

    .checkoutSectionHeader {
      cursor: text;
      align-items: baseline;
      flex-wrap: wrap;
      gap: 8px;
      line-height: 24px
    }

    .fixedSection {
      padding-top: 0;
    }

    .fulfillmentUpdate {
      background-color: #EBF3FE;
      padding: 12px 24px;
      margin: 24px 0;
      text-align: center;
      border: 1px solid #BDD3F5;
    }
  }

  .checkNumber {
    font-size: 18px;
  }

  .linkText {
    color: $link-blue;
  }

  .address, .phone {
    font-size: 16px;
  }

  .sectionRows {
    padding-bottom: 8px;

    .sectionRow {
      font-size: 16px;

      &.noMargin {
        margin-bottom: 0;
      }

      &.instructions, &.directions a {
        font-size: 14px;
      }

      &.directions {
        a {
          font-weight: 500;
          color: $link-blue;
        }
      }
    }
  }

  .cartOrder .cartData .items {
    border-bottom: 0;
  }

  .totalPrice {
    font-weight: 500;
  }
}

.loyaltySection {
  margin: 32px 0;

  .loyaltyLoading {
    display: flex;
    justify-content: center;
  }
}

.orderSummary {
  padding: 16px 24px 16px 24px;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.10);
  border-radius: 8px;
  margin: 16px 0px;
}
