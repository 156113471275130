.locationSelectionWrapper {
  height: 100%;

  @include sm-down {
    padding: 0 !important;
  }
}

.locationSelectionContent {
  height: 100%;
  min-width: 300px;

  .optionsHeading {
    padding: 16px 16px 0;
  }

  .optionsLocationSelector {
    height: 100%;
    max-height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;

    &.basic {
      @include hide-scrollbar;
    }

    .topContent {
      padding: 24px;
      padding-bottom: 0;

      .toggle {
        margin: 12px 0;
      }

      &.basic {
        h3 {
          text-align: center;
        }
      }
    }

    .addressInput {
      input {
        border: 1px solid $light-gray;
      }
      display: flex;
      flex-direction: column;
    }

    .resultSection {
      flex: 2;
    }

    .poweredBy {
      padding: 24px 0;
      width: 100%;
      display: flex;
      justify-content: center;

      > img {
        width: 165px;
      }
    }

    .results {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      .prompt {
        font-size: 24px;
        line-height: 28px;
        text-align: center;

        &.ifFirstChild {
          display: none;

          &:first-child {
            display: block;
          }
        }
      }

      .location {
        width: 100%;
        padding: 16px;
        border-radius: 24px;
        transition: box-shadow 0.2s ease-out, border-color 0.2s ease-out;
        border: 1.5px solid var(--ds-border-default, $light-gray);

        &:hover {
          box-shadow: 0px 0px 20px var(--ds-surface-shadow, rgba(0, 0, 0, 0.15));
          cursor: pointer;
        }

        &.selected {
          border: 1.5px solid $ds-border-focus;
        }

        .unavailable {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 12px;
          padding: 8px 0;
        }

        .badges {
          display: flex;
          gap: 24px;
          font-size: 14px;
          padding: 12px 0;

          .optionBadge {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }

        .storeHours {
          font-size: 16px;
          padding: 12px 0;

          .schedule {
            padding-top: 18px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.5s ease-out;
            margin: 0;

            .hoursAndTooltip {
              display: flex;
              align-items: center;

              .tooltipContent {
                padding: 8px 16px;
                border-radius: 4px;
              }
            }
            .hoursWrapper {
              float: left;
            }

            .infoIcon {
              background: none;
              border: none;
              margin-left: 10px;
            }

            &.expanded {
              max-height: 400px;
            }

            .dayRow {
              display: flex;
              padding: 8px 0;
              grid-template-columns: 1fr 2fr;
            }

            .heading > span {
              font-weight: 500;
            }
            .bold {
              font-weight: 700;
              font-size: 16px;
            }

            dt,  dd {
              margin: 0;
            }

            dt {
              flex: 1;
              margin-right: 5px;
              text-align: left;
            }

            dd {
              flex: 2;
            }
          }

          .hoursToggle {
            display: flex;
            cursor: pointer;
            gap: 8px;
            align-items: center;
            border: none;
            background: none;
            padding: 0;
            font-size: 16px;
            width: 100%;
            color: $ds-icon-default;

            > img {
              transition: transform 0.2s ease-out;

              &.toggled {
                transform: rotate(180deg);
              }
            }
          }
        }

        .openTag {
          font-size: 16px;
          font-weight: 600;

          @include sm-down {
            font-size: 14px;
          }
        }

        .nameDistance {
          display: flex;
          justify-content: space-between;

          .name {
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            max-width: 70%;
            padding-right: 8px;

            @include sm-down {
              font-size: 16px;
            }
          }

          .distance {
            font-size: 14px;
            line-height: 28px;
          }
        }

        .locationInfo {
          font-size: 16px;
          line-height: 20px;
          padding-top: 8px;

          @include sm-down {
            font-size: 14px;
          }
        }

        .buttonLink a {
          color: $ds-text-on-bg-primary !important;
        }
      }
    }

    .header {
      font-weight: 600;
      font-size: 16px;
      padding-bottom: 8px;
      padding-top: 16px;
      padding-left: 13px; // padding of input + border
    }

    h3 {
      font-weight: 400;
      font-size: 32px;
      line-height: 38px;
      padding-bottom: 16px;
    }

    .locationSelector {
      .dropDown {
        height: 34px;
        line-height: normal;
        font-size: 16px;

        .icon {
          padding: 0 10px 0 0;
        }

        .dropDownArrow {
          width: unset;
        }
      }
    }

    .map {
      height: 100%;
      margin-top: 24px;
      &.hiddenView {
        display: none;
      }
    }
  }
}
