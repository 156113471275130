.dropDown {
  position: relative;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.withBorder {
    border: 1px solid $black-56;
    border-radius: 32px;
  }

  &.disabled {
    cursor: default;
  }

  .dropDownLabel {
    display: inline-block;
    white-space: nowrap;
  }

  & .arrow {
    padding: 5px;
  }

  .dropDownContent {
    button {
      background: transparent;
      font-size: inherit;
      border: inherit;
    }

    cursor: default;
    position: absolute;
    top: 100%;
    z-index: 7;
    @extend %box-shadow-std;
    @extend %border-radius-std;
    margin-top: 5px;
    @extend %themed-default-colors;
    max-height: 200px;
    overflow-y: scroll;
    align-items: inherit;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      background: transparent;
      width: 0;
      height: 0;
    }

    &.leftAligned {
      left: 0;
    }

    &:not(.leftAligned) {
      right: 0;
    }

    >* {
      @extend %themed-default-colors;
      display: block;
      cursor: pointer;
      line-height: 24px;

      &:not(.expandableSectionContent) {
        padding: 10px 20px;

        &:hover {
          background-color: $ds-surface-focus;
        }
      }

      &.expandableSectionContent a {
        &:hover {
          background-color: $black-4;
        }
      }
    }
  }
}

.option {
  text-align: left;

  &:focus {
    outline-offset: 2px;
    outline-style: auto;
    outline-width: 2px;
    -moz-outline-radius: 2px;
  }
}