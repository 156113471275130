$item-gap: 24px;
$mobile-image-gap: 16px;

.carouselWrapper {
  &.showOneItem {
    .carousel .itemsWrapper {
      & > div, & > li  {
        min-width: 100%;
      }
    }
  }

  .carousel {
    .itemsWrapper {
      display: flex;
      gap: $item-gap;
      overflow: hidden;
      padding-inline-start: 0;
      margin-block-start: 0;
      margin-block-end: 0;

      @include hide-scrollbar;

      & > div, & > li {
        flex-basis: calc(33% - 12px);
        flex-grow: 1;
        flex-shrink: 0;
      }
    }
  }

  @include sm-down {
    .carousel .itemsWrapper {
      flex-direction: column;
      gap: $mobile-image-gap;
    }

    &.showOneItem, &.mobileScrollCards {
      .carousel .itemsWrapper {
        flex-direction: row;
      }
    }

    &.mobileScrollCards {
      .carousel {
        > div {
          padding: 0 0 0 30px;
        }

        .itemsWrapper {
          overflow: scroll;
          padding-right: 30px;

          .carouselItem {
            width: 250px;
          }
        }
      }
    }
  }
}
