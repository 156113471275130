.fundraisingSelector {
  .fundraisingTitle {
    margin-bottom: 8px;
  }

  .fundraisingInfo {
    display: flex;
    justify-content: space-between;

    &.padded {
      margin: 24px 0;
    }

    .campaignName {
      font-weight: bold;
      font-size: 16px;
      color: #252525;
    }

    .causeParentName {
      font-size: 14px;
    }

    .campaignDescription {
      font-size: 14px;
      margin-top: 12px;
      margin-bottom: 16px;
      color: #252525;
    }

    div:first-child {
      margin-right: 8px;
    }
  }

  .campaignDisclosure {
    font-size: 12px;
    line-height: 1.5;
    color: $dark-gray;
    margin: 16px 0;
    
    a {
      color: $link-blue;
    }
  }

  .flat-rate-amounts {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 8px;

    .flat-rate-amount {
      flex: 1;
      text-align: center;
      min-height: 45px;
      border-radius: 8px;
      background-color: $gray-25;
      border: none;
      font-size: 14px;
      cursor: pointer;

      &.selected {
        color: white;
        background-color: $primary-blue-75;
      }
    }
  }
}
