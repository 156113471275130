.defaultTemplate.betterTogetherTemplate {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.betterTogetherPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.backgroundColor {
    background: linear-gradient(to bottom,
    $ds-background-primary 0%,
    $ds-background-primary 30%,
    rgba(255,255,255,0) 30%,
    rgba(255,255,255,0) 100%);
  }

  .postSubmit {
    color: black;
  }

  .background {
    display: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    inset: 66px 0 0 0;

    @include sm-down {
      &.left, &.right {
        display: block;
      }
    }
  }

  @include md {

    &.left, &.right {
      padding-top: 55px;
      padding-bottom: 126px;
    }

    &.left {
      flex-direction: row;
    }

    &.right {
      flex-direction: row-reverse;
    }
  }

  .image {
    &.above {
      height: 260px;
    }

    &.behind {
      height: 260px;
      position: absolute;
      width: 100%;
      inset: 66px 0 0 0;

      .betterTogetherWrapper {
        align-items: center;
      }
    }

    &.left, &.right {
      max-width: 30%;
      flex: 1;

      @include sm-down {
        display: none;
      }
    }
    @include md {
      &.left {
        padding-left: 15px;
      }
      &.right {
        padding-right: 15px;
      }
    }
  }

  @include md {
    &.left {
      flex-direction: row;
    }

    &.right {
      flex-direction: row-reverse;
    }
  }

  .betterTogetherWrapper {
    z-index: 1;
    flex: 1;

    display: flex;
    justify-content: center;
    overflow: auto;
    padding: 90px 0 28px;
    @include sm-down {
      padding: 50px 25px 50px 25px;
    }

    @include md {
      padding-left: 15px;
      padding-right: 15px;
    }

    .betterTogether {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      min-width: min(100%, 400px);
      max-width: 950px;
      background-color: white;
      border-radius: 10px;

      .heading {
        text-align: center;
        color: #252525;


        @include sm-down {
        .postSubmit {
          > h1 {
            font-size: 20px;
            line-height: 24px;
          }

          > h2 {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

        > h2 {
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;

          padding-top: 24px;
          padding-bottom: 40px;

          @include sm-down {
            font-size: 18px;
            line-height: 24px;
          }

          a, p { color: #252525; }
        }
      }

      .formInput {
        padding-bottom: 12px;
        font-size: 14px;

        .inputMessage {
          position: absolute;
        }
      }

      .submit {
        margin-top: 12px;
        width: 100%;
        max-width: 400px;
      }

      .fieldsWrapper {
        width: 100%;
        max-width: 400px;
      }

      .submit, .fieldsWrapper {
        margin-left: auto;
        margin-right: auto;
      }

      .disableClicks {
        pointer-events: none;
      }
    }
  }

  &.behind {
    .betterTogetherWrapper {
      margin-top: 120px;
      padding-bottom: 120px;

      @include sm-down {
        padding-top: 95px;
        padding-bottom: 25px;
      }

      .betterTogether {
        padding: 80px 180px;

        @include sm-down {
          padding: 25px  8px;
        }
      }
    }
  }

  &.left, &.right {
    padding-top: 100px;
    padding-bottom: 100px;
   }



  &.left, &.right {
    .betterTogether {
      align-items: flex-start;

      .heading {
        text-align: left;
      }
    }
    .disclaimer {
      padding: 20px 0;
    }
  }

  .disclaimer {
    padding: 20px;
    color: $text-color-gray;

    a {
      color: $text-color-gray;
      text-decoration: underline;
    }
  }

  &.catering {

    .cateringIframe {
      @include md-down {
        max-width: calc(100% - 260px);
        min-width: calc(100% - 260px);
      }

      @include sm-down {
        max-width: 520px;
        min-width: 520px;
        padding: 40px;
      }

      @include xs {
        max-width: calc(100% - 48px);
        min-width: calc(100% - 48px);
        padding: 40px 24px 40px 24px;
      }

      max-width: calc(100% - 480px);
      min-width: calc(100% - 480px);
      padding: 40px 80px 40px 80px;
    }

    &.above {
      .betterTogetherWrapper {
        padding: 0px;
      }
    }

    &.backgroundColor {
      background: none;

      .background.backgroundColor {
        background: none;
      }

      .betterTogetherWrapper {
        padding: 88px 0px;

        background: linear-gradient(to bottom,
        var(--tsw-primary-color) 0% 260px,
        rgba(255,255,255,0) 260px 100%);
      }
    }

    &.left, &.right {

      .cateringIframe {
        @include sm-down {
          max-width: 520px;
          min-width: 520px;
        }

        @include xs {
          max-width: calc(100% - 48px);
          min-width: calc(100% - 48px);
        }

        max-width: calc(100% - 136px);
        min-width: calc(100% - 136px);
      }

      .image {
        height: 550px;
      }

      .betterTogetherWrapper {
        padding: 0px;
      }
    }

    &.right {
      .image {
        padding-right: 80px;
      }

      .cateringIframe {
        @include sm-down {
          padding: 40px;
        }
        @include xs {
          padding: 40px 24px 40px 24px;
        }
        padding: 0 56px 0 80px;
      }
    }

    &.left {
      .image {
        padding-left: 80px;
      }

      .cateringIframe {
        @include sm-down {
          padding: 40px;
        }
        @include xs {
          padding: 40px 24px 40px 24px;
        }
        padding: 0 80px 0 56px;
      }
    }
  }
}

.footerWrapper {
  z-index: 1;
}
