.creditCardFormSpi {
  .disabledOverlay {
    pointer-events: none;
    opacity: 0;
    background-color: rgba(255, 255, 255,0.75);
  }

  .disabledOverlay.enabled {
    opacity: 1;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    pointer-events: initial;
  }
  
  .disabledWrapper {
    display: grid;

    > * {
      grid-area: 1 / 1;
    }
  }
}
