.location-dropdown-container {
  display: flex;
  flex-direction: column;

  .location-dropdown {
    display: flex;
    align-items: center;
    @extend %txt-body-lg;
    font-weight: 400;
  
    @include sm-down {
      flex-direction: column;
      align-items: start;
    }
  
    .location-dropdown-label, .selected-location, .address-input {
      display: inline-block;
      @include xs { font-size: 2rem; }
    }
  
    .location-dropdown-label {
      padding-right: 5px;
      white-space: nowrap;
      @extend %txt-source-sans-pro;
    }
  
    .location-dropdown-input {
      display: flex;
      flex-direction: column;
      width: 225px;

    @include sm-down {
      width: 100%;
    }
  
      .location-dropdown-input-wrapper {
        display: flex;
        width: 100%;

        .selected-location {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: $link-blue;
  
          .address { margin: 0 5px; }
        }
  
        .address-input {
          border-bottom: 2px solid blue;
          vertical-align: bottom;
          padding-bottom: 2px;
          width: 100%;
  
          input { height: 100%; }
        }
  
        .current-location {
          padding-left: 12px;
          cursor: pointer;
          height: 24px;
          align-self: center;
        }
      }
  
      .location-error {
        @extend %txt-source-sans-pro-bd;
      }
    }
  }
  
}