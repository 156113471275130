.globalNav {
  z-index: 8;
  position: relative;
  top: 0;
  left: 0;
  right: 0;

  @media (min-height: 450px) {
    &.fixedNav {
      position: fixed;
      filter: drop-shadow(0 4px 4px rgb(0, 0, 0, .1));
    }
  }

  &.stickyNav {
    position: sticky;
  }

  &.absoluteNav {
    position: absolute;

    &.spotlightOffset {
      top: 48px; // spotlight height
    }
  }
}
