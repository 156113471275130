.imageContain {
  object-fit: contain;
}

.imageCover {
  object-fit: cover;
}

.flippedHorizontally {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);

  &.flippedVertically {
    -webkit-transform: scaleY(-1) scaleX(-1);
    transform: scaleY(-1) scaleX(-1);
  }
}

.flippedVertically {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.styledImageWrapper {
  display: inherit;
  position: relative;
  width: 100%;
  height: 100%;

  .styledImageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &.rounded_shape {
    border-radius: 24px;
  }

  &.leaf_shape {
    border-radius: 100px 100px 100px 0;
  }

  &.outline_shape {
    border: solid 2px $ds-background-primary;
    background-color: none;
  }

  &.square_shape {
    border-radius: 0;
  }

  img, .styledImageOverlay {
    border-radius: inherit !important;
  }
}
