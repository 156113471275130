.locationsToggle {
  color: inherit;

  &::after {
    content: '\25be';
    padding: 5px;
  }

  &.collapsed::after {
    transform: rotate(180deg);
  }

  &:hover {
    text-decoration: none;
  }
}
