$card-shadow: 10px;

.reviewsSection {

  blockquote {
    margin-block-end: 0;
    margin-block-start: 0;
    margin-inline-end: 0;
    margin-inline-start: 0;
  }

  .reviewCardWrapper, .reviewCard, .reviewWrapper, .reviewContent {
    height: 100%;
  }

  .reviewCardWrapper {
    padding: $card-shadow;

    .reviewCard {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 0 0 $card-shadow var(--ds-surface-shadow, $black-12);
      border-radius: 22px;
      padding: 64px 60px 64px 50px;
      @extend %default-colors;

      @include sm-down {
        padding: 40px;
      }

      .reviewContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .authorRating {
          margin-top: 20px;

          .star-img {
            margin-right: 6px;
          }
        }
      }
    }
  }

  &.wide {
    background-color: $gray-125;
    color: white;
    padding: 0;

    .carouselArrows {
      padding-bottom: 75px;
    }

    .reviewCardWrapper .reviewCard {
      background-color: $gray-125;
      color: white;
      box-shadow: none;
      border: none;
      padding-bottom: 0;

      @include md {
        padding-top: 125px;
      }

      .reviewWrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .startQuote, .endQuote {
          font-family: Helvetica !important;
        }

        .startQuote {
          flex-grow: 0;
          align-self: flex-start;
          font-size: 96px;
          padding-right: 20px;
          padding-bottom: 20px;

          @include sm-down {
            font-size: 40px;
          }
        }

        .quote .span p,h1,h2,h3,h4 {
          font-weight: 700;
          font-size: 36px;
          line-height: 41px;

          @include sm-down {
            font-size: 18px;
            line-height: 24px;
          }

          .endQuote {
            padding-left: 1rem;
          }
        }
      }
    }
  }

  &.emptySection {
    min-height: 100px;
  }
}
