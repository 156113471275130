.fulfillmentControl {
  display: flex;
  padding: 12px 20px;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  background: $ds-surface-secondary;
  color: var(--ds-text-secondary, var(--tsw-primary-text-color));

  @include xs {
    width: 100%;
    padding: 12px 0;
    background: $ds-surface-default;
    &.orderingUnavailable {
      padding: 20px 0;
      flex-direction: row-reverse; // For mobile, put icon second
    }
    border: none;
  }

  @include sm {
    border-radius: 8px;
    &.roundedCornerNone {
      border-radius: 1px;
    }
    &.roundedCornerExaggerated {
      border-radius: 20px;
    }
    border: 0.75px solid var(--ds-border-default, var(--tsw-primary-text-color-50));
    box-shadow: 0 2.5px 6px 0 #00000006;
    cursor: pointer;

    &:hover:enabled:not(:active) {
      border: 0.75px solid var(--ds-border-focus, var(--tsw-primary-text-color-75));
      box-shadow: 0 2.5px 6px 0 #00000012;
    }

    &:disabled {
      cursor: default;
    }
  }

  .fulfillmentControlContent {
    display: flex;
    gap: 16px;

    .fulfillmentBehavior {
      color: $ds-text-action;
      font-weight: 600;
      align-self: center;
    }

    .separator {
      background: linear-gradient(
        transparent 0%,
        var(--ds-border-default, var(--tsw-primary-text-color-50)) 25%,
        var(--ds-border-default, var(--tsw-primary-text-color-50)) 75%,
        transparent 100%
      );
      width: 1px;
    }

    .fulfillmentDetails {
      text-align: start;
      @include xs {
        font-size: 14px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 17px;
    padding: 0 3px;
    &.loading {
      padding: 0; // make a little extra room for the loading spinner
    }
  }

  &.orderingUnavailable {
    color: $ds-text-action;
    font-weight: 600;
    gap: 8px;
    align-items: center;

    @include sm {
      background-color: var(--ds-surface-tertiary, var(--tsw-background-color));
    }
  }
}

.fulfillmentTooltip {
  display: flex;
  align-items: center;
  gap: 4px;
}
