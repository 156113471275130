.squaresSection {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .square {
    width: calc(50% - 5px);
    min-width: calc(50% - 5px);
    padding: 12px 0;
    background-color: #E5E5E5;
    @include txt-body-md-bold;
    text-align: center;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .occasionIcon {
        display: flex;
        align-items: center;
        i, img {
            margin-top: 1px;
            margin-right: 0.4rem;
        }
    }
  }
}

.menuDividingLine {
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-top: 1px solid black;
  opacity: 0.12;
  margin: 0px;

  &.menuDividingLineMarginTop {
    margin-top: 20px;
  }

  &.menuDividingLinePaddingBottom {
    padding-bottom: 10px;
  }
}

.depositMessage {
  @include txt-body-sm;
  padding-bottom: 8px;
  color: $gray-100;

  span {
    font-weight: 600;
    color: $gray-125;
  }

  &.bold {
    font-weight: 600 !important;
  }

  &.extraPadding {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &.noPaddingBottom {
    padding-bottom: 0px;
  }

  &.morePaddingBottom {
    padding-bottom: 16px;
  }

  &.black {
    color: rgba(37, 37, 37, 1);
  }
}

.guestForm {
  padding: 16px 0;

  &.withDeposit{
    padding: 0px;
  }
}

.cancellationTitleSection {
  @include txt-title-md;
  font-size: 1rem;
  padding-top: 20px;
  padding-bottom: 16px;
  color: $gray-125;
}

.backButton{
  display: flex;
  align-items: center;
  margin-left: 16px;

  .changeSelection {
    width: 100%;
    border: none;
    color: rgba(37, 37, 37, 1);
    background: none;
    @include txt-body-md-bold;
    padding-top: 0px;
    margin-bottom:1px;
  }
}

.cancellationPolicy, .disclaimer {
  @include txt-body-sm;
  padding: 0 0 16px 0 !important;
  color: $gray-100;
}
