.guestSelector, .resLocationSelector, .selector {
  .dropDown {
      @extend %txt-body-md;
      height: 48px;
      border-radius: 8px;
    
      .selected, .selectedLocation {
        vertical-align: middle;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .dropDownContent {
        width: 100%;
      }
  }
}
