.imageScroller {
  width: 100%;
  height: 100%;
  position: relative;

  .images {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;

    & .scrollerImageContainer {
      // A container that automatically fills the image into this element's area.
      position: relative;
      min-width: 100%;
      min-height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  }

  & .arrows {
    // position
    position: absolute;
    right: 0;

    &.bottom {
      bottom: 0;
    }

    &.top {
      top: 0;
    }

    z-index: 2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & .counter {
      color: #ffffff;
      font-size: 1em;
    }

    & button {
      padding: 0.75em;

      // reset
      background: none;
      border: none;

      &.disabled {
        opacity: 0.5;
      }

      &:not(.disabled) {

        &:active,
        &:hover {
          border: none;
          background: none;

          &>img {
            background: rgba(0, 0, 0, 0.6);
          }
        }
      }

      &>img {
        padding: 2.2rem;
        background: rgba(0, 0, 0, 1);
        clip-path: circle(30px at 50% 50%);
      }

      &.btn--left {
        transform: rotateY(180deg);
      }
    }
  }

  &.md-compactButtons {
    @include md-down {
      button {
        padding: 0.25em;

        &>img {
          padding: 25px;
          transform: scale(0.6);
        }
      }
    }
  }
}

.squareScroller {
  width: 400px;
  height: 400px;
}