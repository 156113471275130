.trackerMapFrame {
  flex-basis: 50%;
  height: 199px; // 200 or greater causes google to add additional buttons
  overflow: hidden;
  border-radius: 10px;

  iframe {
    border: none;
    box-sizing: border-box;
  }
}
