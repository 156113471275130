.scheduleMap {
  width: 100%;
  display: flex;

  @include md {
  max-height: 340px;
  }

  @include sm-down {
    flex-direction: column;
  }

  .schedule {
    padding: 45px;

    @include md-down {
      padding: 24px;
    }

    @include sm-down {
      align-items: flex-start;
    }

    flex-basis: 50%;
    color: white;
    background-color: #242424; // TODO: configurable
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      max-width: 100%;
    }

    .header {
      margin: 0 0 20px;
    }

    .day, .times {
      @extend %txt-title-sm;
    }

    .phoneNumber {
      margin-top: 16px;

      > a {
        color: white;
      }
    }

    .hours {
      overflow: scroll;
      margin-top: 20px;
      @include hide-scrollbar;
      display: flex;

      .times {
        min-width: 50px;
        font-weight: 400;
        padding-right: 16px;
      }
    }
  }
}

$desktop-margin: 80px;
$mobile-margin: 30px;

.mapFrame {
  flex-basis: 50%;
  height: 199px; // 200 or greater causes google to add additional buttons
  overflow: hidden;
  margin-top: $desktop-margin;

  @include sm-down {
    margin-top: $mobile-margin;
  }

  iframe {
    border: none;
    box-sizing: border-box;
  }
}
