.creditCardModalContent {
  padding: 16px;
  max-width: 540px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      padding: 8px 0;
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
    }

    .closeButton {
      margin: 0 8px;
    }
  }

  .note {
    font-size: 10px;
    line-height: 12px;
    color: $dark-gray;
    margin-top: 5px;
  }
}

.creditCardModalButton {
  cursor: pointer;
  padding: 0 20px;
  margin: 0 auto 20px;
  height: 40px;
  width: 200px;
  border-radius: 20px;
  border: none;
  color: white;
  @include txt-title-sm;

  &:disabled {
    background-color: $primary-blue-0;
    color: $dark-gray;
    cursor: default;
  }
}
