.popupModal {
  @include hide-scrollbar;

  >* {
    @include hide-scrollbar;
  }
}

.popup {
  padding: 0 24px 24px;

  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 500px;

  border-radius: 24px;

  .popupContent {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include md-down {
      gap: 16px;
    }

    .popupBody {
      display: flex;
      gap: 16px;
      overflow: scroll;

      @include md-down {
        flex-direction: column;
      }

      .image {
        object-fit: cover;
        max-width: 50%;

        @include md-down {
          max-width: 100%;
        }
      }
    }
  }

  .popupActions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 0.5em;

    @include sm-down {
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      flex-wrap: wrap;

      &>button {
        width: 100%;
      }
    }
  }

  .promoCode {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;
    padding-top: 24px;

    .bannerText {
      font-size: 18px;
      padding: 8px 0;
    }

    .codeSection {
      padding-top: 16px;
      position: relative;
      display: flex;
      gap: 8px;
      align-items: center;

      .code {
        font-size: 24px;
        font-weight: 600;
        cursor: pointer;
      }

      .copyMessage {
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0;
        @extend %txt-body-sm;
        pointer-events: none;
        transition: opacity 0.5s ease-out;
        color: $gray-125;
        background-color: $primary-blue-0;
        padding: 4px;
        border-radius: 4px;
        width: fit-content;
        margin: auto;

        &.show {
          opacity: 1;
        }
      }

      .link {
        color: $primary-blue;
        font-weight: 600;
      }
    }
  }
}

.popupHeader {
  display: flex;
  justify-content: flex-end;
  min-height: 50px;

  .closeButton {
    cursor: pointer;
  }
}

.modalWrapper.popupShelf {
  padding: 0;
  justify-content: flex-end;

  .content {
    height: 100%;
    border-radius: 0;

    @include sm-down {
      max-width: 314px;
    }
  }
}

.popupWrapper {
  &.topLeft {
    justify-content: flex-start;
    align-items: flex-start;
  }

  &.topRight {
    justify-content: flex-end;
    align-items: flex-start;
  }

  &.bottomLeft {
    justify-content: flex-start;
    align-items: flex-end;
  }

  &.bottomRight {
    justify-content: flex-end;
    align-items: flex-end;
  }

  .modalContent {
    max-width: 100%;
  }
}

.editorPopup {
  position: fixed;
  inset: 0 0 0 0;
  max-height: calc(100vh - 8rem);
  z-index: 12;

  .editorPopupOverlay {
    position: fixed;
    inset: 0 0 0 0;
  }

  .editorPopupWrapper {
    padding: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    &.popupShelf {
      padding: 0;
      justify-content: flex-end;
      height: 100%;

      .editorPopupContents {
        height: 100%;
        border-radius: 0;

        @include sm-down {
          max-width: 314px;
        }
      }
    }

    &.topLeft {
      justify-content: flex-start;
      align-items: flex-start;
    }

    &.topRight {
      justify-content: flex-end;
      align-items: flex-start;
    }

    &.bottomLeft {
      justify-content: flex-start;
      align-items: flex-end;
    }

    &.bottomRight {
      justify-content: flex-end;
      align-items: flex-end;
    }

    .editorPopupContents {
      position: relative;
      border-radius: 24px;
      max-width: 100%;
    }
  }
}
