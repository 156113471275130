$hover-padding: 16px;

.menuSection {
  ul {
    all: inherit;
    list-style-type: none;
    flex: inherit;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      flex: 1;
      display: flex;
      a:hover {
        text-decoration-line: none;
      }
    }
  }
  padding-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.topImageV2, &.leftImageV2, &.classic {
    .headerWrapper {
      h3 {
        @extend %txt-title-md;
        font-size: 18px;
        line-height: normal;
      }

      .description {
        @extend %txt-body-md;
        font-size: 16px;
      }
    }
  }

  .headerWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    h3 {
      @extend %txt-title-md;
    }

    .description {
      @extend %txt-body-md;
      padding-top: 8px;
      color: $ds-text-secondary;

      &.primaryTextColor {
        color: $ds-text-default;
      }
    }

    .separator {
      height: 4px;
      border: none;
      margin: 2px 0;
    }
  }

  .itemSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include xs {
      grid-template-columns: 1fr;
    }
    &.topImageV2 {
      grid-template-columns: 1fr 1fr 1fr;
      @include md-down {
        grid-template-columns: 1fr 1fr;
      }
      @include xs {
        grid-template-columns: 1fr;
      }
    }
    grid-template-rows: 1fr auto;
    gap: 24px;
    width: 100%;

    &.mobileOptimizedContainer {
      @include xs {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }

    &.compact {
      &.listView {
        gap: 16px;

        .item {
          box-shadow: none;

          &.clickable {
            &:hover {
              box-shadow: none;
            }
          }

          .itemInfo {
            padding: 8px 16px;
          }
        }
      }

      @include lg {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    .item {
      a {
        display: flex;
        width: 100%;
      }
      width: 100%;
      @extend %theme-menu-card-default-colors;
      min-width: 100%;
      height: 136px;
      flex: 1;
      @include xs {
        height: 122px;
        &.mobileOptimized {
          height: 115px;
        }
      }
      overflow: hidden;
      border-radius: 24px;
      box-shadow: 0 0 6px var(--ds-surface-shadow, rgba(0, 0, 0, 0.1));
      transition: box-shadow 0.15s ease-out;
      display: flex;
      flex-direction: row;
      text-align: start;
      padding: 0;
      border: 0;
      align-items: normal;
      &:hover {
        text-decoration: none;
        cursor: default;
      }

      .itemHeader {
        font-size: 18px;
        line-height: 1.25rem;

        @include sm-down {
          font-size: 16px;
        }
      }

      &.right {
        a {
          flex-direction: row-reverse;
        }
      }

      @include sm-down {
        justify-content: flex-start;
      }

      .placeholderImg {
        overflow: hidden;
      }

      &.topImageV2 {
        @include sm {
          @include topImageTemplateV2;
        }
      }

      &.topImageV2:not(.mobileOptimized) {
        @include xs {
          @include topImageTemplateV2;
        }
      }

      &.leftImageV2 {
        .itemImage {
          border-right: 0.75px solid;
        }
      }


      &.topImageV2, &.leftImageV2, &.classic {
        // to absolute position children like tags inside this container
        position: relative;

        .offerBadge {
          position: absolute;
          padding: 6px 8px;
          border-radius: unset;

          &.rightAlign {
            right: 0;
            border-bottom-left-radius: 4px;
          }
          &.leftAlign {
            left: 0;
            border-bottom-right-radius: 4px;
          }
        }

        .tag {
          font-size: 10px;
          font-weight: 600;
        }

        &.borderStrokeExaggerated {
          &.bgShadeLight {
            border: 2px solid var(--ds-border-default, #e0e0e0);
          }
          &.bgShadeDark {
            border: 2px solid var(--ds-border-default, #FFFFFF14);
          }
        }

        &.borderStrokeSubtle {
          &.bgShadeLight {
            border: 0.75px solid var(--ds-border-default , #e0e0e0);
          }
          &.bgShadeDark {
            border: 0.75px solid var(--ds-border-default, #FFFFFF14);
          }
        }

        &.bgShadeLight {
          .itemImage {
            border-color: var(--ds-border-default, #e0e0e0);
          }
        }
        &.bgShadeDark {
          .itemImage {
            border-color: var(--ds-border-default, #FFFFFF14);
          }
        }

        &.roundedCornerNone {
          border-radius: 1px;
        }

        &.roundedCornerSubtle {
          border-radius: 8px;
        }

        &.roundedCornerExaggerated {
          border-radius: 20px;
        }

        &.dropShadowNone {
          box-shadow: none;
        }

        &.dropShadowSubtle {
          box-shadow: 0px 2px 4px 0px var(--ds-surface-shadow, #e0e0e0), 0px 0px 6px 0px #00000005;
        }

        &.dropShadowExaggerated {
          box-shadow: 0px 4px 8px 0px var(--ds-surface-shadow, #0000000F), 0px 0px 4px 0px #0000000A;
        }

        .itemHeader {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          font-size: 16px;
          text-overflow: ellipsis;
        }

        .itemContent {
          padding-top: 0;
          padding-bottom: 0px;

          .itemDescription {
            @extend %line-clamp-unset;
            display: block;
          }

        }

        &.mobileOptimized {
          @include xs {
            position: relative;
            left: -12px;
            box-shadow: none;
            border-radius: 0;

            // use left/right margin so bottom border does not stretch from edge to edge
            margin: 0 12px;
            padding:16px 0;
            width: 100%;
            border-left: 0;
            background-color: $ds-surface-default !important;
            color: $ds-text-default !important;

            overflow: visible;

            .priceAvailability {
              display: flex;
              flex-direction: column-reverse;
              gap: 12px;
              align-items: flex-start;
            }

            a {
              background-color: var(--tsw-primary-bg-color) !important;
            }

            .itemDescription {
              color: $ds-text-default !important;
            }

            .headerText {
              color: $ds-text-default !important;
            }

            .priceAvailability {
              margin-top: 0;

              .price {
                color: $ds-text-default !important;
              }
            }

            &.bgShadeLight {
              border: 0;
              border-bottom: 0.75px solid var(--ds-border-default, #e0e0e0);

              &:last-child {
                border-bottom: none;
              }

              .itemImage {
                border: 0.75px solid var(--ds-border-default, #e0e0e0);
                border-radius: 8px;
                aspect-ratio: 1/1;
              }
            }
            &.bgShadeDark {
              border: 0;
              border-bottom: 0.75px solid var(--ds-border-default, #FFFFFF14);

              &:last-child {
                border: none;
              }

              .itemImage {
                border: 0.75px solid var(--ds-border-default, #FFFFFF14);
                border-radius: 8px;
                aspect-ratio: 1/1;
              }
            }

            .itemImage {
              margin-right: 16px;
            }

            &.classic {
              .itemImage {
                margin-right: 12px;
                margin-left: 16px;
              }
            }

            /* Remove chevron for now. Keep, just incase we want to add it back as the nbsp workaround to line wrap is a bit obscure.
            .headerText:after {
              // add a non-breaking space \00a0 so that the carrot does not break by itself to a new line
              content: '\00a0›';
            }
            */

            .itemInfo {
              padding: 0;
              justify-content: center;
              gap: 12px;
            }
          }
        }
      }

      &.classic {
        .itemImage {
          border-left: 0.75px solid;
        }
      }

      &.clickable:not(.outOfStock) {
        @media (hover: hover) {
          &:hover, &:focus-within {
            &:not(.mobileOptimized) {
              box-shadow: 0px 0px 35px var(--ds-surface-shadow, rgba(0, 0, 0, 0.2));
            }

            @include xs {
              &.mobileOptimized {
                // stretch hover from edge to edge
                margin: 0;
                padding: 16px 12px;
                background-color: $ds-surface-focus !important;

                a {
                  background-color: transparent !important;
                }
              }
            }

            @include md-up {
              box-shadow: 0px 0px 35px var(--ds-surface-shadow, rgba(0, 0, 0, 0.2));
            }

            cursor: pointer;
          }
        }
      }

      &.outOfStock,
      &.outOfStock:hover {
        color: $text-color-disabled;

        a {
          cursor: default;
        }

        .itemDescription {
          color: $text-color-disabled;
        }

        img {
          filter: grayscale(100%);
        }
      }
    }

    .itemInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 16px;
      flex: 1;

      .priceAvailability,
      .availability {
        font-size: 14px;
        line-height: normal;
        display: flex;
        gap: 12px;
        align-items: center;
        margin-top: auto;
      }

      .price {
        &.outOfStock {
          text-decoration: line-through;
          font-style: italic;
        }
      }

      &.outOfStock {
        opacity: 66%;
      }
    }

    .itemImage {
      object-fit: cover;
      height: 100%;

      &.imageRatioLarger, &.imageRatioTaller {
        aspect-ratio: 4/3;
      }

      &.squareImage, &.imageRatioSmaller {
        aspect-ratio: 1/1;
      }

      &.imageRatioShorter {
        aspect-ratio: 16/9;
      }

      &.outOfStock {
        opacity: 33%;
      }
    }

    .itemHeader {
      font-weight: 600;
      @extend %line-clamp-2;
      // Limit the header to a single line if there's a menu item tag taking up space above it
      &.hasTag {
        @extend %line-clamp-1;
      }
    }

    .itemFooter {
      padding: 8px 16px;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      line-height: 24px;
      transition: box-shadow 0.15s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;

      .action {
        color: white;
        transition: opacity 0.15s ease-out;
        opacity: 0;
        white-space: nowrap;
      }
    }

    .itemContent {
      .itemDescription {
        @extend %line-clamp-2;
        font-size: 14px;
        color: $text-color-gray;
        line-height: 16px;
      }
    }
    &.squaredCorners .item {
      border-radius: 0;
    }
  }

  &.condensed,
  &.extraCondensed,
  &.stacked {
    h3 {
      @include txt-title-lg;
      font-weight: 400;
      line-height: normal;
      text-align: start;

      @include sm-down {
        font-size: 32px;
      }
    }

    .menuSectionHeader {
      align-items: center;
      background: none;
      border: none;
      color: $ds-text-default;
      display: flex;
      justify-content: space-between;
      padding: 0;
      width: 100%;

      &.button {
        cursor: pointer;
      }

      > img {
        transition: transform 0.2s ease-out;

        &.expanded {
          transform: rotate(180deg);
        }
      }
    }

    .itemSection {
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-out;
      background-color: var(--ds-surface-secondary, white);

      .item {
        height: 100%;
      }

      .item .itemInfo.condensed {
        padding: 0;
      }

      .sectionSeparator {
        margin: 20px 0;
        max-height: 0px;
        width: 100%;
        max-width: 100%;
      }

      &.expanded {
        max-height: max-content;
      }
    }
  }

  &.stacked {
    .menuSectionHeader,
    .itemHeader,
    .availability,
    .itemContent {
      justify-content: center;
    }

    .description,
    .header {
      text-align: center;
    }

    .itemSection {
      @include sm-down {
        gap: 0px;
      }

      .sectionSeparator {
        margin-bottom: 0;
      }

      .itemInfo {
        .itemHeadAvailDesc {
          padding-right: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .itemHeader {
          padding-bottom: 8px;
          @include txt-body-xl;

          @include sm-down {
            @include txt-body-lg-bold;
            line-height: normal;
          }
        }

        .itemDescription {
          -webkit-line-clamp: inherit;
        }
      }
    }

    .itemContent {
      @include txt-body-sm;
      color: $text-color-gray;
    }
  }

  &.card {
    border-radius: 24px;
    box-shadow: 0px 0px 6px var(--ds-surface-shadow, rgba(0, 0, 0, 0.1));
  }
}

.menuSectionWrapper.columns {
  .menuSection {
    padding: 0;

    .sectionSeparator {
      max-width: 100%;
      margin: 18px 0;
    }

    .itemSection.columns {
      display: block;
      column-count: 2;
      column-gap: 58px;

      @include sm-down {
        display: flex;
        column-count: 1;
      }
      .item {
        padding: $hover-padding;
        width: auto;

        @include sm-down {
          width: 100%;

          &.clickable {
            width: calc(100% - (2 * $hover-padding));
          }
        }

        .itemInfo {
          break-inside: avoid;
        }
      }
    }
  }
}

.menuSectionWrapper.stacked {
  .headerWrapper {
    align-items: center;
  }
}
