.defaultTemplate.formTemplate {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.formPage {
  flex: 1;
  display: flex;
  flex-direction: column;

  .postSubmit {
    color: black;
  }

  .image {
    &.above {
      height: 260px;
    }

    &.behind {
      height: 260px;
      position: absolute;
      width: 100%;
      inset: 66px 0 0 0;
    }

    &.left, &.right {
      max-width: 30%;
      flex: 1;

      @include sm-down {
        display: none;
      }
    }
  }

  &.behind {
    margin-top: 100px;

    .formWrapper {
      align-items: center;
    }
  }

  @include md {
    &.left {
      flex-direction: row;
    }

    &.right {
      flex-direction: row-reverse;
    }
  }

  @include md-up {
    &.left, &.right {
        .formWrapper {
          padding-top: 40px;
        }
    }
  }

  .formWrapper {
    z-index: 1;
    flex: 1;

    display: flex;
    justify-content: center;
    overflow: auto;
    padding: 28px 0 28px 0;

    .form {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      min-width: min(100%, 400px);
      background-color: white;

      .heading {
        color: #252525;
        > h1 {
          font-weight: 400;
          font-size: 40px;
          line-height: 52px;
          color: #252525;

          a, p { color: #252525; }
        }

        > h2 {
          font-size: 18px;
          font-weight: 400;
          line-height: 20px;
          color: #252525;

          a, p { color: #252525; }
        }
      }

      .formInput {
        padding-bottom: 12px;
        font-size: 14px;

        .inputMessage {
          position: absolute;
        }
      }

      .submit {
        margin-top: 12px;
        width: 100%;
      }

      .fieldsWrapper {
        width: 100%;
      }

      .disableClicks {
        pointer-events: none;
      }
    }
  }
}

.footerWrapper {
  z-index: 1;
}
