.toastCashPaymentToggleWrapper {
  padding: 16px 8px;
  // Border to match credit card border treatment
  border-bottom: 1px solid #d3d3d3;

  .toastCashPaymentToggle {
    .toastCashIcon {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
}