.location-container {
  & > p {
    font-size: 18px;
    line-height: 22px;
    margin: 8px 0;
  }

  .location-img {
    max-width: 100%;
    height: auto;
    border: 0;
  }
}
