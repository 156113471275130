.depositPayment {
  .depositDescription {
    .depositDescriptionTitle {
      @include txt-title-sm;
      padding: 16px 0 16px 0;
      color: $gray-125;
    }

    .depositMessage {
      @include txt-body-sm;
      padding-bottom: 16px;
    }

    .depositPaymentContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .depositAmountDue {
        @include txt-title-md;
        padding-bottom: 16px;
        color: rgba(37, 37, 37, 1);
      }
    }
  }

  .countdown {
    @include txt-body-sm;
    text-align: center;
    padding-bottom: 16px;

    span {
      font-weight: bold;
      color: rgba(212, 0, 35, 1);
    }
  }

  .depositError {
    @include txt-body-sm;
    background: rgba(255, 230, 233, 1);
    border-radius: 4px;
    padding: 0.75em 1em;
    margin: 0.5em;

    display: flex;
    flex-direction: row;
  }
}
