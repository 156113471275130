.packagingOptionsSelector {
  .packagingOptionsGuestMessage {
     font-size: 16px;
     color: #252525;
  } 
 
  ul {
   list-style-type: none;
   padding: 0;
 
   li {
     margin-bottom: 16px;
     p {
      font-size: 16px;
     }
   }
  }
 
  .packagingItemDescription {
     font-size: 14px;
     line-height: 1.5;
     color: $dark-gray;
  }
}