.photoSection {
  display: flex;

  @include sm-down {
    flex-direction: column;
  }

  &.right {
    @include sm-down {
      flex-direction: column-reverse;
    }
  }

  .textContent {
    flex-basis: 50%;

    @include md {
      &.left {
        padding-right: 75px;
      }

      &.right {
        padding-left: 75px;
      }
    }

    .title {
      font-size: 68px;
      line-height: 68px;

      @include md-down {
        font-size: 32px;
        line-height: 32px;
      }
    }

    .separator {
      margin: 12px 0;
      max-width: 100px;
      height: 4px;
      border: none;

      @include md-down {
        margin: 8px 0;
      }
    }

    .body {
      @include lg {
        max-width: 550px;
      }
    }

    .ctas {
      margin: 32px 0;

      @include sm-down {
        display: flex;
        justify-content: center;
      }
    }
  }

  .photoContent {
    flex-shrink: 1;
    flex-basis: 50%;
    display: flex;
    position: relative;

    img {
      border-radius: 6px;
      filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .anchorPhoto {
      position: relative;
      width: 400px;
      min-height: 150px;

      @include md-down {
        width: 285px;
      }

      @include sm-down {
        display: none;
      }

      .mainImg {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .featureImg {
        position: absolute;
        bottom: -50px;
        width: auto;
        max-height: 75%;
        max-width: 50%;
      }
    }

    &.left {
      justify-content: flex-end;

      @include sm-down {
        justify-content: center;
      }

      .featureImg {
        left: -50px;
      }
    }

    &.right {
      justify-content: flex-start;

      @include sm-down {
        justify-content: center;
      }

      .featureImg {
        right: -50px;
      }
    }
  }
}
