.completeProfileForm {
  font-family: 'Effra';
  letter-spacing: 0.2px;
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  .formBody {
    .phoneNumberBox {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 9px 12px 11px;
      gap: 8px;
      margin-bottom: 20px;

      background: $gray-25;

      border: 1px solid $gray-50;
      border-radius: 8px;

      font-size: 16px;
      line-height: 19px;

      color: $gray-100;
    }

    .legal {
      font-size: 10px;
      text-align: center;
      color: #646464;
      margin-top: 16px;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  .footer {
    background: #FFFFFF;

    .submitBtn {
      color: white;
      background: #252525;
      width: 100%;
      border-radius: 8px;

      &:disabled {
        background: rgba(0, 0, 0, 0.25);
        cursor: default;
      }
      &:not(:disabled):hover {
        color: darken(#FFFFFF, 10%);
        background: darken(#252525, 10%);
      }
    }
  }

  .formError {
    color: $error-red;
    @extend %txt-body-sm;
    margin: 10px 0 5px;
  }
}
