.previewModeBanner {
  background-color: #2B4FB9;
  color: #E7EAF6;
  height: 80px;
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  justify-content: center;
  grid-gap: 30px;

  p {
    align-self: center;
  }
}

.previewModeModalContent {
  width: 600px;
  height: 220px;
  padding: 25px 20px;
}
