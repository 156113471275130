.toastLocalNav {
  .posPromoMini {
    display: flex;
    align-items: center;
    gap: 10px;
    @extend %txt-source-sans-pro;

    .promoForRestaurant {
      font-weight: 600;
      display: flex;

      .promoHyphen {
        margin: 0 6px;
      }
    }

    .verticalBar {
      width: 1px;
      background-color: $text-color-gray;
      height: 20px;
      margin: 0 10px; 
    }

    .promoMobileLinks {
      display: flex;
      justify-content: space-between;
      gap: 16px;
    }

    .navPromoLink {
      color: $primary-blue;
      font-weight: 600;
    }

    .navPromoLink:hover {
      color: $primary-blue-100;
      font-weight: 600;
    }
  }

}

  .userNavLoginBtn {
    color: $primary-blue;
    border: 1px solid $primary-blue;
    padding: 10px 12px !important;
    margin-left: 10px;
    border-radius: 8px;
    background-color: transparent; 
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    
    &:hover {
      background-color: $primary-blue;
      color: white;
    }
}
