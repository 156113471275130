.imageUpsellItemGrid {
  cursor: pointer;
  display: grid;
  grid-template:
          "image" 88px
          "title" 52px
          "price" 14px;
  grid-gap: 16px;

  .image {
    grid-area: image;

    position: relative;

    .imgWrapper {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 8px;
    }

    .quickAdd {
      position: absolute;
      right: 8px;
      bottom: 8px;
      cursor: pointer;
    }
  }


  .title {
    grid-area: title;

    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    color: black;
  }

  .price {
    grid-area: price;

    font-size: 12px;
    font-weight: 400;
    color: black;
  }
}
