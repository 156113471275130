.modal .modalWrapper .diningOptionsModalContent {
  padding: 16px;
  min-width: 350px;
  overflow-y: visible;
  @include xs {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    padding: 24px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 18px;
      font-weight: 500;
    }
  }

  @include sm-down {
    width: 100%;
  }
}

.diningOptions {
  border: none;
  background-color: transparent;
  padding: 8px 0 8px;
  color: var(--tsw-primary-color);
  cursor: pointer;

  border-top: 1px solid var(--tsw-primary-color);
  border-bottom: 1px solid var(--tsw-primary-color);

  @extend %txt-title-sm;

  &:hover {
    color: $ds-text-action;
  }
}

.diningOptionsContent {
  max-height: 100%;
  min-width: 266px;
  max-width: 450px;
  display: flex;
  flex-direction: column;

  flex: 1;

  .noOrderTimesAvailableNote {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
  }

  .itemAddedToCartNote {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 12px 0;
    padding-top: 8px;
    font-size: 14px
  }

  .toggle {
    margin: 16px 0;
  }

  .prompt {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 18px 0 4px 0;
  }

  .warning {
    display: flex;
    gap: 4px;
    padding: 12px;
    font-size: 16px;
  }

  .addressInput .autocompleteContainer .autocomplete input {
    padding: 4px 12px 4px 24px;
  }

  .options {
    font-size: 16px;

    &:disabled {
      color: $text-color-hint;
    }

    .dropDownContent {
      width: 100%;
    }

    .dropDown {
      padding: 4px 12px;

      .dropDownLabel {
        padding-left: 12px;
      }
    }
  }
}

#diningOptionSubmit, #diningOptionRemove {
  height: 40px;
  width: 100%;
  margin: 32px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  &.flush {
    border-radius: 0 0 6px 6px;
    justify-content: center;

    @include sm-down {
      border-radius: 0;
    }
  }

  &.removeItem {
    color: $error-75 !important;
  }
}
