.ctaItemModal {
  .ctaItemModalContent {
    background-color: $ds-surface-default;
    color: $ds-text-default;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;
    color: $ds-text-default;

    .closeButton {
      margin: 0;
      padding: 0;
      color: $ds-text-default;
      background-color: unset;
      border: none;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    height: auto;
    overflow-y: scroll;
    color: $ds-text-default;


    > a {
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      padding: 13px 36px;

      &:hover {
        background-color: $black-4;
        text-decoration: none;
      }
    }
  }
}
