.modalContentV2 {
  border-radius: 10px !important;
  width: 450px;
  height: 435px;
  &.loyalty {
    height: auto;
    border: 0.75px solid var(--ds-border-default, #CECECE);
  }
  &.secondaryCTA {
    height: 465px;
  }

  &.completeProfile {
    height: auto;
  }

  &.fullScreenMobile {
    @include xs {
      height: 100%;
    }
  }

  padding: 0;
  overflow-y: auto;

  .modalHeader {
    justify-content: flex-end;
    border-bottom: 0.75px solid rgb(0, 0, 0, .12);
    padding: 12px;

    &.withHeaderIconRight {
      justify-content: space-between;
    }

    >* {
      display: inline-flex;
      margin: 0;
      background-color: unset;
      border: none;
      &:is(button):hover {
        border-radius: 50%;
        background-color: $light-gray;
        cursor: pointer;
      }
    }

    .iconRight {
      &:not(.closeButton) {
        padding: 4px;
      }
      img {
        height: 24px;
        width: 24px;
      }
    }
  }

  *:not(.modalHeader) button {
    width: 100%;
    border-radius: 8px;
  }

  .body {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding: 32px 72px;

    @include xs {
      padding: 32px;
    }

    &.loyalty {
      padding: 24px 72px;
      text-align: center;
    }

    &.terms {
      padding: 12px 24px 32px;
      button {
        margin-top: auto;
      }
    }

    color: #646464;
    font-size: 14px;
    ul {
      width: fit-content;
      align-self: center;
      margin-bottom: 0;
      li {
        font-size: 14px;
      }
    }

    .loyaltyFeatures { 
      text-align: left;
    }

    .headerContent {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 4px;

      &.centered {
        align-items: center;
      }

      .title {
        font-size: 18px;
        font-weight: 600;
        color: #202020;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 400;
        color: #646464;
      }
    }

    .phoneForm {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 24px;

      .inputField,
      .intlPhoneInput {
        margin-bottom: 0;
      }

      .intlPhoneInput {
        ul {
          width: 100%;
        }
      }

      .cta {
        display: flex;
        justify-content: center;
        border: none;
        border-radius: 8px;
        width: 100%;
        padding: 12px 0;

        .text {
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: 16px;
          font-weight: 600;
        }

        &.submitPhoneButton {
          background: #252525;

          &:disabled {
            background: rgba(0, 0, 0, 0.25);
            cursor: default;
          }
          .text {
            color: #FFFFFF;
          }
        }

        &.secondary {
          background-color: #FFFFFF;
          color: #252525;
          text-decoration: underline;
        }
      }
    }

    .termsText {
      color: #646464;
      font-size: 14px;
      text-align: center;
      padding: 12px 24px 24px;

      a {
        text-decoration: underline;
        font-weight: normal;
        color: inherit;
      }
    }

    .legal {
      font-size: 10px;
      text-align: center;
      color: #646464;
      margin-top: 24px;
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}
