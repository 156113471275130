.profileFooter {
  height: 230px;
  margin-top: 40px;
  margin-bottom: 100px;

  .footer-container {
    height: 100%;

    .footer-content {
      display: table;
      width: 100%;
      height: 100%;

      border-top: 1px solid $light-gray;

      @include xs {
        padding-top: 20px;
      }

      .twitter {
        path {
          fill: grey;
        }
      }

      .content-cell {
        display: table-cell;
        vertical-align: middle;

        .logo-wrapper {
          margin-bottom: 8px;

          .logo-link { display: inline-block; }
        }

        .footer-nav {
          @extend %txt-body-sm;

          a {
            color: $text-color;
            @extend %txt-body-sm;

            &:hover {
              color: $link-blue;
              text-decoration: underline;
            }
          }

          .social-media {
            margin-top: 10px;

            li {
              display: inline-block;
              padding: 0px 20px 0px 0px;
            }
          }

          &.nav-down {
            li { margin-bottom: 10px; }
          }

          @include xs {
            margin-bottom: 20px;
          }

          .toast-badge {
            margin-top: 30px;
          }
        }

        ul.footer-nav {
          margin: 0px;
          padding: 0px;

          li {
            list-style-type: none;

            a {
              color: $text-color;
              @extend %txt-body-sm;
            }

            &.nav-title {
              padding-bottom: 4px;
              @extend %txt-title-md;
            }
          }

          @include xs {
            margin-bottom: 20px;
          }
        }

      }
    }
  }
}
