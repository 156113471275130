.upsell {
  --border-style: 1px solid $black-12;
  border-top: var(--border-style);
  border-bottom: var(--border-style);

  @include md-height-down {
    overflow-y: auto;
    min-height: 54px;
  }
  @include sm-height-down {
    max-height: 54px;
  }
}

.upsellHeader, .upsellGrid {
  margin: 16px 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: $gray-125;
}

.upsellGrid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  &.upsellLong {
    grid-auto-rows: 186px;
  }

  &.upsellShort {
    grid-auto-rows: 108px;
  }
}

.upsellQuickAddBtn {
  width: 24px;
  height: 24px;

  font-size: larger;
  color: $ds-text-on-bg-primary;
  background-color: $ds-background-primary;
  &:hover {
    background-color: $ds-background-primary-focus;
  }

  border: 1.5px solid #FFFFFF;
  border-radius: 24px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.06), 0 3px 1px -2px rgba(0, 0, 0, 0.09);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
