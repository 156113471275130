$spacing: 24px;

.creditCardSurchargeDisclaimerBase {
  &.headerRule {
    border-top: 1px solid black;
    padding-top: $spacing;
    margin-top: $spacing;
  }

  &.confirmPageRule {
    padding-top: $spacing;
    margin-top: $spacing;
    color: $gray-100;
  }
}
