$desktop-base-size: 16px;
$desktop-reduced-size: 16px;
$mobile-base-size: 14px;

:root,
:host {
  @extend %txt-body-sm;
}

div,
button,
input,
select,
textarea,
.pure-g [class *="pure-u"] {
  font-family: inherit;
}

@mixin txt-family {
  font-family: var(--tsw-font-family, Effra, sans-serif);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  letter-spacing: normal;
}

@mixin txt-title-family {
  font-family: var(--ds-header-1-font-family, Effra, sans-serif);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  letter-spacing: normal;
}

@mixin txt-rg {
  @include txt-family;
  font-weight: 400;
}

@mixin txt-md {
  @include txt-family;
  font-weight: 600;
}

@mixin txt-bd {
  @include txt-family;
  font-weight: 700;
}

/* Mixins */
@mixin txt-10 {
  font-size: 1rem;
}

@mixin txt-title-xs {
  @include txt-rg;
  @include txt-title-family;
  $txt-title-xs: 1.2rem;
  font-size: $txt-title-xs;

  /* Don't let the font get smaller than this, even on small screens */
  @include md-down {
    font-size: calc(($desktop-base-size / $desktop-reduced-size) * $txt-title-xs);
  }

  @include sm-down {
    font-size: calc(($desktop-base-size / $mobile-base-size) * $txt-title-xs);
  }
}

@mixin txt-title-sm {
  @include txt-md;
  @include txt-title-family;
  font-size: 1rem;
  line-height: 1.25;
}

@mixin txt-title-md {
  @include txt-md;
  @include txt-title-family;
  font-size: 1.5rem;
  line-height: 1.5;
}

@mixin txt-title-md-bold {
  @include txt-bd;
  @include txt-title-family;
  font-size: 1.5rem;
  line-height: 1.5;
}

@mixin txt-title-lg {
  @include txt-md;
  @include txt-title-family;
  font-size: 2rem;
  line-height: 1.5;
}

@mixin txt-title-xl {
  @include txt-md;
  @include txt-title-family;
  font-size: 2.5rem;
  line-height: 1.5;
}

@mixin txt-title-xxl {
  @include txt-bd;
  @include txt-title-family;
  font-size: 2.5rem;
  line-height: 1.5;
}

@mixin txt-title-xxxl {
  @include txt-md;
  @include txt-title-family;
  font-size: 3.5rem;
  line-height: 1.5;
}

@mixin txt-body-xs {
  @include txt-rg;
  $txt-body-xs: 0.5rem;
  line-height: 1.1;
  font-size: $txt-body-xs;

  /* Don't let the font get smaller than this, even on small screens */
  @include md-down {
    font-size: calc(($desktop-base-size / $desktop-reduced-size) * $txt-body-xs);
  }

  @include sm-down {
    font-size: calc(($desktop-base-size / $mobile-base-size) * $txt-body-xs);
  }
}

@mixin txt-body-sm {
  @include txt-rg;
  font-size: 0.75rem;
  line-height: 1.5;
}

@mixin txt-body-md {
  @include txt-rg;
  font-size: 1rem;
  line-height: 1.25;
}

@mixin txt-body-lg {
  @include txt-rg;
  font-size: 1.25rem;
  line-height: 1.5;
}

@mixin txt-body-xl {
  @include txt-rg;
  font-size: 1.5rem;
  line-height: 1.5;
}

@mixin txt-body-xxl {
  @include txt-rg;
  font-size: 1.75rem;
  line-height: 1.5;
}

@mixin txt-body-sm-bold {
  @include txt-bd;
  font-size: 0.75rem;
  line-height: 1.1;
}

@mixin txt-body-md-bold {
  @include txt-bd;
  font-size: 1rem;
  line-height: 1.25;
}

@mixin txt-body-lg-bold {
  @include txt-bd;
  font-size: 1.25rem;
  line-height: 1.5;
}

/* Placeholders */
%txt-10 {
  @include txt-10;
}

%txt-title-xs {
  @include txt-title-xs;
}

%txt-title-sm {
  @include txt-title-sm;
}

%txt-title-md {
  @include txt-title-md;
}

%txt-title-lg {
  @include txt-title-lg;
}

%txt-title-xl {
  @include txt-title-xl;
}

%txt-title-xxl {
  @include txt-title-xxl;
}

%txt-title-xxxl {
  @include txt-title-xxxl;
}

%txt-title-md-bold {
  @include txt-title-md-bold;
}

%txt-body-xs {
  @include txt-body-xs;
}

%txt-body-sm {
  @include txt-body-sm;
}

%txt-body-md {
  @include txt-body-md;
}

%txt-body-lg {
  @include txt-body-lg;
}

%txt-body-xl {
  @include txt-body-xl;
}

%txt-body-xxl {
  @include txt-body-xxl;
}

%txt-body-sm-bold {
  @include txt-body-sm-bold;
}

%txt-body-md-bold {
  @include txt-body-md-bold;
}

%txt-body-lg-bold {
  @include txt-body-lg-bold;
}

/* Classes */
.txt-10 {
  @extend %txt-10;
}

.txt-title-xs {
  @extend %txt-title-xs;
}

.txt-title-sm {
  @extend %txt-title-sm;
}

.txt-title-md {
  @extend %txt-title-md;
}

.txt-title-lg {
  @extend %txt-title-lg;
}

.txt-title-xl {
  @extend %txt-title-xl;
}

.txt-title-xxl {
  @extend %txt-title-xxl;
}

.txt-title-xxxl {
  @extend %txt-title-xxxl;
}

.txt-title-md-bold {
  @extend %txt-title-md-bold;
}

.txt-body-xs {
  @extend %txt-body-xs;
}

.txt-body-sm {
  @extend %txt-body-sm;
}

.txt-body-md {
  @extend %txt-body-md;
}

.txt-body-lg {
  @extend %txt-body-lg;
}

.txt-body-xl {
  @extend %txt-body-xl;
}

.txt-body-xxl {
  @extend %txt-body-xxl;
}

.txt-body-sm-bold {
  @extend %txt-body-sm-bold;
}

.txt-body-md-bold {
  @extend %txt-body-md-bold;
}

.txt-body-lg-bold {
  @extend %txt-body-lg-bold;
}

.branded-underline {
  text-decoration: underline;
  text-decoration-color: $brand-orange;
  text-decoration-thickness: .3rem;
  /* Not supported by older browers, oh well */
  text-underline-offset: .5rem;
  /* Not supported by older browers, oh well */
}

h1 {
  font-family: var(--ds-header-1-font-family), sans-serif;
}
h2 {
  font-family: var(--ds-header-2-font-family), sans-serif;
}
h3 {
  font-family: var(--ds-header-3-font-family), sans-serif;
}
h4 {
  font-family: var(--ds-header-4-font-family), sans-serif;
}
h5 {
  font-family: var(--ds-header-4-font-family), sans-serif;
}

.bolder {
  font-weight: bolder;
}

// Toast Local

@mixin txt-source-sans-pro {
  font-family: 'Source Sans Pro', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  letter-spacing: normal;
}

@mixin txt-source-sans-pro-rg {
  @include txt-source-sans-pro;
  font-weight: 400;
}

@mixin txt-source-sans-pro-md {
  @include txt-source-sans-pro;
  font-weight: 600;
}

@mixin txt-source-sans-pro-bd {
  @include txt-source-sans-pro;
  font-weight: 700;
}

%txt-source-sans-pro {
  @include txt-source-sans-pro;
}

%txt-source-sans-pro-rg {
  @include txt-source-sans-pro-rg;
}

%txt-source-sans-pro-md {
  @include txt-source-sans-pro-md;
}

%txt-source-sans-pro-bd {
  @include txt-source-sans-pro-bd;
}
