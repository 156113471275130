.bold {
    font-size: 1rem;
    font-weight: 600;
}

.linkChildrenWrapper {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    grid-gap: 18px;
}
