.shippingSection {
  .addressInput {
    position: relative;
    z-index: 2;

    .addressInputField {
      position: relative;
      border: 1px solid #CECECE;
      border-radius: 8px;
      display: flex;
      height: 56px;
      margin-bottom: 12px;
    }
  }
}

.shippingRatesSection {
  margin-top: 24px;
  display: flex;

  .loader {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .shippingRates {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .shippingRateOption {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .rate {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
      }

      .estimatedDays {
        font-style: italic;
      }
    }
  }
}
