.checkoutSection {
  hr {
    margin: 16px 0;
  }

  .fulfillmentDeliveryPreferences {
    margin-top: 20px;

    .saveAddrCheckbox, .contactlessCheckbox {
      padding-left: 2px;
    }
  }

  .contactlessCheckbox {
    &:has(+ .saveAddrCheckbox) {
      margin-bottom: 12px;
    }
  }

  .addressLabel {
    margin-top: 12px;
  }

  .diningOptionsLink {
    cursor: pointer;
    float: right;
    padding: 0 0 16px 0;
    border: 0;
    border-radius: 0;
    background-color: white;
    color: $link-blue;
    font-size: 16px;
    font-weight: 500;
  }

  .mapFrame {
    height: 145px;
    margin: 0 0 12px 0;
  }

  .curbsidePickupVehicle {
    margin-top: 8px;
  }

  .curbsideText {
    font-size: 16px;
    line-height: 16px;
    padding-bottom: 1px;

    .bold {
      font-weight: 600;
    }

    .gray {
      color: $dark-gray;
    }
  }
}
