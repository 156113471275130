.locationSelectionPageContent {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 73px);
  max-height: 100vh;

  @include md-down {
    max-height: calc(100vh - 65px);
  }

  .leftColumn {
    box-shadow: 2px 0px 10px var(--ds-surface-shadow, rgba(0, 0, 0, 0.1));
    width: 500px;
    z-index: 1;

    @include sm-down {
      max-width: unset;
      flex: 1;
    }

    .viewButton {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      background: white;
      border: 1.5px solid $ds-background-primary;
      @extend %border-radius-full;
      padding: 12px;
      position: fixed;
      bottom: 48px;
      left: 50%;
      transform: translate(-50%, -50%);
      @extend %box-shadow-std;
    }
  }

  .rightColumn {
    flex: 1;

    @include sm-down {
      display: none;
    }
  }
}

.basicLocationSelectionPageContent {
  max-width: 600px;
  margin: auto;
}
