.positionedCard {
  width: 100%;

  .cardContentWrapper {
    pointer-events: none;

    .cardContent {
      pointer-events: all;
    }
  }

  &.textWithBackground:not(.center) {
    .cardImageWrapper, .cardContentWrapper, .cardImageWrapper > a {
      display: block;
    }
  }

  .cardImageWrapper, .cardContentWrapper, .cardImageWrapper>a {
    display: flex;
    flex: 1;
  }

  .cardContentWrapper .cardContent .cardText {
    @include sm-down {
      gap: 8px;
    }
  }

  &.textWithBackground .cardContentWrapper .cardContent {
    background-color: $ds-surface-tertiary;
    color: $ds-text-default;

    &.secondaryTextColor {
      color: var(--ds-text-default, var(--tsw-secondary-text-color));
    }
  }

  @include sm-down {
    .cardContentWrapper .cardContent.wide {
      padding: 0 30px;
    }
  }

  &.large .cardContentWrapper .cardContent {
    padding: 30px;
  }

  &.isFirstSection, &.large {
    .card .cardWrapper {
      gap: 0;
    }
  }

  &.top {
    .card .cardContentWrapper {
      justify-content: center;

      .cardContent {
        text-align: center;
        justify-content: end;
        align-self: end;
      }
    }
  }

  &.bottom {
    .card .cardContentWrapper {
      justify-content: center;

      .cardContent {
        text-align: center;
        justify-content: start;
      }
    }
  }

    &.outlined:not(.center) {
      .card.textInset .cardContentWrapper {
        top: 25px;
        left: 25px;
        height: calc(100% - 50px);
        width: calc(100% - 50px);
      }
  }

  &.center, &.outlined.textInset {
    &:not(.textWithBackground) .card:not(.textInset) .cardContentWrapper .cardContent {
      color: $ds-text-default;
    }

    &.outlined {
      .card .cardImageWrapper {
        height: calc(100% - 52px);
        width: calc(100% - 52px);
      }

    }

    &.center {
      .card {
        .cardContentWrapper {
          justify-content: center;
          align-items: center;
          .cardContent {
            text-align: center;
          }
        }
      }
    }

    .card {
      .cardImageWrapper {
        position: absolute;
        height: 100%;
        width: 100%;

        .cardImage {
          height: 100%;
        }
      }

      .cardWrapper:not(.halfLeaf) .cardImageWrapper .cardImage {
        border-radius: 0;
      }

        .cardContentWrapper {
          position: relative;
          height: 500px;
          padding: 30px;

          .cardContent {
            height: fit-content;
            width: fit-content;
            padding: 30px;
          }
        }
    }
  }

  &.bottom {
    .card .cardWrapper {
      flex-direction: column-reverse;
    }
  }

  @include md {
    .card .cardWrapper {
      flex-direction: row;
    }

    &.center .card .cardContentWrapper .cardContent {
      padding: 50px;
      width: 60%;
    }

    &.top .card .cardWrapper {
      flex-direction: column;
    }

    &.bottomLeft .card .cardWrapper {
      flex-direction: row;
    }

    &.topRight, &.right, &.bottomRight {
      .card .cardWrapper {
        flex-direction: row-reverse;
      }
    }

    .cardContentWrapper .cardContent {
      justify-content: center;
    }

    &:not(.large) {
      &.topLeft, &.topRight {
        .cardContentWrapper .cardContent {
          justify-content: end;
          align-self: flex-end;
        }
      }

      &.bottomLeft, &.bottomRight {
        .cardContentWrapper .cardContent {
          justify-content: start;
        }
      }

      &.left, &.right {
        .cardContentWrapper .cardContent {
          align-self: center;
        }
      }
    }

    .card .cardImageWrapper .cardImage {
      height: 500px;
    }

    &.isFirstSection {
      .cardImageWrapper .cardImage {
        height: 500px;
      }
    }

    &.isFirstSection {
      .cardContentWrapper:not(.outlined) {
        .cardContent, .cardContent {
          padding: 75px 50px 50px;
        }
      }
      .cardContentWrapper.outlined {
        .cardContent, .cardContent {
          padding: 50px 50px 50px;
        }
      }
    }

    &.large {
      &.topLeft, &.topRight {
        .cardImageWrapper {
          padding-bottom: 100px;
        }
      }

      &.left, &.right {
        .cardImageWrapper:not(.isFirstSection) {
          padding: 100px 0;

          .cardImage {
            height: 500px;
          }
        }
      }

      &.bottomLeft, &.bottomRight {
        .cardImageWrapper {
          padding-top: 100px;
        }
      }

      &.textWithBackground {
        &.topLeft, &.left, &.bottomLeft {
          .cardImageWrapper {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 75%, $ds-surface-tertiary 75%);
          }
        }

        &.topRight, &.right, &.bottomRight {
          .cardImageWrapper {
            background: linear-gradient(90deg, $ds-surface-tertiary 25%, rgba(255, 255, 255, 0) 25%);
          }
        }
      }
    }
  }
}
