.loadingSpinnerOverlay {
  position: absolute;
  z-index: 12; // appear over everything
  inset: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ds-surface-overlay, rgba(0, 0, 0, 0.5));

  &.top {
    align-items: flex-start
  }

  &.withBorderRadius {
    border-radius: 24px;
  }

  &.fullScreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
