.warning {
    display: flex;
    align-items: center;
    color: #c42b44;
    border-radius: 5px;
    text-align: left;
  }
  
  .warning-icon {
    margin-right: 10px;
    /* Set the size of your icon */
    width: 24px;
    height: 24px;
  }
  
  .warning-message {
    flex: 1;
  }
  