.menuContent {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  box-shadow: 0 0 6px 1px var(--ds-surface-shadow, rgba(0, 0, 0, 0.15));
  border-radius: 16px 16px 0 0;
  background: $ds-surface-default;

  position: fixed;
  bottom: 0;
  z-index: 10;
  height: fit-content;
  max-height: 544px;

  overflow: scroll;
  @include hide-scrollbar;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 0;

    .closeButton {
      padding: 0;
      margin: 0;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
  }

  .item {
    padding: 12px 0;
  }

  .groupName {
    color: $ds-background-primary;
    font-weight: 700;
    margin-top: 12px;
  }

  .separator {
    border-bottom: 3px solid $ds-background-primary;
    border-radius: 3px;
    width: 100%;
  }
}
