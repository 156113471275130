.offerAndLoyaltyCard {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: stretch;
  text-align: left;
  padding: 0;
  width: 100%;

  // Default to "subtle" for border stroke, border radius, and drop shadow
  border: 0.75px solid var(--ds-border-default, #e0e0e0);
  &.borderStrokeNone {
    border: none;
  }
  &.borderStrokeExaggerated {
    border: 2px solid var(--ds-border-default, #e0e0e0);
  }

  border-radius: 8px;
  &.roundedCornerNone {
    border-radius: 1px;
  }
  &.roundedCornerExaggerated {
    border-radius: 20px;
  }

  box-shadow: 0 2px 4px 0 var(--ds-surface-shadow, #e0e0e0), 0 0 6px 0 #00000005;
  &.dropShadowNone {
    box-shadow: none;
  }
  &.dropShadowExaggerated {
    box-shadow: 0 4px 8px 0 var(--ds-surface-shadow, #0000000F), 0 0 4px 0 #0000000A;
  }

  &:not(:disabled):hover {
    box-shadow: 0 0 24px var(--ds-surface-shadow, rgba(0, 0, 0, 0.2));
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }

  .offerInfo {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .offerText {
      font-size: 18px;
      font-weight: 500;
      @extend %line-clamp-2;
    }

    .applyText {
      font-size: 14px;
      font-weight: 400;
      display: inline;
      svg {
        margin-left: 4px;
      }
    }
  }

  .offerIllustration {
    padding: 12px;
    .scaledIllustrationContainer {
      position: relative;
      width: 120px;
      height: 124px;
      svg {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}
