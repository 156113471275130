.packagingSelections {
  margin-bottom: 16px;

  ul {
    margin: 0;
    padding: 0;
  }

  .packagingItem {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-left: 24px;
  }
}