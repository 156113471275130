.popover {
  .popoverContent {
    @extend %default-colors;
    opacity: 0;
    pointer-events: none;
    z-index: 8;
    position: absolute;
    border-radius: 24px;

    &.popoverVisible {
      opacity: 1;
      pointer-events: all;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.25));
    }

    &.fullScreenPopover {
      width: 100%;
      height: 100vh;
    }

    .arrow {
      width: 12px;
      height: 12px;
      top: -6px;

      > div {
        width: 100%;
        height: 100%;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(45deg);
      }
    }
  }
}

.popoverOverlay {
  position: fixed;
  inset: 0 0 0 0;
}
