.defaultTemplate {
  &:has(> .globalNav.stickyNav),
  &:has(> .globalNav.fixedNav) {
    // NOTE: The :has() selector isn't yet supported on firefox, but the purpose behind its use here is a nice-to-have.
    & .fullScreenSection {
      // When the page has a sticky or fixed nav, we should shorten the fullscreen section.
      min-height: 95vh; // 100vh - (approximate height of the nav/5vh)
      width: 100%;
    }
  }
}
.fullScreenSection {
  display: flex;
  flex-flow: row nowrap;
  height: auto;
  min-height: 100vh;
  width: 100%;

  $padding-x: 2rem;
  $padding-y: 2rem;

  &.wide .text-box-area {
    padding: $padding-y 0;
  }

  & .text-box-area {
    box-sizing: border-box;
    width: 100%;
    flex: none;
    margin-left: -100%;
    z-index: 1;
    padding: $padding-y $padding-x;
    &.top {
      align-content: flex-start;
    }
    &.middle {
      align-content: center;
    }
    &.bottom {
      align-content: flex-end;
    }

    & .text-box-row {
      display: flex;

      // textPosition (horizontal position controls)
      &.left {
        justify-content: flex-start;
      }

      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: flex-end;
      }

      & .text-box {
        padding: 1.5rem;
        color: var(--ds-text-default, var(--tsw-primary-contrasting-color));
        flex-direction: column;
        gap: 0.5rem;
        max-width: 60%;
        overflow-y: auto;
        @include md-down {
          max-width: 90%;
        }

        // theme-specific adjustments
        &.wide {
          width: 100%;
          max-width: 100%;
        }
        &.outlined {
          border: solid 2px $ds-border-default;
          background-color: none;
        }
        &.halfLeaf {
          padding: 2rem;
        }
      }
    }
  }
}

.coverImage {
  max-height: 100vh;
  height: 100%;
  object-fit: cover;
  @media screen and (min-width: 80em) {
    min-width: 100%;
    width: 100%;

  }
}

.image-fill {
  width: 100%;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
