
.checkoutSection.takeoutCta {
    .ctaHeader {
        display: flex;
        cursor: text;
        flex-wrap: wrap;
        line-height: 19.2px;
        font-weight: 500;
        font-size: 16px;
    }

    .ctaBody {
        display: flex;
        padding-top: 20px;
        align-items: center;
        justify-content: space-between;

        .logoContainer {
            width: 48px;
            
            .logo {
                fill: #FFFFFF;
                stroke: #FF4C00;
                background-color: #FF4C00;
                border-radius: 10px;
            }
        }

        .middle {
            .appName {
                line-height: 21px;
                font-size: 16px;
            }

            .rating {
                .ratingNumber {
                    color: #2E7C87;
                    font-size: 12px;
                    line-height: 16px;
                }
                .stars {
                    display: flex;
                    align-items: center;
                    padding-left: 2px;
                    img {
                       width: 11px;
                   }
                }
                display: flex;
                align-items: center;
            }
        }

        .ctaButton {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 11px 16px 13px;
            gap: 10px;
        
            width: 59px;
            height: 22px;
        
            /* Gray/125 */
        
            background: #252525;
            border-radius: 32px;
    
            color: #FFFFFF;
            font-weight: 600;
            text-align: center;
            font-size: 16px;
            text-decoration: none;
                
        &:not(:disabled):hover {
                filter: saturate(90%) brightness(90%);
                text-decoration: none;
                cursor: pointer;
            }
        
        }
    }
}
