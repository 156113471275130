.toastLocalSplash {
  color: $gray-125;

  .posPromo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 75px 0px;
    padding: 20px 0px;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;

    .message {
      @extend %txt-body-xl;
      line-height: 1.5rem;
    }

    @include md-down {
      flex-direction: column;
      align-items: center;
      text-align: center;

      .message {
        padding-bottom: 10px;
        @include txt-source-sans-pro-rg;
      }
    }
  }
}
