%border-radius-std { border-radius: $corner-radius-std; }
%border-radius-lg { border-radius: $corner-radius-lg; }
%border-radius-full { border-radius: $corner-radius-full; }
%box-shadow-std { box-shadow: 0px 0px 10px 0px var(--ds-surface-shadow, rgba(0,0,0,0.25)); }
%box-shadow-light { box-shadow: 0px 0px 10px 0px var(--ds-surface-shadow, rgba(0,0,0,0.1)); }
%box-shadow-soft { box-shadow: 0px 0px 36px 0px var(--ds-surface-shadow, rgba(0,0,0,0.25)); }
%box-shadow-softer { box-shadow: 0px 0px 75px 0px var(--ds-surface-shadow, rgba(0,0,0,0.05)); }

@mixin box-shadow-std { box-shadow: 0px 0px 10px 0px var(--ds-surface-shadow, rgba(0,0,0,0.25)); }
@mixin box-shadow-light { box-shadow: 0px 0px 10px 0px var(--ds-surface-shadow, rgba(0,0,0,0.1)); }

:root, :host {
  color: $ds-text-default;
  font-size: $desktop-base-size; /* For easy rem sizing */
  @include md-down { font-size: $desktop-reduced-size; }
  @include sm-down { font-size: $mobile-base-size; }
  height: 100%;
}

:root, a {
  color: $ds-text-default;
}
.primaryColor {
  color: $ds-background-primary;
}
.primaryColorBackground {
  background-color: $ds-background-primary;
}
.primaryColorHover:hover {
  background-color: var(--tsw-primary-hover-color);
}

/* Initial line-height to mimic admin settings */
html {
  font-size: 16px;
  line-height: 1.5;
}

body {
  overflow-x: hidden;
  margin: 0px;
  height: 100%;
  background-color: $ds-surface-default;
}

#content, .tsw-app-wrapper {
  min-height: 100%;
  height: auto;
  background-color: $ds-surface-default;
}

h1, h2, h3, h4, h5, h6 { margin: 0px; }

h1 { @extend %txt-title-xxl; }
h2 { @extend %txt-title-lg; }
h3 { @extend %txt-title-sm; }
h4 { @extend %txt-title-xs; }
h5 { @extend %txt-title-xs; }

hr {
  border-style: none;
  border-top: 1px solid $light-gray;
}

p { margin: 0px; }

p, li { @extend %txt-body-md; }

a {
  text-decoration: none;

  &:hover { text-decoration: underline; }
}

.link-text {
  text-decoration: none;
  color: var(--ds-text-action, $link-blue);
  cursor: pointer;

  &:hover { text-decoration: underline; }
}

.underline-text {
  text-decoration: underline;
}

input {
  height: 42px;
  line-height: 42px;
  padding: 0px $input-padding-std;
  border: 1px solid rgba(0,0,0,0);
  @extend %txt-body-md;

  &::placeholder { color: $text-color-gray; }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select {
  height: 42px;
  line-height: 42px;
  padding: 0px $input-padding-std;
  border: 1px solid rgba(0,0,0,0);
  @extend %txt-body-md;
}

textarea {
  height: 64px;
  line-height: normal;
  padding: $input-padding-std;
  border: none;
  resize: none;
  @extend %txt-body-md;

  &::placeholder { color: $text-color-gray; }
}

.v-align-children {
  > * { vertical-align: middle; }
}

.no-wrap {
  white-space: nowrap;
}

%button {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding-left: $button-padding-std;
  padding-right: $button-padding-std;
  color: #FFFFFF;
  cursor: pointer;
  border: $button-border-thickness solid rgba(0,0,0,0);
  @extend %border-radius-lg;
  user-select: none;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.disabled-clickable {
    opacity: 0.5;
  }

  .icon-img {
    vertical-align: middle;
  }

  &.button-sm {
    height: 24px;
    line-height: 24px;
    padding-left: $button-padding-sm;
    padding-right: $button-padding-sm;
    @extend %txt-title-md;
  }

  @include sm-down {
    padding-left: $button-padding-sm;
    padding-right: $button-padding-sm;
  }
}

.button-green {
  @extend %button;
  background: $green;
  border-color: $green;
  color: #FFFFFF;
}
a.button-green { text-decoration: none; }

.button-red {
  @extend %button;
  background: $red;
  border-color: $red;
  color: #FFFFFF;
}
a.button-red { text-decoration: none; }

.button-blue {
  @extend %button;
  background: $hard-blue;
  border-color: $hard-blue;
  color: #FFFFFF;
}
a.button-blue { text-decoration: none; }

.button-white {
  @extend %button;
  background: #FFFFFF;
  border-color: #FFFFFF;
  color: $extra-dark-gray;
}
a.button-white { text-decoration: none; }

.button-gray {
  @extend %button;
  background: $extra-light-gray;
  border-color: $extra-light-gray;
  color: $gray-125;
}
a.button-gray { text-decoration: none; }

.button-hollow {
  @extend %button;
  background: none;
  border-color: #FFFFFF;
}
a.button-hollow { text-decoration: none; }

.button-hollow-dark {
  @extend %button;
  background: none;
  border-color: $extra-dark-gray;
  color: $extra-dark-gray;
}
a.button-hollow-dark { text-decoration: none; }

.section-rule {
  height: 6px;
  width: 100px;
  margin-bottom: 20px;
  background: $yellow;

  @extend %border-radius-std;

  &.blue {
    background: $hard-blue;
  }
}

// removing outline from these inputs since they are handling focus without using a focus ring
input {
  &[type="text"], &[type="email"], &[type="tel"], &[type="password"], &[type="number"] {
    &:focus {
      outline: none
    }
  }
}

button, input[type="button"], input[type="submit"] {
  cursor: pointer;
}


p.error { color: $red; }
input.error { border-color: $red; }

.generic-container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 20px;
}

.page-section {
  margin: 50px auto;
}

.pure-g.gutters {
  margin: -0.8rem;
}
.pure-g.gutters > [class *=pure-u] {
  box-sizing: border-box;
  padding: 0.8rem;
}

.pure-g.center {
  justify-content: center;
}

.bottom-banner {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: $red;
  color: #FFFFFF;
  @extend %txt-title-sm;
  @extend %no-select;
}

%arrow {
  width: 0;
  height: 0;
}

%no-select {
  user-select: none;
  cursor: default;
}

img {
  user-select: none;
  @extend %no-drag;
}

.default-cursor {
  cursor: default;
}

%no-drag {
  -webkit-user-drag: none;
  user-drag: none;
}

@mixin arrow-up($size, $color) {
  @extend %arrow;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: $size solid $color;
}

@mixin arrow-down($size, $color) {
  @extend %arrow;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-top: $size solid $color;
}

@mixin arrow-right($size, $color) {
  @extend %arrow;
  border-top: $size solid transparent;
  border-bottom: $size solid transparent;
  border-left: $size solid $color;
}

@mixin arrow-left($size, $color) {
  @extend %arrow;
  border-top: $size solid transparent;
  border-bottom: $size solid transparent;
  border-right:$size solid $color;
}

div {
  box-sizing: border-box;
}

%line-clamp-reqs {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

%line-clamp-1 {
  @extend %line-clamp-reqs;
  -webkit-line-clamp: 1;
}

%line-clamp-2 {
  @extend %line-clamp-reqs;
  -webkit-line-clamp: 2;
}

%line-clamp-3 {
  @extend %line-clamp-reqs;
  -webkit-line-clamp: 3;
}

%line-clamp-unset {
  display: unset;
  -webkit-box-orient: unset;
  overflow: unset;
  -webkit-line-clamp: unset;
}

.line-clamp-1 { @extend %line-clamp-1; }
.line-clamp-2 { @extend %line-clamp-2; }
.line-clamp-3 { @extend %line-clamp-3; }
.line-clamp-unset { @extend %line-clamp-unset; }

.skip-link {
  margin-right: 1rem;
  position: absolute;
  transform: translateX(-200%);
  transition: transform 0.3s;

  &:focus {
      position: static;
      transform: translateX(0);
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

@mixin hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background: transparent;
    width: 0;
    height: 0;
  }
}
