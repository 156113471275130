
.sectionBlock {
    padding: 16px 24px 16px 24px;
    box-shadow: 0px 0px 6px var(--ds-surface-shadow, rgba(0,0,0,0.10));
    border-radius: 8px;
    margin: 16px 0px;
}

.mapWrapper {
    margin-top: 16px;
}

.fulfillmentInformation {
    margin-top: 16px;
}

.trackerSkeleton {
    width: 420px;
    border-radius: 8px;
    margin: 16px 0px;
}
