.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  z-index: 9;

  .modalOverlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.50);
  }

  .modalWrapper {
    width: 100%;
    height: 100%;
    max-height: 100%;
    z-index: 9;
    @media (min-width: 400px) {
      padding: 48px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header {
      @include txt-md;
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
  }

  .closeButton {
    padding: 12px;
    border-radius: 50%;
    align-self: center;

    &:hover {
      background-color: $light-gray;
    }
  }

  .modalContent {
    @extend %default-colors;
    position: relative;
    z-index: 10;
    border-radius: 24px;
    max-height: 100%;

    display: flex;
    flex-direction: column;

    &.scrollable {
      overflow-y: scroll;
    }

    @include sm-down {
      width: 100%;
    }

    &.fullScreenMobile {
      @include xs {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0 !important;
      }
    }
  }
}
