.errorSection {
    .error {
      display: flex;
      flex-direction: row;
      background-color: #FFE6E9;
      padding: 12px;
      margin-bottom: 18px;
      line-height: 24px;

      img {
        align-self: flex-start;
        padding-right: 10px;
      }
    }

    .potsImage {
      padding-bottom: 20px;
      width: 100%;
    }

    .button {
        width: 100%;
        display: flex;
        justify-content: center;
      }
  }
