.allHoursModal {
  $outerPadding: 24px;
  font-size: 16px;
  color: black;
  background: white;
  border-radius: 24px;

  .closeButton {
    width: auto !important;
  }

  button {
    border: none;
    background: none;
    padding: none;
    margin: none;
  }

  .rxName {
    @include txt-md;
    font-size: 1.5rem;
    line-height: 1.6rem;
  }

  .openStatus {
    margin: $outerPadding $outerPadding 0 $outerPadding;
  }

  .schedule {
    padding: $outerPadding;

    .hoursAndTooltip {
      display: flex;
      align-items: center;
      width: fit-content;

      .tooltipContent {
        padding: 8px 16px;
        border-radius: 4px;
        background: $gray-125;
        color: white;
      }

      .tooltipArrow>* {
        background: $gray-125;
      }
    }

    .hoursWrapper {
      float: left;
      vertical-align: middle;
    }

    .overrideTooltip {
      vertical-align: middle;
      margin-left: 8px;
      cursor: pointer;

      @include sm-down {
        width: 1.5rem;
        margin-left: 4px;
      }
    }

    .infoIcon {
      margin-left: 10px;
    }


    >div {
      display: grid;
      padding: 8px 0;
      grid-template-columns: 1fr 1fr;
      align-items: baseline;
    }

    .heading>span {
      font-weight: 500;
    }

    .bold {
      font-weight: 700;
    }
  }

  .scheduleDetail {
    color: white;
    font-size: 1rem;
  }
}
