.loyaltyRedemption {
  line-height: 24px;

  .redemptions {
    position: relative;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    button.redemption {
      all:unset;
      display: flex;
      align-items: center;
      padding: 6px 8px;
      border-radius: 24px;
      background-color: $gray-25;
      line-height: 20px;
      white-space: nowrap;

      &.added {
        color: white;
        background-color: $primary-blue-50;
        font-weight: 500;
      }

      &:not(.loading) {
        cursor: pointer;
      }

      img {
        vertical-align: bottom;
        padding-right: 4px;
      }
    }
  }

  button.consentButton {
    background: transparent;
    border: 2px solid var(--tsw-primary-color);
    color: var(--tsw-primary-color);
    margin-top: 24px;
  }

  .errorHeader {
    font-size: 16px;
    font-weight: 600;
    color: $error-red;
  }

  .loyaltyConsentContent {
    font-size: 14px;
  }

  .loyaltyLoading {
    .react-loading-skeleton {
      margin-bottom: 8px;
    }
  }

  .popover {
    display: inline-block;
    height: 24px;
    width: 24px;
    position: absolute;

    .loyaltyConsentTooltipContent {
      white-space: nowrap;
      padding: 8px 24px;
    }

    .loyaltyInfoButton {
      img {
        height: 24px;
        width: 24px;
      }

      padding: 0;
      height: 24px;
      width: 24px;
      background: none;
      border: none;
    }
  }

  .header {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
}

button.lockedRedemptionButton {
  all: unset;
  display: block;
  width: 100%;
  border: 1px solid #EAECF1;
  background-color: transparent;
  margin-top: 16px;

  .lockedLoyaltyRedemption {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    padding: 12px 8px 12px 18px;
    border-radius: 4px;
    line-height: 18px;
    color: $gray-50;

    .textContainer {
      display: flex;
      flex-direction: column;

      .lockedHeader {
        font-size: 18px;
        font-weight: 400px;
      }

      .message {
        font-size: 13px;
      }
    }
  }
}
