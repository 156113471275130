.leftMenuNav {
  display: flex;
  flex-direction: column;
  background: $ds-surface-default;
  padding: 12px 40px 12px 40px;
  margin-left: 40px;
  height: 100vh;
  position: relative;

  .sections {
    width: 250px;
    overflow-y: scroll;
    @include hide-scrollbar;
  }

  .section {
    display: flex;
    flex-direction: column;
  }

  .item {
    padding: 16px 0;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &.selected {
      font-weight: 700;
    }
  }

  .groupName {
    color: $ds-background-primary;
    font-size: 20px;
    margin-top: 12px;
  }

  .separator {
    border-bottom: 3px solid $ds-background-primary;
    border-radius: 3px;
    width: 100%;
  }

  .arrow {
    padding: 32px;
    position: absolute;
    left: 0;
    width: 100%;
    background: none;
    border: none;
    z-index: 2;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-out;

    &.arrowHidden {
      display: none;
    }
  }

  .upArrow {
    top: 0;
    background: linear-gradient(180deg, var(--tsw-background-color) 75%, var(--tsw-background-color-med));
  }

  .downArrow {
    bottom: 0;
    position: sticky;
    background: linear-gradient(0deg, var(--tsw-background-color) 75%, var(--tsw-background-color-med));
  }
}
