.checkoutForm {
  position: relative;
  margin: 24px auto 50px;
  max-width: 540px;

  .checkoutFormContents {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .note {
    font-size: 10px;
    line-height: 12px;
    color: $dark-gray;
    padding-bottom: 2px;
    text-align: left;
    a {
      color: $dark-gray;
      text-decoration: underline;
    }
  }
  .checkoutNote {
    margin-top: 20px;
  }

  .submitButton {
    &:disabled {
      cursor: default;
      color: black;
      background-color: lightgray;
    }
    border-radius: 32px;
    margin-bottom: 8px;
    width: 100%;
  }

  .submitError {
    margin-top: 10px;
    text-align: center;
    color: $error-red;
    a {
      text-decoration: underline;
    }
  }

  .toastLogo {
    margin: 40px;
  }

  .option .showInput {
    padding-left: 0;
  }

  .loadingSpinnerOverlay {
    // above form, below nav
    z-index: 5;
  }
}

.whiteBackground {
  background-color: white;
}

.internationalPaymentContainer {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.internationalPaymentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;
  padding: 18px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;

  .controlIcon {
    transition: all 0.15s ease-in;

    &.rotated {
      transform:rotate(90deg);
    }
    &.rotatedUp {
      transform:rotate(270deg);
    }
    &.rotatedDown {
      transform:rotate(90deg);
    }
  }
}
