.errorWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .errorImage {
    justify-self: end;
    img {
      mix-blend-mode: exclusion;
      filter: invert(1) contrast(5);
    }
  }

  .errorContainer {
    display: grid;
    grid-template-rows: minmax(200px, 5fr) 1fr 2fr;
    grid-gap: 30px;
    margin: 300px 0 200px;
    justify-self: center;

    .errorCode {
      font-size: 200px;
      font-weight: 700;
      align-self: center;
    }

    .errorMessage {
      font-size: 18px;
      font-weight: 400;
    }

    .backBtn {
    }
  }

  @include md-down {
    grid-template-columns: 1fr;
    .errorImage {
      display: none;
    }

    .errorContainer {
      margin-top: 200px;
    }
  }

  @include sm-down {
    .errorContainer {
      grid-gap: 10px;
      margin: 100px 0 20px 20px;

      .errorCode {
        font-size: 165px;
        font-weight: 700;
      }

      .errorMessage {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
}
