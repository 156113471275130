.offers {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 24px;
  line-height: normal;

  .offersHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header {
      font-size: 20px;
    }

    .arrows {
      display: flex;
      justify-content: flex-end;
      gap: 12px;

      .disabledArrow {
        opacity: 0.4;
      }

      .arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 32px;
        width: 32px;
        border: 1px solid var(--ds-border-default, #0000001F);
        border-radius: 50%;
        background-color: var(--ds-background-primary, #FFFFFF);

        &.right {
          transform: rotate(180deg);
        }

        &.disabled {
          cursor: auto;
        }

        svg {
          height: 12px;
          width: 8px;
        }
      }
    }
  }

  .offerAndLoyaltyCards {
    // Some trickery to make it look like the drop shadow extends past the container
    width: calc(100% + 48px);
    padding: 24px;
    margin: 0 -24px;
  }
}

.offerModalWrapper {
  line-height: normal;

  .offerModal {
    width: 480px;
    color: $gray-100;

    .closeButton {
      align-self: flex-end;
      margin: 6px;
    }

    .content {
      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 48px 64px 48px;
        gap: 12px;
        border-top: 0.5px solid $gray-50;
        .title {
          font-size: 22px;
          font-weight: 600;
          color: $gray-125;
        }
        .description {
          text-wrap: balance;
          text-align: center;
        }
      }

      .body {
        padding: 24px 24px 0 24px;

        .offerDetail {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 0.5px solid $gray-50;
          padding: 28px 0;
          .detail {
            display: flex;
            flex-direction: column;
            gap: 14px;

            .detailTitle {
              font-size: 14px;
            }

            .detailDescription {
              font-size: 16px;
              color: $gray-125;
            }
          }
          .copyButton {
            position: relative;
            &:hover {
              cursor: pointer;
            }
            button {
              all: unset;
            }

            .copyMessage {
              position: absolute;
              left: -12px;
              top: 32px;
              opacity: 0;
              @extend %txt-body-sm;
              pointer-events: none;
              transition: opacity 0.5s ease-out;
              color: $gray-125;
              background-color: $primary-blue-0;
              padding: 4px;
              border-radius: 4px;
              width: fit-content;
              margin: auto;

              &.show { opacity: 1; }
            }
          }
        }
      }
    }
  }
}

.modalContentV2 {
  .loyaltyFormContainer {
    width: 100%;
    border-top: 0.5px solid var(--ds-border-default, #CECECE);
    display: flex;
    flex-direction: column;
    align-items: center;
    .signupForm {
      width: 75%;
      padding-bottom: 24px;
      color: #646464;
      font-size: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .checkboxContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        input {
          accent-color: var(--ds-background-secondary);
        }

        a {
          text-decoration: underline;
          cursor: pointer;
          font-weight: normal;
          color: inherit;
        }
      }
    }
  }
  .signupButton, .termsButton {
    font-size: 16px;
  }
  .accrualBonusTag {
    border-bottom: 0.75px solid rgb(0, 0, 0, .12);
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    padding: 8px;
  }
}

.loadingSpinnerOverlay {
  border-radius: 10px;
}

.loyaltyAuthModal {
  height: 470px;

  @include xs {
    width: 100%;
    height: 100%;
  }
}
