$card-gap: 24px;

.cards.wrapCards .itemsWrapper {
  flex-wrap: wrap;

  & > div, & > li {
    @include md {
      flex-basis: calc(50% - $card-gap);
    }
  }
}

.cards.emptySection {
  min-height: 100px;
}
