.toggle {
  background-color: $ds-surface-default;
  position: relative;
  border-radius: 32px;
  box-shadow: 0 0 0 1.5px $ds-background-primary inset;

  display: flex;
  overflow: hidden;

  &.plain {
    color: black;
    background-color: white;
  }

  .option {
    color: $white-contrasting-color-75;
    z-index: 2;
    width: 50%;
    padding: 8px 12px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 400;
    transition: color 0.4s;
    cursor: pointer;
    border-radius: 32px;
    min-height: 40px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    &.selected {
      color: $ds-text-on-bg-primary;
      cursor: default;

      &:focus-visible {
        outline: 1px solid $ds-text-on-bg-primary;
        outline-offset: -4px;
      }
    }

    &:focus-visible:not(.selected) {
      outline: 1px solid $ds-border-focus;
      outline-offset: -4px;
    }
    &.plain:not(.selected) {
      color: black;
    }

    &:focus {
      outline: none;
    }

    &.disabled {
      opacity: 0.4;
      &:hover {
        cursor: default;
      }
    }

    .optionText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      white-space: nowrap;
      :first-child {
        font-weight: 600;
      }
    }
  }

  .switch {
    position: absolute;
    width: 50%;
    border-radius: 32px;
    border: 1px solid $ds-background-primary;
    background-color: $ds-background-primary;
    top: 0;
    bottom: 0;
    right: 100%;
    left: 0;
    transition: left 0.5s, right 0.5s;
  }

  &.hasDisabled {
    opacity: 0.4;
  }

  &.right {
    .switch {
      right: 0;
      left: 50%;
    }
  }

  &.left {
    .switch {
      left: 0;
      right: 50%;
    }
  }
}
