.itemTagsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  &.wrap {
    flex-wrap: wrap;
    > div {
      white-space: normal;
    }
  }

  .dynamicTagContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    overflow: hidden;

    .itemTag:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.itemTag {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
  padding: 2px 6px;
  min-width: fit-content;
  max-width: fit-content;
  text-decoration-line: none;
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  &:not(.subtle) {
    font-weight: bold;
    font-family: "Source-Sans-Pro", sans-serif;
  }
}
