.modal .modalWrapper.reservationModalWrapper {
  .modalContent {
    @include sm-down {
      min-width: 100%;
    }
    // max-height:
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      background: transparent;
      width: 0;
      height: 0;
    }
  }
  .cardSection {
    margin: 0px;

    @include sm-down {
      min-width: 100%;
    }
  }
}

.reservation .link {
  color: $text-color;
}
