.pill {
  font-size: 14px;
  line-height: 17px;
  background: #FCE8E5;
  border: 1px solid $brand-orange;
  border-radius: 22px;
  padding: 4px 10px 4px 10px;
  display: inline-block;

  &.primary {
    background: #FCE8E5;
    border: 1px solid $brand-orange;
  }

  &.secondary {
    background: #FFFFFF;
    border: 1px solid $medium-gray;
  }

  &.truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
