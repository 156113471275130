.deliveryAddressSelection {
  width: 100%;

  .dropDownLabel {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
button.button {
  .buttonChildrenWrapper {
    grid-gap: 8px;
  }
}

.address2Input {
  .addCircleIcon {
    height: 100%;
    svg {
      height: 100%;
    }
  }
}
