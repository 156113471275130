.occasionSelector { 
    padding-top: 12px;

    .dropDownWrapper{
        .dropDown {
            border-radius: 0px;
            border-top: 0px;
            border-right: 0px;
            border-bottom: 1px solid $black-56;
            border-left: 0px;
            padding-left: 0px;

            .dropDownContent {
                width: 100%;
                max-height: 300px;
            }

            .dropDownLabel .selected .placeholder {
                font-size: 18px;
            }
        }
    }
    
    .option,
    .selected {
        display: flex;
        align-items: center;
        i, img {
            margin-top: 1px;
            margin-right: 0.4rem;
        }
    }

    .placeholder {
        color: $text-color-hint
    }
}