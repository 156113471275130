@mixin button-hover {
  &:not(:disabled):hover {
    filter: saturate(90%) brightness(90%);
  }

  text-decoration: none;

  & a {
    text-decoration: none;
  }
}

@mixin links-inherit-color {
  & a {
    color: var(--ds-text-action, inherit);
  }
}

// Button color mixins.
@mixin primary-button-color {
  color: $ds-text-on-bg-primary;
  background-color: $ds-background-primary;
}

@mixin secondary-button-color {
  background-color: $ds-surface-default;
  color: $ds-text-default;
  border-color: $ds-border-default;
}

@mixin inverted-button-color {
  color: $ds-background-primary;
  background-color: $ds-text-on-bg-primary;
}

.button-wrapper {
  display: block;

  a,
  div {
    display: inline-block;
  }

  &.align-button--left {
    text-align: start;
  }

  &.align-button--right {
    text-align: end;
  }

  &.align-button--center {
    text-align: center;
  }
}

.blockWrapper {
  .button-wrapper {
    flex: 1;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 100%;
    }

    a {
      width: 100%;
      height: 100%;
    }
  }
}

a:hover {
  text-decoration-line: none
}

button.button,
div.button,
.buttonLink {
  cursor: pointer;
  @extend %txt-title-sm;
  font-size: 14pt;

  @include md-down {
    font-size: 14pt;
  }

  padding: 9px 26px;
  border-radius: 32px;
  border: none;
  width: fit-content;

  @include links-inherit-color;

  &.primary {
    @include primary-button-color;
    @include button-hover;
  }

  &.secondary {
    @include secondary-button-color;
    border: 2px solid $ds-border-default;

    @include button-hover;
  }

  &.inverted {
    @include inverted-button-color;
    @include button-hover;
  }

  &.blue {
    background-color: $primary-blue;
    color: white;
    @include button-hover;
  }

  &.text {
    background-color: transparent;
    color: $ds-text-action;
  }

  &.link {
    background-color: transparent;
    color: $ds-text-action;
  }

  &:disabled,
  &.disabled {
    filter: grayscale(1) contrast(0.7);
  }

  .buttonChildrenWrapper {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    grid-gap: 18px;
  }
}