.filterTags {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  padding-top: 24px;
  padding-bottom: 16px;
  flex-wrap: wrap;

  .filterTag {
    background-color: $ds-surface-focus;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    font-size: 14px;

    .removeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $ds-surface-tertiary;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      border: none;
      color: $ds-icon-default;

      i {
        height: 100%;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
