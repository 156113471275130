.embeddedCode {
  border: none;

  min-height: 100%;
  min-width: 100%;

  &.empty {
    text-align: center;
    align-content: center;
    color: $gray-100;
    background-color: $gray-50;
    height: 253px; // based on the default size for an embedded code type dynamic block
    width: 253px; // based on the default size for an embedded code type dynamic block
  }
}
