.giftCardAndPinWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.giftCardWrapper {
  flex-grow: 3;
  width: 75%;
  margin-right: 24px;
}

.giftCardPinWrapper {
  flex-grow: 0;
  width: 25%;
}