$hover-padding: 16px;

.popularItem {
    border-radius: 24px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-size: 381px;
    height: 175px;
    width: 380px;

    @include md-down {
        background-size: 311px;
        height: 176px;
        width: 311px;
    }

    .itemName {
        border-radius: 24px;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        padding: 30px;
        position: relative;
        height: 100%;
        width: 100%;
    }
}

.popularItem.backgroundImage {
    .itemName {
        background-color: rgba(0, 0, 0, .45);
        color: white;
    }
}

.popularItem:not(.backgroundImage) {
  .itemName {
    @extend %theme-menu-card-default-colors;
  }
}

.menuSection.condensed, .menuSection.extraCondensed, .menuSection.stacked {
    box-sizing: border-box;
    padding: 16px 0;
    width: 100%;

    .itemSection {
        .item.condensed, .item.extraCondensed {
            max-width: calc(100% - 32px);
        }
        .item.stacked {
            max-width: 100%;
        }
        .item.condensed, .item.extraCondensed, .item.stacked {
            border-radius: 0;
            box-shadow: none;
            display: flex;
            flex: none;
            flex-direction: column;
            max-height: fit-content;
            min-height: auto;
            min-width: auto;
            overflow: auto;
            transition: none;
            width: 100%;

            &.clickable:not(.outOfStock) {
                &:hover {
                box-shadow: none;
                }
            }
        }
        .item.condensed, .item.extraCondensed {
            width: 100%;
            padding: $hover-padding;

            &.clickable {
                width: calc(100% - (2*$hover-padding));
            }

            @include sm-down {
                width: 100%;

                &.clickable {
                    width: calc(100% - (2*$hover-padding));
                }
              }
        }
    }

}

.item.clickable:not(.outOfStock):hover {
    &.stacked {
        .itemHeader {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.item.clickable:not(.outOfStock):hover {
    &.condensed, &.extraCondensed {
        cursor: pointer;
        background-color: $primary-color-transparent-25;
    }
}

.menuSection .itemSection {
    flex-direction: row;
    .itemInfo.condensed, .itemInfo.extraCondensed {
        padding: 0;

        .itemHeadAvailDesc {
            padding-right: 24px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
        }
        .itemHeader {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @include txt-body-lg;
            line-height: normal;
            -webkit-line-clamp: initial;

            @include sm-down {
                @include txt-body-lg-bold;
            }
        }

        .price {
            @include txt-body-md;
            text-decoration: none;
            white-space: nowrap;
        }

        .itemContent .itemDescription {
            color: $text-color-gray;
            @include txt-body-md;
            -webkit-line-clamp: inherit;
            white-space: pre-wrap;
        }

    }
}
