.discountsSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .header {
    font-weight: normal;
    line-height: 20px;
    color: #646464;
    font-size: 14px;
    padding-bottom: 12px;
  }
  .offerPills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    padding-bottom: 12px;
  }
}

.discountPill {
  background: none;
  padding: 6px 8px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-width: 0.75px;
  border-color: rgba(0, 0, 0, .16);
  border-radius: 50px;
  color: #646464;
  height: 40px;
  line-height: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .discountText {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    text-align: left;
    font-size: 16px;
    &.smallerText {
      font-size: 14px;
    }
  }

  &:not(.active, .locked, .buttonPill) {
    border-style: dashed;
    border-width: 1px;
    padding-right: 16px;
  }

  &.active {
    border-style: solid;
    background-color: #E7F7E6;
  }
  &.locked {
    border-style: solid;
    opacity: 0.5;
  }
  .tagIcon {
    border-radius: 50px;
    height: 28px;
    width: 28px;
    min-width: 28px;
    background-color: var(--tsw-primary-color-25);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .activeActions {
    display: flex;
    align-items: center;
    padding-left: 4px;

    .closeIcon {
      height: 28px;
      width: 28px;
      min-width: 28px;
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }
  .checkContainer {
    border-radius: 50px;
    height: 28px;
    width: 28px;
    min-width: 28px;
    background-color: #408140;
    display: flex;
    justify-content: center;
    align-items: center;

    .checkIcon {
      height: 17px;
      width: 17px;
    }
  }

  &.buttonPill {
    align-items: center;
    color: $link-blue;
    border-style: solid;
    padding: 0 16px;
    border-width: 0.75px;
  }

  .divider {
    height: 20px;
    width: 1px;
    opacity: 0.65;
    background: linear-gradient( rgba(64, 129, 64, 0) 0%, rgba(64, 129, 64, 0.4) 25%, rgba(64, 129, 64, 0.6) 50%, rgba(64, 129, 64, 0.4) 74.5%, rgba(64, 129, 64, 0) 100%);
  }
}
.buttonContainer{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.discountsModal {
  border-top: #0000001F 0.75px solid;
  display: flex;
  flex-direction: column;
  padding: 16px 32px 32px 32px;
  max-width: 450px;

  @media screen and (min-width: 768px) {
    min-width: 400px;
  }
  .header {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 8px;
    @include txt-family;
  }

  p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #646464;
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top: 16px;
    .discountsModalInput {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #CECECE;
      box-sizing: border-box;
      font-size: 16px;
    }
    .legal {
      text-align: center;
      font-size: 12px;
      a {
        text-decoration: underline;
        color: unset;
      }
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-self: flex-end;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .modalButton {
    width: 100%;
    justify-content: center;
    &.disabled {
      opacity: 0.4;
      filter: none;
    }
    &.confirm {
      width: fit-content;
    }
    &.cancel {
      background-color: #FFF;
      color: #1E1F24;
      border: 1px solid #CDCED7;
      width: 30%;
    }
  }
}

.closeModalButton {
  color: #646464;
  align-self: flex-end;
}
