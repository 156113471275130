.giftCardIllustration {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 60px;

  .headlines {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    color: $ds-text-default;

    .cta {
      @extend %txt-body-md;
      font-size: 26px;
      color: $ds-text-default;
    }

    .locationName {
      @extend %txt-title-md;
      color: $ds-text-default;
      font-size: 50px;
      line-height: 1.5;
    }
  }

  .card {
    box-shadow: 0 20px 31px 3px var(--ds-surface-shadow, rgba(0, 0, 0, 0.06));
    background-color: $ds-surface-tertiary;
    border-radius: 20px;
    padding: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: 24px;

    .bow {
      color: $ds-icon-action;
      position: absolute;

      // Gross but necessary because of how the SVG renders
      top: -16px;
      right: -2px;
    }

    .tagIcon {
      color: $ds-surface-default;
    }

    .illustration {
      width: 343px;
      max-width: 343px;
      height: 193px;
      max-height: 193px;
      border-radius: 12px;
      box-shadow: 0 8px 19px 1px var(--ds-surface-shadow, rgba(0, 0, 0, 0.06));
      border: 1px solid $gray-30;
      overflow: hidden;
      @extend %txt-body-sm;

      display: flex;
      flex-direction: column;

      .heading {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        .rxDetails {
          display: flex;
          align-items: center;
          gap: 16px;
          max-width: 70%;
          text-overflow: ellipsis;
          overflow: hidden;
          color: var(--ds-text-default, black);

          .logo {
            height: 48px;
            width: auto;
          }
        }

        .price {
          @extend %txt-body-sm;
        }
      }

      .maskedImageWrapper {
        overflow: hidden;
        clip-path: ellipse(100% 80% at 50% 80%);
        display: flex;
        flex: 2;
      }

      .maskedImage {
        width: 100%;
        height: 100%;
      }
    }

    .messages {
      align-items: center;
      gap: 16px;
      display: flex;
      flex-direction: column;
      color: var(--ds-text-default, black);

      .primary {
        display: flex;
        gap: 4px;
        font-weight: 600;

        .name {
          font-family: 'Brush Script MT', cursive;
          font-size: 24px;
          font-weight: 400;
        }
      }
    }
  }
}
