@mixin adjustable-padding($desktop-padding) {
  $tablet-padding: calc($desktop-padding * (2 / 3));
  $mobile-padding: calc($desktop-padding * (1 / 3));

  padding-top: $desktop-padding;
  padding-bottom: $desktop-padding;
  @include md-down {
    padding-top: $tablet-padding;
    padding-bottom: $tablet-padding;
  }
  @include sm-down {
    padding-top: $mobile-padding;
    padding-bottom: $mobile-padding;
  }
}

.sectionWrapper {
  &.bottomPadding {
    &.PADDING-SETTING--NONE {
      @include adjustable-padding(0);
    }

    &.PADDING-SETTING--SMALL {
      @include adjustable-padding(30px);
    }

    &.PADDING-SETTING--MEDIUM {
      @include adjustable-padding(60px);
    }

    &.PADDING-SETTING--LARGE {
      @include adjustable-padding(90px);
    }
  }

  &.topPadding {
    &.PADDING-SETTING--NONE {
      @include adjustable-padding(0);
    }

    &.PADDING-SETTING--SMALL {
      @include adjustable-padding(30px);
    }

    &.PADDING-SETTING--MEDIUM {
      @include adjustable-padding(60px);
    }

    &.PADDING-SETTING--LARGE {
      @include adjustable-padding(90px);
    }
  }

  &:first-of-type.topPadding {
    padding-top: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .sectionWrapper {
    &.transparent {
      opacity: 0;
    }
    &.animated {
      animation-duration: 1000ms;
      animation-timing-function: ease-out;
      animation-iteration-count: 1;
      animation-name: section-enter-from-bottom;
    }
    &.animated:first-child {
      animation-name: section-enter-from-bottom-slight;
    }
    &.preview.animated,
    &.preview.animated:first-child {
      animation-duration: 3000ms;
      animation-iteration-count: infinite !important;
      animation-timing-function: ease-in-out;
    }
  }
}

@keyframes section-enter-from-bottom {
  0% {
    opacity: 0.0;
    transform: translateY(90px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes section-enter-from-bottom-preview {
  0% {
    transform: translateY(90px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes section-enter-from-bottom-slight {
  0% {
    opacity: 0.7;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
