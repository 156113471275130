.tipSelector {
  padding-bottom: 12px;

  .tipHeader {
    margin: 0;
    padding-bottom: 8px;
  }

  .tipButtons {
    margin-top: 24px;
  }

  .buttonGroup {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 8px;

    .tipButton {
      flex: 1;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 45px;
      border-radius: 8px;
      background-color: $gray-25;
      border: none;

      &.selected {
        color: white;
        background-color: $primary-blue-75;
      }
    }
  }

  .tipPercent {
    font-weight: bold;
    font-size: 16px;
  }

  .tipAmount {
    font-size: 14px;
  }

  .customTipInput {
    margin-top: 8px;
  }
}
