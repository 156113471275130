.addressInput {
  display: flex;

  input {
    flex-grow: 1;
    border: 1px solid $dark-gray;
    border-radius: 32px;
    font-size: 16px;
    height: 32px;
  }
}

.addressInputError, .addressInput input {
  padding: 0 12px;
}
