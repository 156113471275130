.basicUpsellCardGrid {
  cursor: pointer;
  background-color: $gray-0;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 400;

  .title {
    font-size: 14px;
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    color: black;
  }

  .price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: black;
  }
}
