.confirmationSection {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 16px;
  padding: 16px;

  .helpText {
    font-size: 14px;
    text-align: center;
    color: $text-color-gray;

    .resend {
      color: $link-blue;
      padding: 8px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .formError {
    font-size: 14px;
    color: $error-red;
    @extend %txt-body-sm;
    margin-bottom: 4px;
  }
}

.inputFieldOtp {
  justify-content: center;
  margin-bottom: 0;

  > div {
      padding: 0;
      border: 1px solid #CECECE;
      border-radius: 8px;
      height: 56px;
      width: 52px;
      margin-bottom: 8px;
      justify-content: center;

      &:not(:last-of-type) {
        margin-right: 8px;
      }
  }
}
