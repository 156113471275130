.imageWithTags {
  .tagSection {
    display: flex;
    justify-content: center;
    gap: 4px;

    width: 100%;
    text-align: center;
    padding: 18px 0;
    color: var(--ds-text-on-bg-primary, white);
    @extend %txt-title-sm;

    background-color: var(--ds-surface-inverse, #242424);

    & p,h1,h2,h3,h4 {
      display: inline;
    }
  }

  .image {
    width: 100%;
    &.halfWidth {
      width: 50%;
    }

    height: 400px;

    @include sm-down {
      height: 200px;
    }
  }
}
