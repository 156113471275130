.profileWallet {
  .profileWalletHeading {
    font-size: 24px;
    line-height: 29px;
    color: black;
    @include txt-family;
  }

  .profileWalletHeadingDisabled {
    font-size: 24px;
    line-height: 29px;
    color: $gray-50;
  }

  .profileWalletSections {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 20px;
  }

  .walletSubheading {
    font-size: 18px;
    line-height: 22px;
    color: black;
    @include txt-family;
  }

  .toastCashCardContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;    

    .toastCashCard {
      background-color: #F8F5F2;
      border-radius: 12px;
      padding: 12px;

      .toastCashContentsContainer {
        display: flex;
        flex-direction: row;
        gap: 12px;
        justify-items: left;
        align-items: center;

        img {
          height: 34px;
          width: 34px;
        }
        .toastCashAmountContainer {
          width: 100%;

          .toastCashAmount {
            font-size: 26px;
            line-height: 32px;
            font-weight: 600;
          }
    
          .toastCashAvailableFunds {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .toastCashDisclaimer, .savedCardsDisclaimer {
    color: #666;
    font-size: 12px;
    line-height: 16px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .savedCards {
    margin: 20px 0;
  }

  .empty {
    font-size: 14px;
    line-height: 1.25rem;
    color: $gray-100;
    margin: 20px 0;
  }

  .savedCreditCard, .addCard {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .savedCreditCard {
    margin-bottom: 12px;

    .savedCardRadio {
      flex-grow: 1;

      .savedCardLabel {
        display: flex;
        align-items: center;
        gap: 12px;

        .cardLogo {
          width: 37px;
        }

        .expiration {
          margin-left: auto;
        }
      }
    }

    .editCard {
      cursor: pointer;
      padding: 0 10px 0 20px;
    }

    .dropDown {
      .dropDownArrow {
        display: none;
      }

      .dropDownContent {
        width: auto;
        right: 5px;
        white-space: nowrap;
      }
    }
  }

  .savedCardLabel .labelText {
    color: black;
  }
}
