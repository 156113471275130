.toastLocalFooter {
  position: relative;
  height: 230px;
  margin-top: 40px;
  margin-bottom: 100px;
  @include txt-source-sans-pro;

  .container {
    height: 100%;

    .footerContent {
      display: table;
      width: 100%;
      height: 100%;

      border-top: 1px solid $light-gray;

      @include xs {
        padding-top: 20px;
      }

      .contentCell {
        display: table-cell;
        vertical-align: middle;

        .logoWrapper {
          margin-bottom: 8px;

          .logoLink { display: inline-block; }
        }

        .footerNav {
          @extend %txt-source-sans-pro;
          font-size: .9rem;

          a {
            color: $text-color;
            @extend %txt-body-md;

            &:hover {
              color: $link-blue;
              text-decoration: underline;
            }
          }

          .socialMedia {
            margin-top: 10px;

            li {
              display: inline-block;
              padding: 0px 20px 0px 0px;
            }
          }

          &.navDown {
            li { margin-bottom: 10px; }
          }

          @include xs {
            margin-bottom: 20px;
          }

          .toastBadge {
            margin-top: 30px;
          }
        }

        ul.footerNav {
          margin: 0px;
          padding: 0px;

          li {
            list-style-type: none;

            a {
              color: $text-color;
              @extend %txt-source-sans-pro;
              font-size: .9rem;
            }
          }

          @include xs {
            margin-bottom: 20px;
          }
        }

      }
    }
  }
}
