.logoutModalContent {
  display: grid !important;
  max-width: 400px;
  grid-template:
          "header" 29px
          "body" minmax(100px,1fr);
  grid-gap: 32px;
  padding: 24px;

  .header {
    grid-area: header;

    display: grid;
    grid-auto-columns: minmax(245px, 5fr) minmax(40px, 1fr);

    .headerText {
      grid-row: 1;
      align-self: center;

      font-family: 'Effra Heavy', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
    }

    .headerClose {
      grid-row: 1;
      justify-self: end;
      align-self: center;

      .closeButton {
        margin: 0;
        padding: 2px;
      }
    }
  }

  .body {
    grid-area: body;
  }
}
