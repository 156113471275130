.appliedInput {
  position: relative;

  .appliedInputValue {
    height: 36px;
    line-height: 36px;
  }
}

.customInputWrapper {
  display: flex;
  flex-direction: row;

  .inputButton {
    all: unset;
    align-self: flex-start;
    height: 62px;
    font-weight: 700;
    transition: all .2s ease;
    margin-left: 12px;
    color: rgba(0,0,0,.12);


    &.active {
      cursor: pointer;
      color: $ds-text-action;
    }
  }
}

