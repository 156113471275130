.menuItemLink {
  height: 100%;
  width: 100%;
}

.menuItemWrapper {
  position: relative;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 16px;
  height: 100%;

  background: linear-gradient(9.94deg, rgba(0, 0, 0, 0.64) 10.13%, rgba(0, 0, 0, 0) 50%),
  linear-gradient(8.65deg, rgba(0, 0, 0, 0.64) -24.9%, rgba(0, 0, 0, 0) 76.2%),
  linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08));

  .itemImage {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    border-radius: 16px;
  }

  .contentDiv {    
    z-index: 1;
    padding: 24px;
    border-radius: 16px;
  }

  .itemName {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    position: relative;
    @extend %line-clamp-1;
    text-overflow: ellipsis;
  }

  .itemPrice, .itemDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    @extend %line-clamp-1;
    text-overflow: ellipsis;
    position: relative;

  }
}
