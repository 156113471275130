.intlPhoneInput {    
    .inputFieldInner {
        margin-bottom: 12px;
    }

    label {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-right: 16px;
    }

    button,
    ul {
        text-align: left;
        background: #fff;
        border: 0;
    }

    button {
        display: flex;
        align-items: center;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 16px 8px 16px 16px;

        &:focus-visible {
            outline: 0;
            box-shadow: 0 0 5px 2px rgba(251, 146, 60, 0.7) inset;
        }
    }

    ul {
        position: absolute;
        left: 0;
        right: 0;
        top: 60px;
        max-height: 200px;
        overflow-y: auto;
        padding: 0;
        margin-top: 0;
        opacity: 0;
        transform: scale(1, 0);
        transform-origin: top left;
        transition: all .3s ease-in;
        pointer-events: none;
        z-index: 2;
        background: #fff;
        box-shadow: var(--react-international-phone-dropdown-shadow,2px 2px 16px rgba(0,0,0,.25));

        &.active {
            opacity: 1;
            transform: scale(1, 1);
            pointer-events: auto;
        }

        li {
            padding: 8px 16px;
            cursor: pointer;
            transition: all .3s ease-in;
            position: relative;
            display: flex;
            align-items: center;

            &:hover,
            &.current {
                background: #e6e6e6;
            }

            &.active {
                box-shadow: 0 0 0 2px rgba(251, 146, 60, 0.7);
            }

            &.active::before {
                opacity: 1;
            }

            img {
                margin-right: 10px;
            }
        }
    }

    &Dropdown {
        display: flex;
    }
}