.rx-carousel {
  .title-section {
    padding-bottom: 24px;
  }

  h2 {
    color: #000;
    display: inline-block;
    font-size: 22px;
    @include txt-rg;
  }

  .scroll-button-section {
    background: #FFFFFF;
    border-radius: 50px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    float: right;
    height: 32px;
    width: 64px;

    & > .arrow {
      padding: 4px;
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }

  .restaurants {
    display: block;
    overflow: hidden; /* Hide scrollbars */
    padding-bottom: 10px;
    white-space: nowrap;

    @include sm-down {
      overflow-x: auto;
      overflow-y: hidden;
      scroll-snap-type: x mandatory;
    }
  }

  .carousel-listing {
    @extend %box-shadow-light;
    margin-right: 16px;
    width: 288px;
    border-radius: 24px;
    overflow: hidden;
    scroll-snap-align: start;

    a {
      &:hover { text-decoration: none; }
    }

    .listing-image-container {
      height: 132px;
      width: 288px;
      overflow: hidden;

      .listing-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .listing-content {
      padding: 16px;

      .first-line {
        overflow-y: hidden;
        max-height: 30px;
      }

      .review-summary {
        color: $text-color;
        float: right;
        font-size: 14px;
        @include txt-rg;
        img {
          height: 10px;
          width: 10px;
          padding-right: 4px;
        }
      }

      .rx-name {
        color: #000000;
        display: inline-block;
        font-size: 16px;
        @include txt-rg;
        text-overflow: ellipsis;
        max-width: 65%;
        overflow: hidden;
      }

      &:hover .rx-name {
        max-width: 100%;
      }

      .address-line {
        color: #000000;
        font-size: 12px;
        @include txt-rg;
      }

      .view-rx {
        color: $text-color;
        font-size: 16px;
        font-weight: 700;
        height: 25px;
        margin-top: 27px;
        .icon-img {
          float: right;
        }
      }
    }
  }

  .more-rx-button {
    padding-top: 24px;
    display:flex;
    justify-content: center;

    .btn {
      width: fit-content;

      .btn-text {
        background: $extra-dark-gray;
        border: 2px solid $extra-dark-gray;
        border-radius: 32px;
        color: #FFFFFF;

        display: inline-block;
        font-size: 16px;
        @include txt-rg;
        line-height: 24px;
        padding: 12px 24px;
      }
    }
  }
}
