.topMenuNav {
  border-top: 3px solid $ds-background-primary;
  border-bottom: 3px solid $ds-background-primary;
  padding: 12px 6px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px 32px;

  @include sm-down {
    flex-direction: column;
    align-items: stretch;
  }

  .topMenuNavMenuSelection {
    min-width: 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .navControl {
      margin-right: 12px;
    }

    .topMenuNavWrapper {
      position: relative;
      overflow: auto;

      .sections {
        margin-right: 1px;
        margin-left: 1px;
        max-width: 100%;
        display: grid;
        grid-template-columns: auto;
        grid-auto-flow: column;
        overflow: scroll;
        @include hide-scrollbar;

        .menuItem {
          scroll-margin-left: 6px;
          scroll-margin-right: 6px;
          padding: 10px 32px;
          @include sm-down {
            padding: 6px 12px;
          }
          white-space: nowrap;

          .menuItemTarget {
            display: flex;
            gap: 12px;
            cursor: pointer;

            .menuLink {
              color: var(--ds-text-secondary, var(--tsw-primary-text-color));
              font-size: 16px;
              font-weight: 400;
              @include sm-down {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              &.selected {
                text-decoration: none;
                color: $ds-text-action;
                font-weight: 700;
              }
              &:hover {
                text-decoration: none;
              }
            }

            .dropdownTriangle {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .arrow {
        padding: 0 12px;
        position: absolute;
        height: 100%;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        z-index: 2;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.2s ease-out;

        top: 0;

        &.arrowHidden {
          display: none;
        }
      }

      .leftArrow {
        left: 0;
        padding-left: 0;
        background: linear-gradient(90deg, var(--ds-surface-default, var(--tsw-background-color)) 75%, var(--ds-surface-default, var(--tsw-background-color-med)));
      }

      .rightArrow {
        right: 0;
        padding-right: 0;
        background: linear-gradient(90deg, var(--ds-surface-default, var(--tsw-background-color)) 75%, var(--ds-surface-default, var(--tsw-background-color-med)));
      }
    }
  }

  .topMenuNavSearch {
    max-width: 230px;
    margin-left: auto;

    @include sm-down {
      max-width: none;
      margin-left: initial;
    }
  }
}

.dropdown {
  background: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04), 0 4px 5px rgba(0, 0, 0, 0.06), 0 2px 4px -1px rgba(0, 0, 0, 0.09);
  z-index: 10;
  width: 300px;
  max-height: 496px;
  overflow-y: scroll;
  @include sm-down {
    width: 200px;
    max-height: 304px;
  }
}

.subMenuItem {
  padding: 16px 12px;
  width: 100%;
  background: #ffffff;
  border: none;
  font-size: 16px;
  text-align: left;
  cursor: pointer;

  &:focus,
  &:hover {
    background: $ds-surface-default;
    color: $ds-text-default;
  }
}
