.spotlightBanner {
  height: 48px;
  transition: height 0.5s ease-out;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 24px;

  position: relative;

  // keep under cart dropdown
  z-index: 7;

  &.isHidden {
    height: 0;
  }

  .carouselWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .spotlightCarousel {
      width: 100%;

      .carouselArrows {
        width: 44px;
        height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        padding: 0;
        display: flex;
        gap: 4px;
        align-items: center;

        div {
          height: 20px;
          width: 20px;
          border-radius: 30px;
          background: color-mix(in srgb, var(--spotlightBannerBgColor), #000 15%);
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            display: flex;
          }
    
          * {
            height: 10px;
            width: 10px;
          }

          &:not(.swiper-button-disabled) {
            cursor: pointer;

            &:hover {
              background: color-mix(in srgb, var(--spotlightBannerBgColor), #000 30%);
            }
          }

          &.swiper-button-disabled {
            opacity: 0.4;
          }
        }

        .carouselArrowPrev {
          left: 0;
        }

        .carouselArrowNext {
          right: 0;
        }
      }

      .carouselPagination {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 4px 0;

        span {
          width: 24px;
          height: 3px;
          background: var(--spotlightPaginationColor);
          border-radius: 4px;

          &:not(.swiper-pagination-bullet-active) {
            opacity: 0.4;
          }
        }
      }
    }

    .hideButton {
      background: color-mix(in srgb, var(--spotlightBannerBgColor), #000 15%);
      height: 20px;
      width: 20px;    
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0;
      position: absolute;
      top: 20%;
      z-index: 1;

      &.singlePage {
        top: 0;
      }

      &:hover {
        background: color-mix(in srgb, var(--spotlightBannerBgColor), #000 30%);
      }

      i {
        position: relative;

        svg {
          position: absolute;
          left: 0;
        }
      }

      * {
        height: 10px;
        width: 10px;
      }
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
  
    .contentWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      gap: 4px;
      width: 50%;

      @include xs{
        width: 100%;
      }

      .seeMoreButton {
        min-width: fit-content;

        &.leftPadding {
           padding-left: 8px;
        }
      }

      button {
        font-size: 14px;
        padding: 0px;
      }
    }
  }

  &.fixedTop {
    position: sticky;
    top: 0;
    left: 0;
    right: 0
  }

  .description {
    @extend %line-clamp-1;
    line-height: 24px;
  }

  .icon {
    padding-right: 4px;
    height: 16px;
    width: 16px;

    i {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  @include sm-down {
    font-size: 1rem;
    font-weight: 500;
  }
}

.spotlightModal {
  padding: 16px 24px 24px;
  max-width: 375px;
  line-height: 20px;

  .header {
    display: flex;
    justify-content: flex-end;
  }

  .description {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .additionalDetails {
    font-size: 16px;
  }

  .closeButton {
    cursor: pointer;
  }
}