.circle {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: $error-75; 
  display: inline-block;
  margin-right: 8px;
  
  &.open {
    background-color: $open-green;
  }
}
