.itemHistoryModal {
  min-width: 500px;

  .closeButton {
    position: absolute;
    top: 8px;
    right: 16px;
    z-index: 3;
    background-color: white;
    border-radius: 50%;

    @include sm-down {
      top: 0;
    }
  }

  h2 {
    padding-top: 24px;
    padding-left: 24px;
  }

  @include sm-down {
    min-width: 100%;
  }

  .items {
    padding: 24px 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    overflow: scroll;

    > :last-child {
      margin-bottom: 24px;
    }
  }

  .itemCard {
    max-width: 100%;
    min-width: 100%;
    overflow: visible;

    &:last-child {
      margin-bottom: 24px;
    }
  }
}
