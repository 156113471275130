.ooSearch {
  margin-left: auto;
  height: 40px;

  background-color: white;

  border: 1px rgba(0,0,0, .12) solid;
  border-width: 1px;
  border-radius: 9999px;

  &:hover {
    border-color: rgb(43, 79, 185);
    box-shadow: rgb(43, 79, 185) 0px 0px 0px 1px inset;
  }
  &:focus-within {
    border-color: rgb(43, 79, 185);
    outline: none;
    box-shadow: rgb(85, 142, 221) 0px 0px 0px 3px;
  }

  display: flex;
  justify-content: unset !important;
  align-items: center;

  .searchIcon {
    color: rgb(102, 102, 102);
    margin-Left: calc(-1px + 0.75rem); line-height: 0;
  }

  .searchBox {
    border-radius: 9999px;
    height: calc(100% - 4px);
    flex: 1;
    min-width: 0;

    &:focus {
      // ensure that text size is large enough or else mobile browsers will automatically zoom on focus
      font-size: 18px;
      &::placeholder {
        // Hide placeholder when input is focused
        color: transparent;
      }
    }
  }

  .searchClearIconPlaceHolder {
    width: 36px;
    height: 36px;
    border-radius: 9999px;
  }

  .searchClearIcon {
    width: 36px;
    height: 36px;

    border-radius: 9999px;

    button {
      background-color: transparent;
      border: none;
      color: rgb(192, 192, 192);
      padding: 2px;
      line-height: 0px;

      &:focus-within {
        outline: none;
        border-radius: 9999px;
        background-color: rgba(0, 0, 0, 0.04)
      }

      i {
        display: inline-block;
        padding: 4px;
        border-radius: 9999px;
      }
    }
  }
}
