.locationSelectionModal {
  height: unset;
  min-width: 300px;
  padding-bottom: 16px;

  @include md {
    min-width: 500px;
  }

  .closeButton {
    position: absolute;
    right: 0;
  }

  .optionsHeading {
    padding: 16px 16px 0;
  }

  .optionsLocationSelector {
    padding: 16px 16px 0;

    .updateButton {
      padding: 24px 0 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .results {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .location {
        border-radius: 8px;
        padding: 8px;
        cursor: pointer;
        transition: background-color 0.2s ease-out;

        &:hover {
          background-color: $extra-light-gray;
        }

        &.selected {
          background-color: $light-gray;
        }

        &.disabled {
          color: $medium-gray;

          &:hover {
            cursor: default;
            background-color: white;
          }
        }

        .name {
          font-weight: 500;
        }

        .locationInfo {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .header {
      font-weight: 500;
      line-height: 24px;
      padding-bottom: 8px;
      padding-top: 16px;
    }

    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      padding-bottom: 16px;
    }

    .toggle {
      margin-bottom: 16px;
    }

    .locationSelector {
      .dropDown {
        height: 34px;
        line-height: normal;
        font-size: 16px;

        .icon {
          padding: 0 10px 0 0;
        }

        .dropDownArrow {
          width: unset;
        }
      }
    }
  }

  .backButton {
    position: absolute;
    left: 0;
    display: flex;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    border: none;
    background: none;

    img {
      height: 18px;
      width: 18px;
    }

    &:hover {
      border-radius: 50%;
      background-color: #f5f5f5;
    }

    &.white:hover {
      background-color: var(--tsw-primary-color);
    }
  }

  .optionsMapFrame {
    height: 127px;
    border: none;
    box-sizing: border-box;
    transition: height 0.5s ease-out;
    border-radius: 24px 24px 0 0;

    &.expanded {
      height: 423px;
    }

    &:only-child {
      margin-bottom: 16px;
    }
  }

  .optionItems {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    transition: height 0.5s ease-out, padding 0.5s ease-out;

    &.collapsed {
      height: 0;
      overflow: hidden;
      padding: 0;
    }
  }
}
