.miniScroll {
  white-space: nowrap;

  ul {
    display: inline-block;
    margin: 0;
    padding: 0;

    &.navButtons {
      white-space: nowrap;

      button {
        width: 32px;
        height: 32px;
        line-height: 32px;
        border: none;
        background-color: #FFFFFF;
        @extend %border-radius-full;
        @extend %box-shadow-light;
        text-align: center;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }

    li {
      display: inline-block;
      padding: 0px 3px;
      list-style-type: none;
    }

    &.dots {
      margin-right: 10px;

      @include sm-down {
        display: none;
      }

      .dot {
        width: 8px;
        height: 8px;
        @extend %border-radius-full;
        background-color: $gray-50;

        &.active { background-color: $gray-110; }
      }
    }
  }
}
