.payment {
  .subSection, .appliedInput {
    margin: 12px 0;
  }

  .note {
    margin-top: 5px;
  }

  .changePaymentLink {
    text-align: center;
  }

  .creditCardForm {
    // fixes a bug where android chrome zooms in on the cc form inputs when focused
    // https://stackoverflow.com/questions/7073396/disable-zoom-on-input-focus-in-android-webpage
    touch-action: none;
  }
}

.paymentTabs {
  display: flex;
  flex-direction: row;

  button {
    flex: 1;
    background-color: white;
    border: 0;
    padding: 12px;
    @include txt-body-md-bold;
    font-size: 16px;

    &.selected {
      border-bottom: 3px solid var(--tsw-white-contrasting-color)
    }

    &:not(.selected) {
      border-bottom: 1px solid $medium-gray;
    }
  }
}

.paymentButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .option {
    width: auto;
    line-height: 24px;
    padding: 8px 0;
    margin: 12px 0;

    .appleLogo {
      vertical-align: bottom;
    }
  }
}

.note {
  margin-top: 6px;
}

.payAtRestaurantNote {
  margin-top: 24px;
}

.orderPrices {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 16px;
  line-height: 24px;
  color: $gray-125;

  .taxesAndFees {
    display: flex;
    flex-direction: row;
    align-items: center;

    .infoIcon {
      cursor: pointer;
    }
  }
  .total {
    font-weight: bold;
    display: flex;
  }

  .surcharge {
    border-top: 1px solid $medium-gray;
    margin-top: 14px;
    padding-top: 24px;
  }

  .surchargeDisclaimer {
    font-size: 12px;
    line-height: 16px;
    color: $gray-100;
  }
}
