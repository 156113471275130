$selector-height: 48px;

.locationSelector {
  .dropDownWrapper {
    position: relative;

    @include sm-down {
      margin: auto;
    }
  }

  .dropDown {
    @extend %txt-body-md;
    cursor: pointer;
    margin-right: 0;
    height: 42px;
    background-color: white;
    padding: 2px 12px;
    font-size: 16px;

    .icon, .selectedLocation {
      vertical-align: middle;
    }

    .icon {
      padding: 0 10px 0 12px;
    }

    .selectedLocation {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @include md-down {
        max-width: 350px;
      }

      @include sm-down {
        max-width: 225px;
      }
    }

    .dropDownArrow {
      vertical-align: top;
    }

    .dropDownArrow:after {
      padding-left: 15px;

      @include md-down {
        padding-left: 22px;
      }
    }

    .dropDownContent {
      width: 100%;

      div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        padding: 10px 20px;
        line-height: 24px;

        &:hover {
          background-color: $black-4;
        }
      }
    }
  }
}
