.calendarEvents {
  .initialAddEvent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .plusSign {
    width: 40px;
    height: 40px;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .eventsList {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    .event {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      border-bottom: 1px solid;
      border-color: var(--tsw-secondary-text-color);
      padding-bottom: 30px;
      &:first-child {
        margin-top: 0;
      }

      .eventDate {
        font-weight: 700;
        font-size: 16px;
        line-height: 19.2px;
        color: var(--tsw-primary-text-color);
        flex-basis: 20%;
      }
      .eventContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-basis: 60%;
        padding-left: 18px;
        padding-right: 18px;
        > * {
          text-align: center;
        }
        .eventName {
          font-size: 32px;
          font-weight: 400;
          line-height: 38.4px;
          color: var(--tsw-primary-text-color);
        }
        .eventDescription {
          line-height: 16px;
          font-size: 12px;
          color: var(--tsw-primary-text-color);
        }
      }
      .eventLink {
        font-size: 16px;
        line-height: 24px;
        color: var(--tsw-primary-text-color);
        flex-grow: 0;
        text-align: right;
        flex-basis: 20%;
      }

      @include sm-down {
        flex-direction: column;
        .eventLink {
          flex-basis: 100%;
          text-align: center;
        }
      }
    }
  }

  .no-events-disclaimer {
    line-height: normal;
  }
}
