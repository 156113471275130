button.closeButton {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  color: currentColor;
  padding: 1rem;
  border: none;
  border-radius: 50%;

  &:hover {
    background-color: $ds-surface-focus;
  }
}
