$desktop-padding: 80px;
$tablet-padding: 50px;
$mobile-padding: 30px;
$max-logo-width: 220px;

@mixin left-padded-section {
  padding-left: $desktop-padding;

  @include md-down {
    padding-left: $tablet-padding;
  }

  @include sm-down {
    padding-left: $mobile-padding;
  }
}

@mixin right-padded-section {
  padding-right: $desktop-padding;

  @include md-down {
    padding-right: $tablet-padding;
  }

  @include sm-down {
    padding-right: $mobile-padding;
  }
}

@mixin padded-section {
  display: flex;
  justify-content: center;

  @include left-padded-section;
  @include right-padded-section;

  > * {
    max-width: 1224px;
    width: 100%;
    align-self: center;
  }
}

@mixin padded-section-full-width {
  display: flex;
  justify-content: center;

  @include left-padded-section;
  @include right-padded-section;

  > * {
    width: 100%;
    align-self: center;
  }
}

@mixin half-padded-section {
  > * {
    max-width: 600px !important;
    width: 100%;
    align-self: center;
  }
}

.defaultTemplate {
  .paddedSection {
    @include padded-section;
    &.whiteBackground {
      background-color: white;
    }
  }

  .leftPaddedSection {
    @include half-padded-section;
    @include left-padded-section;
  }

  .rightPaddedSection {
    @include half-padded-section;
    @include right-padded-section;
  }

  .nav {
    padding-top: 12px;
    padding-bottom: 12px;

    @include md-down {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .locationSelectorWrapper {
    padding: 20px 0;
  }

  .orderAgain {
    padding-top: 16px;
  }

  .gradient {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(196, 196, 196, 0.2) 100%);
  }

  .sectionHeader {
    padding-bottom: 24px;
  }

  .sectionSeparatorWrapper {
    width: 100%;
    background-color: $ds-surface-default;
  }

  .sectionSeparator {
    max-width: 940px;
    padding: 0 30px;
    border: 1px solid $ds-border-default;
  }

  .centeredLogo {
    display: flex;
    justify-content: center;
    max-height: 300px;
    margin-top: 6px;

    img {
      object-fit: contain;
      max-width: 100%;
    }
  }

  .themeElement {
    &.rounded {
      border-radius: 24px;
    }
  }

  .themeElement {
    &.halfLeaf {
      border-radius: 100px 100px 100px 0;
    }
  }

  .themeRow.wide {
    > div:first-child .themeRowPaddedElement {
      @include md {
        padding-left: $tablet-padding;
      }

      @include lg {
        padding-left: $desktop-padding;
      }
    }

    > div:last-child .themeRowPaddedElement {
      @include md {
        padding-right: $tablet-padding;
      }

      @include lg {
        padding-right: $desktop-padding;
      }
    }

    > div .themeRowPaddedElement {
      padding-left: 1rem;

      @include sm-down {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .headerBadge {
    display: inline-block;
    margin-left: 8px;
  }

  .separator {
    height: 4px;
    border: none;
    margin: 2px 0;
  }
}

.padAroundLargeLogo {
  // add 25px buffer account for the circle around the logo
  padding-left: calc($max-logo-width + 25px);
  padding-right: calc($max-logo-width + 25px);
  padding-top: 0;
  @include sm-down {
    padding-left: 0;
  }
}


// Use a side nav when BOTH the sideBySide class is applied AND screen size is sufficiently large
.navAndMenu {
  display: flex;
  flex-direction: column;

  &.sideBySide {
    flex-direction: row;
    @include sm-down {
      flex-direction: column;
    }

    .menuSections {
      overflow: scroll;
      width: 100%;
    }
  }

  .fulfillmentContainer {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 35px;
  }
}

// Dynamic components fitting
.blockWrapper {
  & > div {
    width: 100%;
  }
}
