.stickyFooter {
  position: fixed;
  bottom: 0;
  padding: 15px 30px;
  background-color: $ds-surface-default;
  width: 100%;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.25));
  z-index: 1;

  button {
    width: 100%;
  }

  display: none;
  @include sm-down {
    display: block;
  }
}
