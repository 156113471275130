.mission {
  position: relative;
  margin: 60px 0px;

  .missionImg {
    height: 440px;
    width: 100%;
    @extend %border-radius-lg;
  }

  .missionContent {
    position: absolute;
    top: 0;
    left: 75px;
    bottom: 0;
    align-content: center;
    color: #FFFFFF;
    max-width: 500px;

    .flares, h2 { margin-bottom: 24px; }

    h2 {
      font-weight: 400;
      font-size: 1.8rem;
    }

    p {
      @include txt-source-sans-pro;
      font-size: 16px;
    }

    @include sm-down {
      left: 45px;
      right: 45px;
    }
  }
}
