$image-gap: 24px;

.gallery {
  display: flex;
  flex-direction: column;

  @include sm-down {
    &.desktopGallery {
      display: none;
    }

    &.paddedSection {
      padding-right: 0;
    }
  }

  .galleryWrapper {
    .galleryImage {
      height: 200px;
    }
  }

  @include md {
    &.mobileGallery {
      display: none;
    }

    .galleryWrapper {
      display: flex;
      gap: $image-gap;

      .imageRows, .imageRows .imageRow {
        display: flex;
        gap: $image-gap;
      }

      .firstImage, .imageRows {
        flex: 1 0 50%;
      }

      .firstImage .galleryImage {
        height: calc(400px + $image-gap);
      }

      .galleryImage {
        width: 100%;
      }

      .imageRows {
        flex-direction: column;
      }
    }
  }
}
