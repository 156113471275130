.orderAgain {
  .ordersWrapper {
    display: flex;
    justify-content: center;
    overflow: auto;
  }

  .scrollWrapper {
    max-width: min(1440px, 100%);
    flex: 1;
    padding-left: 108px;

    &.sideBySide {
      padding-right: 40px;
      padding-left: 40px;
    }

    @include md-down {
      padding-left: 36px;
    }

    @include sm-down {
      padding-left: 16px;
    }
  }

  .orders {
    padding-top: 26px;
    padding-bottom: 26px;
    display: flex;
    flex: 1;
    gap: 16px;
  }

  .seeMore {
    padding-left: 0;
    margin-top: -12px;
  }
}

.orderCard {
  background-color: white;
  border: none;
  text-align: left;

  position: relative;
  max-width: 300px;
  min-width: 300px;
  overflow: hidden;

  padding: 16px;
  border-radius: 24px;
  box-shadow: 0 0 6px var(--ds-surface-shadow, rgba(0, 0, 0, 0.1));
  transition: box-shadow 0.15s ease-out;

  @include sm-down {
    max-width: 100%;
  }

  &:hover {
    box-shadow: 0 0 24px var(--ds-surface-shadow, rgba(0, 0, 0, 0.2));
    cursor: pointer;
  }

  &.loading {
    cursor: default;
  }

  &.squaredCorners {
    border-radius: 0;
  }

  h4 {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 4px;
    display: flex;
    gap: 8px;

    .selection {
      display: flex;
      align-items: center;
      gap: 4px;

      .quantity {
        border-radius: 50%;
        background-color: black;
        color: white;
        height: 24px;
        width: 24px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .fade {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 20px;
      z-index: 1;
      background: linear-gradient(270deg, white 75%, rgba(255, 255, 255, 0.3));
    }
  }

  .subtitle {
    font-size: 14px;
  }

  .orderInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    font-size: 16px;
    font-weight: 500;

    .icon {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}
