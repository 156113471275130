.toastLocalHero {
  position: relative;
  padding-bottom: 100px;

  .heroImage {
    position: absolute;
    right: 0;
    height: 500px;
  }

  .heroContent {
    position: relative;
    display: flex;

    .leftContent {
      display: flex;
      flex-direction: column;

      gap: 24px;

      @include md-down {
        align-items: center;
      }

      h1 {
        @include txt-title-xxxl;
        @include txt-rg;
        margin-top: 150px;

        @include md-down {
          text-align: center;
        }
      }
    }

    .rightContent {
      position: relative;

      .foodPlate {
        position: absolute;
        width: 200px;
        height: 200px;
        padding: 10px;
        background: #FFFFFF;
        transition: all 0.15s ease-in-out;
        box-sizing: content-box;
        @extend %border-radius-full;
        @extend %box-shadow-soft;

        .cropper {
          width: 100%;
          height: 100%;
          overflow: hidden;
          @extend %border-radius-full;

          img {
            width: auto;
            height: 100%;
          }
        }

        &.foodPlate1 {
          transform: scale(0.65);
          top: 70%;
          left: 8%;
        }

        &.foodPlate2 {
          transform: scale(0.36);
          left: 2%;
          top: 8%;
        }

        &.foodPlate3 {
          top: 40%;
          left: 54%;
        }
      }
    }
  }
}
