.rx-overview {
  max-width: 383px;

  @include md-down {
    max-width: 247px;
  }

  @include sm-down {
    max-width: none;
  }

  .address {
    margin: 8px 0 16px;
  }

  .ctas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: baseline;

    @include md-down {
      row-gap: 16px;
    }

    .cta {
      text-align: center;
      color: $text-color;
      flex-grow: 1;
      flex-basis: 0;

      @include md-down {
        flex-basis: 50%;
      }

      @include sm-down {
        flex-basis: 0;
      }

      &>p {
        @include txt-body-md;

        @include sm-down {
          @include txt-body-sm;
        }
      }
    }
  }

  hr {
    margin: 16px 0;
    color: $light-gray;
  }
}
