.cartPopoverTarget {
  border-radius: 32px;
  padding: 16px 24px;

  background-color: $ds-background-primary;
  color: $ds-text-on-bg-primary;

  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;

  cursor: pointer;

  .cartIcon {
    stroke: $ds-text-on-bg-primary;
  }

  @include md-down {
    padding: 11px 15px;
  }

  &.disabled {
    cursor: default;
    border-color: $text-color-disabled;
    opacity: 0.5;
  }

  &:hover {
    background-color: $ds-background-primary-focus;
    cursor: pointer;
  }

}

.targetAction {
  background-color: transparent;
  border: 0px;
  padding: 0px;
  margin: 0px;

  &:focus-visible {
    outline-offset: 3px;
  }
}
