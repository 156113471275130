@mixin topImageTemplateV2 {
  a {
    flex-direction: column;
  }
  height: 100%;
  width: 100%;
  .mobileDescription {
    display: none;
  }

  .desktopDescription {
    .itemDescription {
      min-height: 34px;
    }
  }

  @include sm-down {
    .mobileDescription {
      display: block;
    }

    .desktopDescription {
      display: none;
    }
  }

  .itemImage {
    border-bottom: 0.75px solid;
    height: unset;
    &.hideImage {
      display: none;
    }
  }

  .itemInfo {
    min-height: 124px;
    max-height: 124px;
  }
}

