.giftCardPurchaseForm {
  max-width: 538px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .creditCardFormSpi {
    width: 100%;
  }

  flex-direction: column;
  gap: 32px;

  .row {
    display: flex;
    gap: 32px;
    width: 100%;

    .inputField {
      width: 100%;
      margin-bottom: 0;

      input,
      textarea {
        line-height: normal;
      }

      &.inputFieldTextarea {
        .inputFieldInner {
          height: 80px;
        }
      }
    }

    @include md-down {
      flex-direction: column;
    }
  }

  .button {
    width: 100%;
  }

  .ccForm {
    width: 100%;
  }

  .successMessage {
    text-align: center;
    @extend %txt-body-lg;
    font-size: 38px;
  }
}