$rx-photo-padding: 10px;

.rxPageSectionWrapper {
  flex-direction: column;

  .rx-page-section {
    margin: 8px 0;
  }

  h1.rx-page-section {
    font-weight: 900;
    font-size: 48px;
    line-height: 44px;
    @include sm-down {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .rx-page-section.rx-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    & > * {
      flex: calc(50% - 16px);
      @include sm-down {
        flex: 100%;
      }
    }
  }

  a { color: $link-blue; }
}

.restaurantProfile {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 10px;

  .lg-reverse {
    display: flex;
    flex-direction: column-reverse;
    @include sm-down {
      flex-direction: column;
    }

    .rx-info {
      display: block;
      position: relative;
      padding-right: 480px;  // match the width of .rx-overview
      margin: 16px 0;

      @include md-down {
        padding-right: 345px;  // match the width of .rx-overview in md-down
      }

      @include sm-down {
        position: static;
        padding-right: 0;
        margin: 0;
      }

      .overview-container {
        position: absolute;
        top: 16px;
        right: 32px;
        background: #FFFFFF;
        padding: 24px;
        border-radius: 24px;
        z-index: 1;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);

        @include sm-down {
          position: static;
          padding: 0;
          border-radius: 0;
          box-shadow: none;
        }
      }
    }
  }

  .menu-wrapper {
    margin-top: 32px;
  }

  .banner-container {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
  }

  .food-types {
    padding-top: 5px;
    @extend %txt-body-xs;
    color: $text-color-gray;
  }

  .open-hours-today {
    display: flex;
    >p {
      font-size: 18px;
      margin-bottom: 16px;
      @include sm-down {
        margin-bottom: 8px;
      }
    }
  }

  .cuisine-pills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 8px;
    margin: 16px 0;
  }

  .rx-details {
    margin-top: 16px;

    .description {
      max-width: 100%;
      overflow: hidden;
      @extend %txt-body-sm;
    }

    .address {
      padding: 5px 0px;
      color: $text-color-gray;
    }
  }

  .popular-title { padding: 10px 0px; }

  .see-full-menu {
    text-align: center;
    @extend %txt-body-md;
    margin: 8px 0;
  }

  .rx-photo-title { padding: 20px 0px; }

  .rx-photos {
    margin: 0px -$rx-photo-padding 0px -$rx-photo-padding;
    .rx-photo {
      padding: $rx-photo-padding;

      img {
        max-width: 100%;
        height: auto;
        @extend %border-radius-lg;
      }
    }
  }

  .order-buttons {
    display: inline-block;
    display: flex;
    align-items: center;
    @extend %txt-body-sm;
    background-color: white;
    padding: 6px 10px;
    cursor: pointer;
    @extend %border-radius-full;
    border: 1px solid $light-gray;
  }

  .order-buttons:hover { background-color: $light-gray; }

  .individual-container { padding-right: 10px; }

  .button-container {
    display: flex;
    direction: row;
    justify-content: flex-start;
    padding: 20px 0px;
  }

  .button-icon { padding-right: 10px; }

  .rx-hot-spots {
    margin-top: 40px;

    .title-section {
      margin-bottom: 20px;

      .icon, h2 {
        display: inline-block;
        vertical-align: middle;
      }

      .icon { margin: 0px; }
      h2 { margin-left: 10px; }
    }
  }

  .title-info.add-padding {
    @include sm-down {
      margin-left: 21px;
    }
  }

  .open-closed-dot {
    border-radius: 50%;
    display: inline-block;
    height: 13px;
    width: 13px;
    margin-right: 8px;
    vertical-align: top;
    margin-top: 6px;
    @include sm-down {
      margin-top: 2px;
    }

    &.almost-closed {
      background-color: #F76600;
    }

    &.closed {
      background-color: #C12700;
    }

    &.open {
      background-color: #71AB24;
    }
  }

  .mobile-bottom-sticky {
    z-index: 8;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 19px 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 24px 24px 0px 0px;
  }

  .desktop-sticky-order-nav {
    $nav-height: 88px; // WARNING: don't change this without updating rootMargin arg to `isSticky.ts:stickyWatcher`
    position: fixed;
    top: -$nav-height;  // hide above viewport until .slide-down is applied.
    transition: top 0.3s, box-shadow 0.3s;
    left: 0;
    right: 0;
    height: $nav-height;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px  rgba(0, 0, 0, 0.0);  // fully transparent until .slide-down is applied.
    z-index: 11; // one more than .navbar
    padding: 0 80px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & h1 {
      font-size: 24px;
      line-height: 29px;
      @include txt-md;

      @include md-down {
        font-size: 18px;
      }
    }

    & .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 40px;
    }

    & .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 530px;

      & .review-summary {
      font-size: 18px;
      line-height: 22px;
      margin-right: 24px;
      white-space: nowrap;

        &>img {
          margin-right: 10px;
        }
      }
    }
    &.slide-down {
      top: 0;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }
  }
  .divider {
    margin: 16px 0;
  }

  & .go-to-gallery-button {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 16px;
    padding: 8px 16px;
    @include txt-md;
    font-size: 16px;
    line-height: 25px;
    color: $text-color;
    background: #FFFFFF;
    border-radius: 24px;
    &:hover {
      text-decoration: none;
    }
    &:visited,:active {
      color: $text-color;
    }
    &>img {
      margin-right: 10px;
      margin-bottom: -3px;  // visually center the icon
      @include sm-down {
        margin-right: 4px;
        margin-left: 4px;
      }
    }
  }
}
