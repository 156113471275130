.modalContentV2 {
  .codeForm {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;

    .resendCodeButton {
      border: 1px solid #CDCED7;
      border-radius: 8px;
      background: #FFFFFF;
      padding: 12px 24px;
      color: #1E1F24;
      font-size: 16px;
      font-weight: 500;
      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
      &:active {
        background: rgba(0, 0, 0, 0.08);
      }
    }

    .formError {
      font-size: 16px;
    }

    .loadingSpinnerOverlay {
      border-radius: 10px;
    }
  }
}

.inputButton {
  align-self: flex-end;
  height: 36px;
  padding-left: 10px;
  color: $text-color-disabled;
  font-weight: bold;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;

  &.active {
    cursor: pointer;
    color: $ds-text-action;
  }
}
