.reservation {
  width: 100%;
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  .backgroundImage {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cardSection {
    max-width: 500px;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    color: #252525;
    border-radius: 24px;
    margin: 120px 80px;
    padding: 24px;
    box-shadow: 0px 0px 6px var(--ds-surface-shadow, rgba(0, 0, 0, 0.15));

    &.isModal {
      box-shadow: none;
    }

    @include sm-down {
      margin: 16px 0;
      width: calc(100% - 48px);
      min-width: calc(100% - 48px);
    }

    .closeButton {
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: #fff;
      @include sm-down {
        top: 12px;
      }
    }

    h3.titleSection, .titleSection h3 {
      @include txt-title-lg;
      margin-bottom: 24px;
      display: flex;
      justify-content: center;

      @include sm-down {
        @include txt-title-md;
        margin-bottom: 16px;
      }
    }

    .formSection {
      display: flex;
      margin-bottom: 24px;
      justify-content: space-between;
      flex-direction: column;

        .locationSection, .guestSection, .dateSection, .timeSection {
          padding-bottom: 16px;
        }

        .dateTimeSection {
          display: flex;
          flex-direction: row;
          gap: 8px;

          .dateSection, .timeSection {
            width: calc(50% - 4px);
          }
        }

        .findTableButton {
          padding-top: 16px;
        }
    }

    .sectionTitle {
      padding-bottom: 4px;
      @include txt-body-md-bold;
    }

    .seatingLocationWrapper {
      width: 100%;
      padding-bottom: 24px;

      &.withoutBorder {
        padding-bottom: 0px;
      }
    }

    .timeOptions {
      padding: 16px 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .timePillContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .timePill {
          padding: 10px;
          background-color: $text-color-transparent-25;
          border-radius: 24px;
          @include txt-body-sm-bold;
          border: none;
          text-decoration: none;
          cursor: pointer;

          &.selected, &:hover {
            background-color: $ds-background-primary;
            color: white;
          }
        }

        button {
          background-color: $gray-30;
        }

        span {
          color: $gray-100;
          font-size: 12px;
        }
      }
    }

    .description {
      padding-bottom: 32px;
    }

    .findTableButton {
      align-self: flex-end;
      display: flex;
      align-items: center;

      button {
        width: 100%;
        margin-left: 32px;
      }

      .changeSelection {
        margin-left: 8px;
      }
    }

    .toastLogo {
      margin-top: 16px;
      svg {
        fill: #252525;
      }
    }
  }
}
