.cartModal {
  margin-left: auto;

  height: 100vh; // fallback in case dvh not supported
  height: 100dvh;
  width: 500px;
  z-index: 8; // most modals use 9, lower it here so item modals are sure to appear above the cart

  @include sm-down {
    width: 100%;
  }

  .wrapper {
    padding: 0;

    .content {
      height: 100%;
      width: 100%;
      border-radius: 0;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 12px 12px 24px;
        border-bottom: 0.75px solid $light-gray;
        font-size: 20px;
        margin-bottom: 16px;
        font-weight: 700;
      }

      .cart {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        background-color: white;
        flex: 1;
        min-height: 0;

        .body {
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          .paddedContent {
            overflow-y: auto;
            padding: 24px;
          }
        }

        .footer {
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding: 24px;
          border-top: 0.75px solid $gray-30;
        }
      }
    }
  }
}

.cartData {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  @include sm-height-down {
    min-height: 0px;
  }

  .items {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    padding: 8px 0;
    @include hide-scrollbar;

    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;
      li:not(:last-child) {
        border-bottom: 0.75px solid $gray-30;
      }
    }

    .emptyCart {
      padding: 36px 0;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #818181;
    }

    .item {
      padding: 24px 4px;
      display: table;
      width: 100%;

      .quantity {
        display: table-cell;
        padding-right: 16px;
        line-height: 21px;
        white-space: nowrap;
        width: 0.1%;
      }

      .details {
        width: 100%;

        .name {
          line-height: 21px;
          font-size: 16px;
          font-weight: 400;
          font-family: inherit;
        }

        .itemActions {
          display: flex;
          gap: 24px;

          .itemAction {
            margin: 8px 0;
            padding: 0px;
            border: none;
            background-color: transparent;
            color: $link-blue;
            font-weight: 500;

            &:hover {
              color: var(--tsw-primary-hover-color);
              cursor: pointer;
            }

            &:focus-visible {
              outline-offset: 2px;
            }
          }
        }
      }

      .fractional {
        padding: 4px 0;
        font-weight: 500;
      }

      .mods {
        color: $text-color-gray;
        font-size: 12px;

        .mod {
          padding: 2px 0;
          line-height: 14px;
          display: flex;
          align-items: center;
          
          .quantity {
            font-weight: 600;
            padding-right: 0;
            width: 21px;
          }
        }
      }

      .rightColumn {
        display: table-cell;
        text-align: end;
      }

      .price {
        font-size: 16px;
        line-height: 21px;
        min-width: 85px;
        text-align: right;
      }
    }
  }

  .actions {
    display: table-cell;
    column-span: 2;
  }
}

.row {
  display: table-row;
  justify-content: space-between;

  .cell {
    display: table-cell;
  }

  .emptyCell {
    display: table-cell;
    column-span: 1;
  }
  &.loading {
    display: block;
  }
}

.cart-flex-row {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.prices {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  font-size: 14px;
  .row {
    line-height: 1.3rem;
  }
}

.checkoutButton {
  @extend %txt-title-sm;
  border-radius: 32px;
  background-color: $ds-background-primary;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  color: $ds-text-on-bg-primary;
  padding: 8px 24px;

  &:hover {
    background-color: $ds-background-primary-focus;
    cursor: pointer;
  }
}

.targetAction {
  background: transparent;
}

.checkoutButtonAction {
  &:focus-visible {
    outline-offset: 2px;
    outline-style: auto;
    outline-width: 2px;
    -moz-outline-radius: 2px;
  }
}

.discountedPrice {
  text-decoration: line-through;
  color: gray;
}
