.giftCardPurchasePage {
  background-color: $ds-surface-default;
  min-height: 90vh;
  display: flex;

  @include md-down {
    flex-direction: column;
  }

  .form {
    padding: 48px 24px;
    background: white;
    flex: 1;
    min-width: 50%;
    color: black;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @include lg {
      box-shadow: 12px 0 19px 1px var(--ds-surface-shadow, black);
    }
  }

  .card {
    padding: 48px 24px;
    flex: 1;
    min-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
