.responsiveNav {
  text-align: right;
  white-space: nowrap;

  .viewItem {
    display: inline-block;
    text-align: left;
  }
}

.hiddenNavItems {
  display: flex;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  // can't add display: none so use visibility: hidden so items
  // don't show up in the tab order
  visibility: hidden;
}

.navWrapper,
.mobileNavOverlay {
  button.button.link {
    color: var(--ds-text-default, var(--tsw-primary-text-color));
  }

  .overlay {
    background-color: $ds-surface-overlay;
  }

  .navLinks {
    align-items: center;
    padding: 0 24px 0 0;
  }

  .userNav {
    display: flex;
    cursor: pointer;
    padding-left: 0;
    background-color: transparent;
    border-width: 0;

    .dropDown {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .navLinks,
  .userNav {
    .dropDownContent {
      max-height: none;
    }

    .dropDownContent .button,
    .dropDownContent .link,
    .button {
      border-radius: inherit;
      padding: 10px 20px;
      width: 100%;
    }

    .dropDownContent .button,
    .dropDownContent .link {
      @extend %themed-default-colors;
    }

    .link {
      padding: 0 15px;
      @extend %txt-body-md;
      display: flex;
      font-weight: 400;
      text-decoration: none;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
      }
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.navWrapper {
  $nav-transition-time: 1s;

  @include padded-section-full-width;
  background-color: $ds-surface-default;
  color: $link-color;
  transition: background-color $nav-transition-time;

  &.withShadow {
    filter: drop-shadow(0 4px 4px rgb(0, 0, 0, 0.1));
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
    }
  }

  .nav {
    max-height: 80px;
    display: flex;
    justify-content: space-between;

    >.left,
    >.right {
      flex-grow: 0;
      display: flex;
      gap: 16px;

      @include xs {
        align-items: center;
      }
    }

    >.right {
      align-items: center;
    }

    >.middle,
    >.right {
      display: flex;
      justify-content: center;
    }

    >.middle {
      flex-grow: 1;
    }

    [nav-role='primary-cta'] {
      display: flex;
      flex-direction: row;
      align-items: center;

      >*:not(:first-child) {
        padding-left: 8px;
      }
    }

    [nav-role='links'] {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;

      >.navLinks {
        display: flex;
        justify-content: flex-end;
        flex: 1;
      }
    }

    .logoTitle {

      .logo,
      .name {
        vertical-align: middle;
      }

      h1 {
        color: $ds-background-primary;
        @extend %line-clamp-1;
        padding-top: 7px;
        font-size: 20px;

        &.smaller {
          @media screen and (max-width: 35.5em) {
            font-size: 18px;
            padding-right: 4px;
            padding-top: 8px;
          }
        }
      }

      $overflow-circle-padding: 30px;

      .logoWrapper {
        position: relative;
        transition: background-color $nav-transition-time, margin-top $nav-transition-time, padding $nav-transition-time, margin-left $nav-transition-time;
        margin-left: 0px;
        overflow: hidden;
        line-height: 0;

        @include md {
          &.overflow-circle {
            transition: background-color $nav-transition-time, margin-top $nav-transition-time, padding $nav-transition-time, margin-left $nav-transition-time;
            background: transparent;
            padding: 0 $overflow-circle-padding $overflow-circle-padding $overflow-circle-padding;
            margin-left: -$overflow-circle-padding;
            border-bottom-right-radius: calc(50% + $overflow-circle-padding);
            border-bottom-left-radius: calc(50% + $overflow-circle-padding);

            & .logo {
              max-height: 100px;
              padding-right: 0;
              position: relative;
              z-index: 2;
            }

            .backgroundCircle {
              z-index: 1;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: calc(100% - 80px + 12px); // 100% - header height + nav padding

              @include md-down {
                height: calc(100% - 80px + 8px);
              }

              transition: background-color $nav-transition-time;
            }
          }
        }

        .logo {
          max-height: 50px;
          max-width: $max-logo-width;
          width: auto;

          @include xs {
            max-width: 100%;
          }

          transition: max-height $nav-transition-time,
          padding-right $nav-transition-time;
        }

        .hiddenHeader {
          display: none
        }
      }
    }

    .name {
      @include txt-body-md;

      @include sm-down {
        @include txt-title-md-bold;
      }
    }

    a:hover {
      text-decoration: none;
    }

    /* Nav Layout styles */
    &.header-layout-1 {
      .middle {
        justify-content: flex-end;
        flex-grow: 1;
      }
    }

    &.header-layout-2 {
      .middle {
        justify-content: center;
        flex-grow: 1;

        >[nav-role='links'] {
          justify-content: center;

          >.navLinks {
            justify-content: center;
          }
        }
      }

      .left,
      .right {
        flex-grow: 0;
      }
    }


    &.header-layout-4 {
      .left {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 1;

        [nav-role='links'] {
          .navLinks {
            justify-content: flex-start;
          }
        }
      }

      .right {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 1;
        justify-content: flex-end;
      }

      .middle {
        flex-grow: 1;
        align-self: flex-start;

        .logo {
          padding: 0;
        }
      }
    }

    &.header-layout-5 {
      .left {
        flex-basis: 50%;
        flex-grow: 0;
      }

      .middle {
        flex-grow: 1;
        align-self: flex-start;

        .logo {
          padding: 0;
        }
      }

      .right {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-basis: 50%;
        flex-grow: 0;

        >* {
          display: flex;
        }

        >[nav-role='primary-cta'] {
          display: flex;
          flex-direction: row;
          align-items: center;

          >*:not(:first-child) {
            padding-left: 8px;
          }
        }

        >[nav-role='links'] {
          flex-grow: 1;

          >.navLinks {
            flex: 1;
          }
        }
      }
    }

    &.header-layout-6 {
      .left {
        flex: 1;
      }

      .middle {
        flex: 1;

        .logo {
          padding: 0;
        }
      }

      .right {
        flex: 1;
      }
    }

    &.header-layout-7 {
      .left {
        flex: 1;
      }

      .middle {
        flex: 1;

        .logo {
          padding: 0;
        }
      }

      .right {
        flex: 1;
        justify-content: flex-end;
      }
    }

    &.header-layout-8 {
      max-height: 120px;

      .left,
      .right {
        flex-basis: 10%;
        flex-grow: 0;
      }

      .middle {
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        flex-basis: auto;
        align-self: flex-start;

        [nav-role='links'] {
          width: 100%;

          .navLinks {
            justify-content: center;
          }
        }

        .logoWrapper {
          @include md {
            & .logo {
              max-height: 50px;
              max-width: 220px;
              width: auto;
              transition: max-height $nav-transition-time, padding-right $nav-transition-time;
            }

            .backgroundCircle {
              display: none;
            }

            &.overflow-circle {
              padding: 0;
              margin: 0;
              border-radius: 0;
            }
          }

          .logo {
            max-height: 50px;
            max-width: 220px;
            width: auto;
            transition: max-height $nav-transition-time, padding-right $nav-transition-time;
          }
        }
      }

      .right {
        [nav-role='primary-cta'] {
          justify-content: flex-end;
        }
      }
    }
  }

  .behaviorToggle {
    position: relative;

    svg {
      height: 40px;
    }
  }

  .hamburgerWrapper {
    align-items: center;
    display: flex;
  }

  .hamburger {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    fill: $ds-icon-default;
    align-self: center;
    height: 20px
  }
}

$mobile-overlay-padding: 10px;

.mobileNavOverlay {
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: hidden;
  top: $mobile-overlay-padding;
  left: $mobile-overlay-padding;
  right: $mobile-overlay-padding;
  z-index: 10;
  border-radius: 6px;

  .overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
  }

  .content {
    display: flex;
    flex-direction: column;
    z-index: 10;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 2 * $mobile-overlay-padding);

    @include md {
      max-width: 400px;
    }

    .header {
      display: flex;
      justify-content: flex-end;
      padding: 12px 12px 0 0;

      @include md {
        justify-content: flex-end;
        border: none;
      }
    }

    .body {
      overflow-y: auto;
    }

    .body,
    .footer {
      .navLinks {
        display: flex;
        flex-wrap: wrap;
        border: none;
        padding: 0;
        font-weight: 400;
        @extend %txt-title-md;

        .mobileLink,
        .expandableSectionLabel {
          @extend %txt-title-md;
          font-weight: 400;
          text-align: left;
          display: block;
          cursor: pointer;
          padding: 18px 24px;
          flex: 1 1 100%;

          &:hover {
            background-color: $ds-surface-focus;
          }
        }

        .expandableSectionContent {
          .icon {
            padding-right: 10px;
          }
        }
      }
    }

    .footer {
      padding: 8px 0;
      align-items: center;
      text-align: center;

      .cta {
        margin: 0 24px;

        button {
          width: 100%;
        }
      }

      hr {
        margin: 24px 24px 0 24px;
      }
    }
  }
}

.navToastContainer {
  z-index: 9;

  @include sm-down {
    padding: 0 20% 0 20%;
    margin-bottom: 40px;
  }

  .Toastify__toast {
    align-items: center;
    background-color: #252525;
    color: #FFFFFF;
    border-radius: 8px;
    font-family: var(--tsw-font-family);
    min-height: fit-content;
    font-size: 14px;
  }
}