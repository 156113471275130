.formInputContainer {
    .inputFieldInner {
        position: relative;
        border: 1px solid #CECECE;
        border-radius: 8px;
        display: flex;
        height: 56px;
        margin-bottom: 8px;
    
        label {
            display: flex;
            justify-content: center;
            flex-direction: column-reverse;
            transform: none;
            flex-grow: 1;
    
            span {
                transition: all .2s;
                transform: translateY(100%);
                transform-origin: top left;
                color: #646464;
                flex: 1;
                line-height: 18px;
            }
    
            input,
            textarea {
                height: 100%;
                padding: 0;
                border: none;
                box-shadow: 0 0 0 30px #fff inset;
                flex: 2;
    
                &:focus,
                &:not(:placeholder-shown) {
                    ~ span {
                        transform: translateY(40%) scale(0.8);
                        pointer-events: none;
                    }
                }
    
                &::-internal-autofill-selected {
                    background-color: inherit;
                }
            }
        }
    }
    
    .inputMessage {
        margin-bottom: 8px;
        font-size: 12px;
      
        .errorMessage {
          color: $error-red;
          background-color: #FFCDC7;
          padding: 6px 12px;
          border-radius: 6px;
          
          &::first-letter {
              text-transform: capitalize;
          }
        }
    }

    &.inputDisabled {
        opacity: 40%;
    }
}

.inputError.formInputContainer {
    > div {
        border-color: #DA342E;
    }
}