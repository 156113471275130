.phoneInput {
  display: flex;
  align-items: center;

  .prefix {
    position: absolute;
    padding: 0 4px;
  }
  
  .textBox {
    border-bottom: 1px solid $black-12;
    flex: 1;
  
    &.phone {
      padding: 0 26px;
    }
  
    &:focus {
      border-bottom-color: var(--tsw-primary-color);
    }

    &:hover {
      border-bottom-color: var(--tsw-primary-hover-color);
    }
  }
}
