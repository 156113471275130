$green: #2BCA86;
$yellow: #FFE388;
$red: #F35345;
$error-red: #C42B44;
$blue: #678FE6;
$primary-blue: #2B4FB9;
$hard-blue: #4977DE;
$link-blue: #2B4FB9;
$extra-light-blue: #F5F7FB;

// User colors
// TODO add default for all colors
$ds-surface-default: var(--ds-surface-default, var(--tsw-background-color));
$ds-surface-secondary: var(--ds-surface-secondary, var(--tsw-background-color));
$ds-surface-tertiary: var(--ds-surface-tertiary, var(--tsw-primary-color));
$ds-surface-focus: var(--ds-surface-focus, var(--tsw-primary-color-50));
$ds-surface-shadow: var(--ds-surface-shadow, var(--tsw-primary-color));
$ds-surface-overlay: var(--ds-surface-overlay, var(--tsw-background-contrasting-color-50));
$ds-surface-inverse: var(--ds-surface-inverse, var(--tsw-background-contrasting-color-50));

$ds-text-default: var(--ds-text-default, var(--tsw-primary-text-color));
$ds-text-secondary: var(--ds-text-secondary, var(--tsw-secondary-text-color));
$ds-text-action: var(--ds-text-action, var(--tsw-primary-hover-color));
$ds-text-on-overlay: var(--ds-text-on-overlay, var(--tsw-primary-contrasting-color));
$ds-text-on-bg-primary: var(--ds-text-on-bg-primary, var(--tsw-primary-contrasting-color));
$ds-text-on-bg-primary-subtle: var(--ds-text-on-bg-primary-subtle);
$ds-text-on-bg-secondary: var(--ds-text-on-bg-secondary);
$ds-text-on-bg-secondary-subtle: var(--ds-text-on-bg-secondary-subtle);
$ds-text-on-error-primary: var(--ds-text-on-error-primary);
$ds-text-on-error-secondary: var(--ds-text-on-error-secondary);

$ds-icon-default: var(--ds-icon-default, var(--tsw-primary-text-color));
$ds-icon-secondary: var(--ds-icon-secondary, var(--tsw-secondary-text-color));
$ds-icon-action: var(--ds-icon-action, var(--tsw-primary-color));
$ds-icon-on-overlay: var(--ds-icon-on-overlay, var(--tsw-primary-contrasting-color));
$ds-icon-on-bg-primary: var(--ds-icon-on-bg-primary, var(--tsw-primary-contrasting-color));
$ds-icon-on-bg-primary-subtle: var(--ds-icon-on-bg-primary-subtle);
$ds-icon-on-bg-secondary: var(--ds-icon-on-bg-secondary, var(--tsw-primary-contrasting-color));
$ds-icon-on-bg-secondary-subtle: var(--ds-icon-on-bg-secondary-subtle);
$ds-icon-on-error-primary: var(--ds-icon-on-error-primary);
$ds-icon-on-error-secondary: var(--ds-icon-on-error-secondary);

$ds-background-primary: var(--ds-background-primary, var(--tsw-primary-color));
$ds-background-primary-overlay: var(--ds-background-primary-overlay);
$ds-background-primary-focus: var(--ds-background-primary-focus, var(--tsw-primary-hover-color));
$ds-background-primary-subtle: var(--ds-background-primary-subtle);
$ds-background-primary-subtle-focus: var(--ds-background-primary-subtle-focus);
$ds-background-secondary: var(--ds-background-secondary);
$ds-background-secondary-overlay: var(--ds-background-secondary-overlay);
$ds-background-secondary-focus: var(--ds-background-secondary-focus);
$ds-background-secondary-subtle: var(--ds-background-secondary-subtle);
$ds-background-secondary-subtle-focus: var(--ds-background-secondary-subtle-focus);

$ds-border-default: var(--ds-border-default, var(--tsw-primary-color));
$ds-border-focus: var(--ds-border-focus, var(--tsw-background-contrasting-color));
$ds-border-with-primary: var(--ds-border-with-primary);
$ds-border-with-primary-subtle: var(--ds-border-with-primary-subtle);
$ds-border-with-secondary: var(--ds-border-with-secondary);
$ds-border-with-secondary-subtle: var(--ds-border-with-secondary-subtle);

// Color overrides
$ds-override-nav-text: var(--ds-override-nav-text);
$ds-override-nav-background: var(--ds-override-nav-background);
$ds-override-view-only-menu-item-title: var(--ds-override-view-only-menu-item-title);
$ds-override-view-only-menu-item-description: var(--ds-override-view-only-menu-item-description);
$ds-override-view-only-menu-card-background: var(--ds-override-view-only-menu-card-background);
$ds-override-oo-menu-item-title: var(--ds-override-oo-menu-item-title);
$ds-override-oo-menu-item-description: var(--ds-override-oo-menu-item-description);
$ds-override-oo-menu-card-background: var(--ds-override-oo-menu-card-background);
$ds-override-footer-background: var(--ds-override-footer-background);
$ds-override-footer-text: var(--ds-override-footer-text);


$text-color-transparent-25: var(--tsw-primary-text-color-25);
$primary-color-transparent-25: var(--tsw-primary-color-25);

$primary-contrasting-color: var(--tsw-primary-contrasting-color);

$white-contrasting-color: var(--tsw-white-contrasting-color);
$white-contrasting-color-75: var(--ds-text-secondary, var(--tsw-white-contrasting-color-75));
$white-contrasting-color-50: var(--ds-text-secondary, var(--tsw-white-contrasting-color-50));

$text-color: var(--tsw-primary-text-color);
$text-color-gray: #666666;
$text-color-disabled: #7A7A7A;
$text-color-hint: #7A7A7A;
$link-color: var(--ds-text-action, var(--tsw-link-color));

$brand-orange: #FF4C00;
$brand-orange-hover: #DB3B11;

$extra-light-gray: #F0F0F0;
$light-gray: #E5E5E5;
$medium-gray: #C0C0C0;
$dark-gray: #6E6E6E;
$darker-gray: #404040;
$extra-dark-gray: #212121;

$black-4: rgb(0, 0, 0, .04);
$black-12: rgb(0, 0, 0, .12);
$black-56: rgb(0, 0, 0, .56);
$black-87: rgb(0, 0, 0, .87);

$primary-blue-0: #E7EAF6;
$primary-blue-25: #9AA6DB;
$primary-blue-50: #5069C3;
$primary-blue-75: #2B4FB9;
$primary-blue-100: #002182;

$gray-0: #F7F7F7;
$gray-0-alpha: #F7F7F7EE;
$gray-25: #F0F0F0;
$gray-30: #E5E5E5;
$gray-50: #C0C0C0;
$gray-75: #7A7A7A;
$gray-100: #666666;
$gray-110: #474747;
$gray-125: #252525;

$open-green: #71AB24;

$error-75: #D40023;
$error-alert-bg: #ff002a94;
$info-alert-bg: #0050ff47;

$brand-0: #FCE8E5;

.text-color {
  color: $ds-text-default;
}

.text-color-gray {
  color: $text-color-gray;
}

%themed-default-colors {
  color: var(--ds-text-default, $gray-125);
  background-color: var(--ds-surface-default, white);

  > a, > p {
    color: var(--ds-text-default, $gray-125);
    background-color: var(--ds-surface-default, white);
  }
}

%theme-menu-card-default-colors {
  color: var(--ds-text-default, $gray-125);
  background-color: var(--ds-surface-secondary, white);

  > a, > p {
    color: var(--ds-text-default, $gray-125);
    background-color: var(--ds-surface-secondary, white);
  }
}


%default-colors {
  color: $gray-125;
  background-color: white;

  > a, > p {
    color: $gray-125;
    background-color: white;
  }
}
