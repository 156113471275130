.checkoutErrorModalContent {
  padding: 24px;
  max-width: 540px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 20px;
      line-height: 20px;
      font-weight: 400;
    }

    .closeButton {
      margin: 0;
      padding: 8px;
    }
  }

  .body {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .buttonContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      > * {
        min-width: 200px;
        display: flex;
        justify-content: center;
        font-size: 16px;
        padding: 0;

        &.proceed {
          background: $ds-background-primary;
          color: $ds-text-on-bg-primary;
          display: flex;
          align-items: center;
          &:hover {
            background: $ds-background-primary-focus
          }
        }
        &.cancel {
          border: none;
          background: none;
          color: $ds-text-on-error-secondary;
        }
      }
    }
  }
}
