.carouselArrows {
  display: inline-flex;
  float: right;
  justify-content: flex-end;
  padding-top: 40px;

  @include sm-down {
    justify-content: center;
  }

  &.whiteBorder .carouselArrow.inactive {
    border: 1px solid white;
  }

  .itemRange {
    align-self: center;
    padding: 0 18px;
    font-size: 16px;
    font-weight: bold;

    .itemTotal {
      font-weight: normal;
    }
  }

  &.large {
    .carouselArrow {
      height: 75px;
      width: 75px;

      svg {
        height: 25px;

        path {
          stroke-width: 1;
        }
      }
    }

    .itemRange {
      padding: 0 50px;

      @include sm-down {
        padding: 0 20px;
      }
    }
  }
}

.carouselArrow {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: $ds-background-primary;
  border: 1px solid $ds-border-default;
  border-radius: 50%;

  &.right {
    transform: rotate(180deg);
  }

  &.inactive {
    cursor: auto;
    opacity: 0.4;
  }

  svg {
    flex: 1;
  }
}
