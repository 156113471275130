.reorderItems {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 24px;
  line-height: normal;

  .reorderItemsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header {
      font-size: 20px;
    }

    .arrows {
      display: flex;
      justify-content: flex-end;
      gap: 12px;

      .disabledArrow {
        opacity: 0.4;
      }

      .arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 32px;
        width: 32px;
        border: 1px solid var(--ds-border-default, #0000001F);
        border-radius: 50%;
        background-color: var(--ds-background-primary, #FFFFFF);

        &.right {
          transform: rotate(180deg);
        }

        &.disabled {
          cursor: auto;
        }

        svg {
          height: 12px;
          width: 8px;
        }
      }
    }
  }

  .reorderItemCards {
    // Some trickery to make it look like the drop shadow extends past the container
    width: calc(100% + 48px);
    padding: 24px;
    margin: 0 -24px;
  }
}

.loadingSpinnerOverlay {
  border-radius: 10px;
}
