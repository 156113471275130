.cartOrder {
  flex: 1;
  padding-bottom: 10px;
  &.expandableSection {
    .cartData {
      min-height: unset;
      .items {
        padding: 0;
      }
    }
  }

  .cartData {
    max-height: none;

    .items {
      padding: 16px 0;

      ul {
        list-style: none;
      }

      .item {
        padding: 5px 0;
        border-top: none;
      }

      .item .details h3, .price {
        font-size: 16px;
        font-weight: 400;
      }

      .mod {
        display: inline-block;
        margin-right: 3px;
      }

      .mod::after {
        content: ", ";
      }

      .mod:last-child::after {
        content: "";
      }
    }
  }

  .prices {
    padding: 4px 0;
    line-height: 21px;
    box-shadow: none;
  }
}

.addItemsButton {
  text-align: center;
  padding: 8px;
  span {
    padding-left: 8px;
    color: $gray-125
  }
}
