.inputField {
    &:not(.inputFieldOtp) .inputFieldInner {
        margin-bottom: 12px;
        padding-left: 16px;
        padding-right: 16px;

        label {
            flex-grow: 1;
        }
    }

    .autocomplete {
        height: 100%;
        align-items: center;

        input {
            padding: 0;
        }

        .dropdown {
            width: calc(100% + 32px);
            max-height: 340px;
            left: -16px;
            right: auto;
        }
    }

    .inputButton {
        all: unset;
        height: 100%;
        color: rgba(0, 0, 0, .12);
        font-weight: bold;
        transition: all .2s ease;
        margin-right: -8px;
        align-self: center;
    }
}