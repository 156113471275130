.profileInformation {
    .infoWrapper {
        padding-top: 16px;

      .emailInputWrapper {
        border: 1px solid #CECECE;
        border-radius: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        height: 56px;
        margin-bottom: 8px;
        padding-top: 10px;

        .emailInputToVerify {
          width: 100%;

          .inputFieldInner {
            border: none !important;
            box-sizing: border-box;
            flex-grow: 1;
          }
        }

        .verifyCurrentEmail {
          max-width: fit-content;
          color: $primary-blue;
          flex-shrink: 0;
          font-weight: 600;
          border: none; 
          background: none;
          font-size: 14px;
          margin-right: 10px;
        }

        .verifyCurrentEmail:hover {
          color: $primary-blue-100;
          font-weight: 600;
        }
      }
        .submitPhoneButton {
          display: flex;
          justify-content: center;
          background: #252525;
          border: none;
          border-radius: 8px;
          padding: 12px 18px;
          margin: 32px 0px;
          float: right;

          @include sm-down {
            width: 100%;
          }
          &:disabled {
            background: rgba(0, 0, 0, 0.25);
            cursor: default;
          }
          .text {
            color: #FFFFFF;
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: 16px;
            font-weight: 600;

          }
        }

    }
    input[type=text] {
        &:read-only {
            color: $medium-gray;
        }
    }

    .sectionSeparatorWrapper{
      background-color: white !important;

      .sectionSeparator {
        border: 1px solid $gray-50 !important;
        margin-bottom: 12px;
        width: 100%;
        padding: 0px !important;
      }
    }

}
