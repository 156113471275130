.profileAddresses {
  .addressItemsWrapper {
    display: grid;
    grid-gap: 16px;
    margin-top: 16px;

    .addressItem {
      display: grid;
      grid-template-areas:
            "label   label   actions"
            "address address actions";
      grid-gap: 8px;

      .addressLine {
        grid-area: address;
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        grid-gap: 0.5ch;

        .addressDelimiter {
          display: unset;
        }

        @include sm-down {
          grid-auto-flow: row;
          justify-content: unset;
          grid-gap: unset;

          .addressDelimiter {
            display: none;
          }
        }
      }
      .addressLabel {
        grid-area: label;
      }
      .addressActions {
        grid-area: actions;
        justify-self: end;
        align-self: center;

        .addressActionIcon {
          cursor: pointer;
          padding: 0 10px 0 20px;
        }

        .dropDown {
          .dropDownArrow {
            display: none;
          }

          .dropDownContent {
            width: auto;
            right: 5px;
            white-space: nowrap;
          }
        }
      }

      .addressLabel, .addressLine {
        color: $gray-125;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  .placeholderMsg {
    padding-top: 10px;
    color: $gray-75;
  }
}
