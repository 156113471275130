$footer-bg: var(--ds-override-footer-background, var(--ds-surface-tertiary, $gray-125));
$footer-text: var(--ds-override-footer-text, var(--ds-text-default, #FFFFFF));

.templateFooter {
  background-color: $footer-bg;
  color: $footer-text;
  padding: 36px 80px;

  @include md-down {
    padding: 16px 24px 72px;
  }

  @extend %txt-body-md;

  .name {
    @extend %txt-body-lg-bold;
  }

  .locations {
    display: flex;
    flex-wrap: wrap;

    .locationAddress {
      a {
        text-decoration: underline;
        color: inherit;
      }
    }
  }

  .poweredBy {
    display: flex;
    align-items: center;

    >a {
      margin-top: -1px;
      margin-left: 7px;
      display: flex;
    }
  }

  .legal-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    gap: 10px;

    .legal {
      display: contents;

      @include xs {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
      }

      a {
        text-decoration: underline;
        color: inherit;
      }

      li {
        list-style-type: none;
        color: inherit;
        display: contents;
      }

      button#ot-sdk-btn.ot-sdk-show-settings {
        background: none;
        border: none;
        color: inherit;
        font-size: 1em;
        padding: 0;
        text-decoration: underline;
        word-wrap: normal;
      }
    }
  }

  .socialIcons {
    display: flex;
    align-items: center;
    margin-top: 8px;

    >a {
      &:not(:first-child) {
        padding-left: 5px;
      }

      &:not(:last-child) {
        padding-right: 5px;
      }

      >img {
        height: 20px;
        width: 20px;
        object-fit: contain;
      }
    }
  }
}