.commission-disclaimer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  & .storefront-icon img {
    vertical-align: middle;
    margin: auto 0;
  }

  & p {
    font-size: 14px;
    line-height: 16px;
  }
}
