.baseShapeBlock {
  width: 100%;
  height: 100%;
}

.circleBlockWrapper {
  display: grid;
  width: 98%;
  height: 98%;
  top: 2px;
  left: 2px;
  position: relative;
}
