.pdfWrapper {
  position: relative;
  margin-top: 4px;
}

.pdfEditorOverlay {
  position: absolute;
  inset: 0 0 0 0;
}

.pdfCanvasWrapper {
  width: 100vw;
  height: calc(100vh - 38px);
  overflow: auto;
  text-align: center;
  margin-bottom: calc(38px/2);

  &.inEditor {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    overflow: hidden;
  }
}

.pdfNav {
  position: sticky;
  bottom: 10px;
  z-index: 100;
  margin: auto;
  margin-bottom: calc(38px/2);
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  font-size: 12px;
  color: white;

  .pdfToolbar {
    display: flex;
    flex-direction: row;
    padding: calc(4px/2) 4px;
    border-radius: 100px;
    background-color: #555;
    color: white;
    align-items: center;
  }

  .iconButton {
    width: calc(38px - 4px * 2);
    height: calc(38px - 4px * 2);
    padding: 5px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: rgba(255,255,255,0.3);
    margin-right: 6px;
    margin-left: 6px;
    color: white;
    &:hover {
      background-color: rgba(255,255,255,0.4);
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

  }
}