@mixin fill-center-child-image {
    position: relative;
    overflow: hidden;

    & > div > img {
      min-height: 100%;
      width: 100%;
      position: absolute;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }
}

.rx-header-photo {
    width: 100%;
    height: 300px;
    border-radius: 24px;
    background: $extra-light-gray;
    @include fill-center-child-image();

    @include md-down {
      height: 150px;
      border-radius: 0;
      margin-left: -50px;
      width: calc(100% + 100px);
      margin-top: 16px;
    }

    @include sm-down {
      margin-left: -30px;
      width: calc(100% + 60px);
    }

    & .image-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .map-container {
      display: inline-block;
      & img {
        min-width: auto;
      }

    }
}
