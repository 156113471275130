.debugPanelButton {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 15;
  width: 36px;
  margin: 12px;
  cursor: pointer;

  .logo {
    fill: #FFFFFF;
    background-color: $brand-orange;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 0 0 0 6px $brand-orange;
    &:hover {
      background-color: $brand-orange-hover;
      box-shadow: 0 0 0 6px $brand-orange-hover, 0 0 6px 12px $gray-25;
    }
  }
}

.debugPanelContainer {
  width: 400px;
  height: 100%;
  margin-left: auto;
  background-color: white;

  .wrapper {
    padding: 0;

    .content {
      height: 100%;
      width: 100%;
      border-radius: 0;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 20px;
        font-weight: bold;
        align-items: center;
        padding: 12px 12px 12px 24px;
        box-shadow: 2px 0 4px 2px $gray-25;

        .closeButton {
          margin: 0;
          padding: 4px;
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 48px;
        padding: 24px 24px 48px;
        overflow-y: auto;
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          li {
            font-size: 12px;
          }
        }

        .formContainer {
          .formHeader {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;

            .formTitle {
              font-size: 18px;
              font-weight: bold;
            }

            .expandButton {
              background: #FFFFFF;
              border-radius: 50%;
              height: 30px;
              width: 30px;

              &.rotatedUp {
                transform: rotate(270deg);
              }

              &.rotatedDown {
                transform: rotate(90deg);
              }

              &:hover {
                background: $gray-25;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.rjsf {
  .form {
    font-size: 16px;

    fieldSet {
      display: flex;
      flex-direction: column;
      gap: 24px;
      border: 1px solid $gray-75;
      padding: 18px 12px;

      .control-label {
        font-weight: 400;
      }

      legend {
        &#root__title {
          display: none;
        }
      }

      input, select {
        height: 24px;
        margin-top: unset;
        margin-bottom: unset;
        border: 1px solid $gray-50;
        border-radius: 4px;
        padding: 0 8px;
        font-size: 16px;
        &:not(input[type="checkbox"]) {
          box-shadow: 0 2px 4px 0 #e0e0e0, 0 0 6px 0 #00000005;
        }
      }

      .field-string, .field-boolean, .field-number {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .checkbox label {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
          input {
            transform: scale(1.5);
          }
        }
        &.field-number input {
          max-width: 100px;
        }
        &.field-string input[type="text"] {
          width: calc(100% - 16px);
        }
      }

      .field-object {
        // Make nested objects wrap down vertically
        fieldset .form-group {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  button[type="submit"] {
    @extend %txt-title-sm;
    background-color: $primary-blue;
    color: #FFFFFF;
    width: 100px;
    border: none;
    border-radius: 32px;
    height: 32px;
    margin-top: 24px;
    padding: 4px 20px;
    text-align: center;
    float: right;

    &:hover {
      cursor: pointer;
      background-color: $primary-blue-100;
    }
  }
}
