.expandableSectionLabel {
  cursor: pointer;

  .controlIcon {
    display: inline-block;
    margin-left: 10px;

    &.rotated {
      transform:rotate(90deg);
      transition: all 0.15s ease-in;
    }
  }

  .controlIcon:after {
    font-family: Tahoma !important;
    content: '\203a';
  }
}

.expandableSectionContent {
  width: 100%;
  font-size: 18px;
  line-height: 18px;

  &:hover {
    background-color: unset;
  }

  > * {
    display: flex;
    cursor: pointer;
    padding: 13px 36px;

    a:hover {
      text-decoration: none;
    }

    &:hover {
      background-color: $black-4;
    }
  }
}
