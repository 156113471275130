.groupContainer {
  background-color: var(--ds-surface-tertiary, var(--tsw-background-color));
  padding: 16px;
  border-radius: 24px;
  box-shadow: 0 0 3px var(--ds-surface-shadow, rgba(0, 0, 0, 0.12));
  height: 100%;
  @include txt-family;

  @mixin truncated-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: fit-content;
  }

  @mixin additional-item-layouts {

    // Different layouts can be configured in css:
    // left aligned, right aligned, text only, image tile, etc.
    &.rightAligned {
      grid-template-areas:
        "item_image item_name"
        "item_image item_descr"
        "item_image item_price";

      .itemDescription {
        margin-top: 4px;
      }
    }

    &.rightAlignedNoDescr {
      grid-template-areas:
        "item_image item_name"
        "item_image item_price"
        "item_image ..........";
    }

    &.rightAligned,
    &.rightAlignedNoDescr {
      grid-template-columns: 1fr 3fr;
      border-bottom: solid 1px var(--ds-border-default, #e0e0e0);

      .itemName {
        align-self: end;
      }

      .itemImage {
        justify-self: start;
        margin-right: 16px;
      }

      .itemPrice {
        margin-top: 12px;
      }
    }

    &.textOnly {
      grid-template-columns: max-content 1fr max-content;
      grid-template-rows: min-content;
      grid-template-areas:
        "item_name  item_delim item_price"
        "item_descr item_descr item_descr";
      margin: 10px 0;

      .itemImage {
        display: none;
      }

      .itemPrice {
        justify-self: end;
      }

      &.priceHidden>.itemDelimiter {
        display: none;
      }

      .itemDelimiter {
        display: block;
        align-self: center;
        border-bottom: 1px dotted var(--ds-border-default, #e0e0e0);
        $gap: 2%;
        width: calc(100% - $gap * 2);
        margin-left: $gap;
        height: 3px;
      }
    }
  }

  .groupWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    align-items: start;
    align-content: start;
    grid-gap: 30px;
    height: 100%;

    .groupDetails {
      display: grid;
      grid-template-areas:
        "group_name"
        "group_descr";
      padding: 0 12px;

      .groupName {
        grid-area: group_name;
        color: $ds-text-default;
        font-weight: 400;
        font-size: 22px;
        @include truncated-text;
        @include txt-title-family;
      }

      .groupDescription {
        grid-area: group_descr;
        color: $ds-text-secondary;
        @include truncated-text;
      }
    }

    .groupItems {
      display: grid;
      grid-template-columns: 1fr;

      overflow-y: auto;
      height: 100%;
      align-content: start;

      @include md-up {
        &.columns2 {
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 48px;
        }

        &.columns3 {
          grid-template-columns: 1fr 1fr 1fr;
          grid-column-gap: 48px;
        }
      }

      .itemWrapper {
        display: grid;
        padding: 16px 12px;
        align-items: center;

        &.leftAligned {
          grid-template-areas:
            "item_name   item_image"
            "item_descr  item_image"
            "item_price  item_image";

          .itemDescription {
            margin-top: 4px;
          }
        }

        &.leftAlignedNoDescr {
          grid-template-areas:
            "item_name   item_image"
            "item_price  item_image"
            "..........  item_image";
        }

        &.leftAligned,
        &.leftAlignedNoDescr {
          grid-template-columns: 3fr 1fr;
          border-bottom: solid 1px var(--ds-border-default, #e0e0e0);

          .itemName {
            align-self: end;
          }

          .itemPrice {
            margin-top: 12px;
          }

          .itemImage {
            margin-left: 16px;
          }
        }

        @include additional-item-layouts;

        &:hover {
          background-color: var(--ds-surface-focus, var(--tsw-primary-color-50));
        }

        .itemName {
          grid-area: item_name;
          color: $ds-text-default;
          @include truncated-text;
        }

        .itemDescription {
          grid-area: item_descr;
          color: $ds-text-secondary;
          line-height: 1.25;
          @include truncated-text;
        }

        .itemPrice {
          grid-area: item_price;
          color: $ds-text-default;
        }

        .itemImage {
          grid-area: item_image;
          justify-self: end;
          background: linear-gradient(9.94deg, rgba(0, 0, 0, 0.64) 1.13%, rgba(0, 0, 0, 0) 50%), linear-gradient(8.65deg, rgba(0, 0, 0, 0.64) -24.9%, rgba(0, 0, 0, 0) 76.2%), linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08));
          border-radius: 5px;
          width: 116px;
          height: 116px;

          .itemImageWrapper {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }

        .itemDelimiter {
          display: none;
        }
      }
    }
  }
}