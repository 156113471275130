
.noDriver {
  display: flex;
  flex-direction: row;

  .info {
    display: flex;
    flex: 1;
    flex-direction: column;
  }


  .icon {
    margin-right: 16px;
  }
}

.copy {
  color: #666666;
  margin-top: 8px;
  font-size: 14px;
}

.driverProfile {
  display: flex;
  flex-direction: row;
  align-items: center;

  .driverPhoto {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    margin-right: 8px;
  }
}

.driverName {
  font-size: 18px;
  font-weight: bold;
}

.providerName {
  font-size: 12px;
  color: #666666;
  margin-top: 8px;
  font-style: italic;
}

.driverInfoHeader {
  display: flex;
  flex-direction: row;
}

.driverPhone {
  margin-top: 8px;
  display: flex;
  border: none;
  padding: 0;
  background: none;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.copyToClipboardIcon {
  margin-left: 6px
}
