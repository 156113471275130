.title-card {
  width: 100%;
  display: block;
  margin: 8px 0;

  .title {
    padding: 8px 0;
    cursor: pointer;

    &::after {
      float: right;
      content: ">";
      font-size: 20px;
      color: $text-color;
    }
  }

  & .children {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.6s ease-out;
  }

  &.close {
      & .title::after {
        transform: rotate(90deg) scaleY(1.5);
        transition: transform 0.6s;
      }
  }

  &.open {
    & .title::after {
        transform: rotate(270deg) scaleY(1.5);
        transition: transform 0.6s;
    }
    & .children {
        max-height: 500px;
        transition: max-height 0.6s ease-in;
    }
  }
}
