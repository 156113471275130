.autocomplete {
  width: 100%;
  position: relative;
  display: flex;

  .input {
    width: 100%;
    padding: 2px 12px;
    position: relative;
  }

  .dropdown {
    background: #FFFFFF;
    border: solid 1px $light-gray;
    border-radius: 5px;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    max-height: 340px;

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  .prediction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    width: 100%;
    background: #FFFFFF;
    border: none;
    font-size: 16px;
    text-align: left;
    cursor: pointer;

    &:focus, &:hover {
      background: $extra-light-gray;
    }
  }

  .attribution {
    display: flex;
    align-items: center;
    padding: 16px 12px;
    width: 100%;
    background: #FFFFFF;
    color: $gray-75;
    font-size: 16px;
    text-align: left;
    border-top: 1px solid $black-12;
    img {
      height: 1rem;
      margin-left: 4px;
      margin-top: 2px;  // Align text of image with surrounding text
    }
  }

  .loader {
    height: 150px;
  }

  .arrow {
    height: 24px;
  }

  .currentLocation, .loadingSpinner {
    position: absolute;
    right: 13px;  // align with position in dropdown
    align-self: center;
    display: flex;
    background: linear-gradient(270deg, white 60%, rgba(255, 255, 255, 0.3));
    padding-left: 16px;
    &:hover {
      cursor: pointer;
    }
  }

}

.autocompleteContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.placesAutocompleteError {
  color: $error-red;
  font-size: 14px;
  margin: 8px;
  display: flex;
  flex-direction: column;
  padding-left: 4px;
  span {
    padding: 4px 0;
  }

  p {
    color: #666;
    font-size: 14px;
    &:not(:last-child) {
      padding-bottom: 8px;
    }
    a {
      color: $link-blue;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
