.react-aria-DatePicker {
  color: $white-contrasting-color;

  &.onThemedBackground {
    color: $ds-text-default;
    .calendarToggle {
      color: $ds-icon-default;
    }
  }

  .react-aria-Group {
    display: flex;
    width: fit-content;
    align-items: center;

  }

  .calendarToggle {
    border: none;
    font-size: inherit;
    background: inherit;
    color: $white-contrasting-color;
    height: inherit;
    width: inherit;
    box-sizing: content-box;
    padding: 8px 12px;

    & svg {
      height: 16px;
      width: 16px;
    }

  }

  .react-aria-DateInput {
    padding: 4px 2.5rem 4px 8px;
    display: flex;
  }
}

.react-aria-Popover[data-trigger="DatePicker"] {
  max-width: unset;
}

.react-aria-Calendar {
  width: fit-content;
  max-width: 100%;
  padding: 1rem;
  color: $ds-text-default;
  background: $ds-surface-tertiary;
  border: 1px solid $ds-border-default;
  border-radius: 1rem;
  box-shadow: 0 2.5px 6px 0 $ds-surface-shadow;

  header {
    display: flex;
    align-items: center;
    margin: 0 4px 0.5rem 4px;

    .react-aria-Heading {
      flex: 1;
      margin: 0;
      text-align: center;
      font-size: 1.375rem;
      @include txt-title-family;
    }
  }

  .changeMonthArrow {
    width: 2rem;
    height: 2rem;
    padding: 0;
    border: none;
    background: none;
    color: inherit;
  }

  .react-aria-CalendarCell {
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    border-radius: 6px;
    cursor: default;
    outline: none;
    margin: 1px;
    forced-color-adjust: none;

    &[data-unavailable] {
      color: $ds-text-secondary;
    }

    &[data-outside-month] {
      display: none;
    }

    &[data-hovered]:not([data-unavailable]) {
      background: $ds-background-primary-subtle-focus;
    }

    &[data-focus-visible] {
      outline: 2px solid $ds-background-primary-focus;
      outline-offset: 2px;
    }

    &[data-selected] {
      background: $ds-background-primary-focus;
      color: $ds-text-on-bg-primary;
      font-weight: bold;
    }
  }
}
